import React, { useState } from "react";
import ImportFileDialogComponent from "./importFileDialog.component";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import {
  checkHasPendingProcess,
  importAdtInfoForm,
  validateAdtInfoForm,
} from "modules/serial-number/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getApiLang, getLang } from "app/feature/constants";
import { addAlert, selectLoading } from "modules/notification";

function ImportFileDialogContainer({ isOpen, handleClose, handleReload }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, importAdtInfoForm.typePrefix)
  );
  const isValidating = useSelector((state) =>
    selectLoading(state, validateAdtInfoForm.typePrefix)
  );

  const [file, setFile] = useState(null);
  const [showTemplateInput, setShowTemplateInput] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [errorField, setErrorField] = useState(null);
  const [errorTitles, setErrorTitles] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  const [isValidFile, setIsValidFile] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [isFileProcessing, setIsFileProcessing] = useState(false);

  const handleValidate = async () => {
    dispatch(validateAdtInfoForm({ file: file }))
      .then(unwrapResult)
      .then((val) => {
        if (!val.success) {
          let errorCode = val.code;
          if (val.code === "error.ERROR_INVALID_COLUMN_COUNT") {
            setShowPreview(true);
            setErrorField({
              row: val.data.row,
              column: val.data.column,
            });
          } else if (val.code === "error.ERROR_INVALID_ADDITIONAL_INFO_TITLE") {
            setShowPreview(true);
            let columns = val.data.map((d) => d.column);
            setErrorTitles(errorTitles.concat(columns));
          } else if (
            val.code === "error.ERROR_MISSING_COLUMN_FIELD" ||
            val.code === "error.ERROR_INVALID_DATE_FORMAT" ||
            val.code === "error.ERROR_INVALID_SEQ_NUMBER" ||
            val.code === "error.ERROR_INVALID_SERIAL_NUMBER" ||
            val.code === "error.ERROR_INVALID_URL_FORMAT"
          ) {
            setShowPreview(true);
            setErrorField({
              row: val.data.row,
              column: val.data.column,
            });
          } else if (val.code === "error.ERROR_MISSING_SN_SEQ") {
            setShowPreview(true);
          }
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, errorCode),
            })
          );
        } else {
          if (val.data?.file_path) {
            setFilePath(val.data?.file_path);
          }
          setIsValidFile(true);
        }
      });
  };

  const handleSubmit = () => {
    dispatch(importAdtInfoForm({ file_path: filePath }))
      .then(unwrapResult)
      .then(() => {
        dispatch(
          addAlert({
            severity: "success",
            message: getLang(
              lang,
              "message.success.SUCCESS_IMPORT_ADDITIONAL_INFO"
            ),
          })
        );
        dispatch(checkHasPendingProcess());
        handleClosePopUp();
        handleReload();
      });
  };

  const handleClosePopUp = () => {
    handleClose();
    setShowTemplateInput(false);
    setFile(null);
    setIsValidFile(false);
    setErrorField(null);
    setErrorTitles([]);
    setShowPreview(false);
  };

  const showAlert = (value) =>
    dispatch(addAlert({ severity: "warning", message: value }));

  const handleFileChange = async (file) => {
    if (file) {
      setShowPreview(false);
      setErrorTitles([]);
      setErrorField(null);
      setIsValidFile(false);
      setIsFileProcessing(true);
      try {
        Papa.parse(file, {
          header: true,
          worker: true,
          skipEmptyLines: true,
          complete: function (results) {
            const rowsArray = [];
            const valuesArray = [];

            // Iterating data to get column name and their values
            results.data.forEach((d) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });

            // console.log("result: ", results.data);

            // Filtered Column Names
            setTableHeader(rowsArray[0]);

            // Filtered Values
            setTableValues(valuesArray);
            setIsFileProcessing(false);
          },
        });
      } catch (error) {
        setIsFileProcessing(false);
        // console.log(error);
      }
    }
  };
  return (
    <>
      <ImportFileDialogComponent
        showTemplateInput={showTemplateInput}
        setShowTemplateInput={setShowTemplateInput}
        handleValidate={handleValidate}
        isOpen={isOpen}
        handleClose={handleClosePopUp}
        lang={lang}
        addAlert={showAlert}
        isSubmitting={isSubmitting}
        setFile={setFile}
        file={file}
        handleFileChange={handleFileChange}
        tableHeader={tableHeader}
        tableValues={tableValues}
        errorField={errorField}
        errorTitles={errorTitles}
        showPreview={showPreview}
        isValidFile={isValidFile}
        handleSubmit={handleSubmit}
        isValidating={isValidating}
        isFileProcessing={isFileProcessing}
      />
    </>
  );
}

ImportFileDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ImportFileDialogContainer;
