import axios from "axios";

const endpoint_url = "/distributor/admin/api/v1/stockout/daily-stockout-summary";

const getDailyStockoutSummary = ({
  length = 25,
  start = 0,
  country,
  node_uuids,
  product_uuids,
  start_date,
  end_date
}) => {

  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url,{
        params: {
          length,
          start,
          country,
          node_uuids,
          product_uuids,
          start_date,
          end_date
        }
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getDailyStockoutSummary;
