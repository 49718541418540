/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AddSnAdtInfoComponent from "./addSnAdtInfo.page";
import { useDispatch, useSelector } from "react-redux";
import { ADDITIONAL_INFO_EDIT } from "lib/constants/accessRights";
import { useHistory } from "react-router-dom";
import LoadingComponent from "components/loading";
import {
  checkHasPendingProcess,
  resetAdditionalFieldDropdown,
  updateAdditionalInfo,
} from "modules/serial-number/redux";
import { getLang } from "app/feature/constants";
import { addAlert, selectLoading } from "modules/notification";
import { ROOT } from "modules/serial-number/config/serial-number.route";

function AddSnAdtInfoContainer() {
  const dispatch = useDispatch();
  const hasAdtInfoEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ADDITIONAL_INFO_EDIT)
  );
  const history = useHistory();
  const lang = useSelector((state) => state.constant.languages);
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateAdditionalInfo.typePrefix)
  );
  const pendingProcess = useSelector(
    (state) => state.additionalInfo.pendingProcess
  );

  const [languageTabIndex, setLanguageTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!hasAdtInfoEditAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [hasAdtInfoEditAccessRight]);

  useEffect(() => {
    dispatch(checkHasPendingProcess());

    return () => {
      dispatch(resetAdditionalFieldDropdown());
    };
  }, []);

  if (!hasAdtInfoEditAccessRight) return <LoadingComponent />;

  const validate = (formik) => {
    let valid = true;
    let hasEmptyField = false;

    let additionalInfo = [];
    let invalidRange = { ...formik.values.invalidRangeError };

    formik.values.additionalInfo.forEach((info, infoIdx) => {
      if (!info.title) {
        if (
          info.ranges.length &&
          (!!info.ranges[0].from || !!info.ranges[0].to)
        ) {
          valid = false;
          dispatch(
            addAlert({
              severity: "warning",
              message: getLang(
                lang,
                "message.warning.ADDITIONAL_INFO_TITLE_EMPTY"
              ),
            })
          );
        } else {
          return;
        }
      }

      let ranges = [];
      info.ranges.forEach((range, rangeIdx) => {
        if (range.from || range.to) {
          if (!range.value) {
            valid = false;
            hasEmptyField = true;
            // formik.setFieldTouched(
            //   `additionalInfo.[${infoIdx}].ranges.[${rangeIdx}].value`,
            //   true
            // );
          } else {
            if (info.format === 1) {
              // formik.values.usedLang.forEach((lang) => {
              //   let val = range.value[lang.code];
              //   if (!val || !val.trim()) {
              //     valid = false;
              //     hasEmptyField = true;
              //     formik.setFieldTouched(
              //       `additionalInfo.[${infoIdx}].ranges.[${rangeIdx}].value.[${lang.code}]`,
              //       true
              //     );
              //   }
              // });
              if (
                !range.value.hasOwnProperty("EN") ||
                !range.value["EN"].trim()
              ) {
                valid = false;
                hasEmptyField = true;
                // formik.setFieldTouched(
                //   `additionalInfo.[${infoIdx}].ranges.[${rangeIdx}].value.["EN"]`,
                //   true
                // );
              }
            }
          }

          if (range.from !== "" && range.to !== "") {
            ranges.push({
              ...range,
              from: range.from,
              to: range.to,
            });
          } else if (range.from) {
            ranges.push({
              ...range,
              from: range.from,
              to: range.from,
            });
          } else if (range.to) {
            valid = false;
            hasEmptyField = true;
            // formik.setFieldTouched(
            //   `additionalInfo.[${infoIdx}].ranges.[${rangeIdx}].from`,
            //   true
            // );
            if (invalidRange[infoIdx]) {
              invalidRange[infoIdx].push(rangeIdx);
            } else {
              invalidRange[infoIdx] = [rangeIdx];
            }
            ranges.push(range);
          }
        } else if (rangeIdx === info.ranges.length - 1 && !ranges.length) {
          ranges.push(range);
        }
      });

      additionalInfo.push({
        ...info,
        ranges,
      });
    });

    if (!additionalInfo.length) {
      additionalInfo.push({
        title: "",
        format: 1,
        ranges: [
          {
            from: "",
            to: "",
            value: "",
          },
        ],
      });
      // formik.setFieldTouched(`additionalInfo.[0].title`, true);
      valid = false;
    }

    formik.setFieldValue("invalidRangeError", invalidRange);
    formik.setFieldValue("additionalInfo", additionalInfo);

    if (hasEmptyField) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "label.NOT_ALL_LANG_FILLED"),
        })
      );
    }

    return valid;
  };

  const handleAddLang = (lang, formik) => {
    const temp = [...formik.values.usedLang];
    temp.push(lang);
    setLanguageTabIndex(temp.length - 1);

    let adtTemp = [...formik.values.additionalInfo];

    adtTemp = adtTemp.map((a) => {
      let title = a.title;

      let ranges = a.ranges;
      if (a.format === 1)
        ranges = a.ranges.map((r) => {
          let tempR = { ...r.value, [lang.code]: "" };

          return {
            ...r,
            value: tempR,
          };
        });

      return {
        ...a,
        title,
        ranges,
      };
    });

    formik.setFieldValue("usedLang", temp);
    formik.setFieldValue("additionalInfo", adtTemp);
  };

  const handleRemoveLang = (formik) => {
    let toRemove = formik.values.usedLang[languageTabIndex].code;

    const temp = [...formik.values.usedLang].filter(
      (item) => item.code !== formik.values.usedLang[languageTabIndex].code
    );

    let adtTemp = [...formik.values.additionalInfo];

    adtTemp = adtTemp.map((a) => {
      let title = a.title;
      let ranges = a.ranges;
      if (a.format === 1)
        ranges = a.ranges.map((r) => {
          let tempR = { ...r.value };
          delete tempR[toRemove];

          return {
            ...r,
            value: tempR,
          };
        });

      return {
        ...a,
        title,
        ranges,
      };
    });

    setLanguageTabIndex(temp.length - 1);

    formik.setFieldValue("usedLang", temp);

    formik.setFieldValue("additionalInfo", adtTemp);
  };

  const handleSubmit = (values) => {
    let infoList = values.additionalInfo.reduce((infos, info) => {
      let temp = [...infos];

      if (!!info.title) {
        let ranges = info.ranges.filter(
          (range) => range.from !== "" && !!range.value
        );
        temp.push({
          ...info,
          title: info.title.id,
          ranges,
        });
      }

      return temp;
    }, []);

    let param = {
      type: values.selection,
      info: infoList,
      supported_languages: values.usedLang.map((lang) => lang.code),
    };

    dispatch(updateAdditionalInfo(param)).then(() => {
      history.push(`${ROOT}?tab=adt-info`);
    });
  };

  return (
    <>
      <AddSnAdtInfoComponent
        lang={lang}
        handleCloseAddLang={() => setAnchorEl(null)}
        handleAddLangClick={(event) => setAnchorEl(event.currentTarget)}
        validate={validate}
        languageTabIndex={languageTabIndex}
        setLanguageTabIndex={setLanguageTabIndex}
        handleSubmit={handleSubmit}
        isUpdating={isUpdating}
        handleAddLang={handleAddLang}
        handleRemoveLang={handleRemoveLang}
        anchorEl={anchorEl}
        pendingProcess={pendingProcess}
      />
    </>
  );
}

export default AddSnAdtInfoContainer;
