import React, { useState } from "react";
import ConsumerTableComponent from "./consumerTable.component"
import { useSelector } from "react-redux"

export default function ConsumerTableContainer({
  consumers,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  handleEditLoyaltyPoint,
  paginationOptions,
  handleSearch,
}) {
  const isLoadingConsumerError = useSelector(state => state.consumer.isLoadingConsumerError)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const [searchColumn, setSearchColumn] = useState(0);
  const [searchData, setSearchData] = useState("");

  const clearSearch = () => {
    setSearchData("");
    setSearchColumn(0);
    handleSearch("", 0);
    // handleReload();
  };

  const handleSubmit = () => {
    handleSearch(searchData, searchColumn);
  };

  return (
    <ConsumerTableComponent
      isFetching={isFetching}
      consumers={consumers}
      page={page}
      language={language}
      search={search}
      rowsPerPage={rowsPerPage}
      totalRecords={totalRecords}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      isLoadingConsumerError={isLoadingConsumerError}
      paginationOptions={paginationOptions}
      handleEditLoyaltyPoint={handleEditLoyaltyPoint}
      lang={lang}
      clearSearch={clearSearch}
      searchColumn={searchColumn}
      setSearchColumn={setSearchColumn}
      searchData={searchData}
      setSearchData={setSearchData}
      handleSubmit={handleSubmit}
    />
  )
}
