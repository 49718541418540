import React from "react";
import { makeStyles, Box, Typography, Link, Divider } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import DefaultImage from "assets/img/defaultPhoto.png";
import DefaultImg from "assets/img/img-default.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  CHECKOUT_LIST_PAGE,
  INVENTORY_LIST_PAGE,
  MY_DEALER_LIST_PAGE,
  RECALL_LIST_PAGE,
  STOCKOUT_LIST_PAGE,
  TRANSFER_LIST_PAGE,
} from "modules/stockflow";
import { ROOT as CLAIM_ROOT } from "modules/claim";
import { ROOT as PRODUCT_ROOT } from "modules/product-mgmt";
import { ROOT as CONSUMER_ROOT } from "modules/consumer";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import ScanTrackDetailTimeline from "../scanTrackDetailTimeline";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  seperator: {
    display: "flex",
    flexDirection: "column",
    flex: 0,
    alignItems: "center",
  },
  sequenceIndicator: {
    boxShadow: "none",
    color: "#ffffff",
    border: "1px solid #D0D5DD",
    alignSelf: "baseline",
    borderRadius: "50%",
    aspectRatio: "1 / 1",
    height: 32,
    width: 32,
    textAlign: "center",
    margin: "5px 0px",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: 25,
    borderLeft: "2px dotted #D0D5DD",
  },
  list: {
    paddingInlineStart: "0px",
    marginTop: 0,
    display: "flex",
    flexDirection: "column-reverse",
  },
  content: {
    border: "1px solid #ECEEEE",
    borderRadius: 4,
    margin: "10px 15px !important",
    overflow: "hidden",
    flex: 1,
  },
  dot: {
    color: "#D0D5DD",
    lineHeight: "13px",
  },
  profile: {
    border: "1px solid #ECEEEE",
    height: 30,
    width: 30,
    minWidth: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      flexShrink: 0,
    },
  },
  productContainer: {
    padding: "8px 16px",
    borderRadius: "8px !important",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    columnGap: theme.spacing(2),
    marginBottom: 12,
  },
  tierIcon: {
    borderRadius: "4px",
    paddingLeft: 3,
    paddingRight: 3,
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
}));

export default function ProductTraceabilityTimelineComponent({
  traceabilityList,
  language,
  serialNumber,
  updateRecordFocus,
  recordFocus,
  lang,
}) {
  const classes = useStyle();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const getIndicatorColor = (userType) => {
    let color = "#35CA91";
    if (userType === "Dealer") {
      color = "#6184FF";
    }
    if (userType === "Employee") {
      color = "#A749F0";
    }
    return color;
  };

  const ASSIGNED_PRODUCT = "assign";
  const PACKING = "packing";
  const PACKED_TO_PACKAGE = "packed_to_package";
  const REMOVE_FROM_PACKAGE = "removed_from_package";
  const UNPACK = "unpacked";
  const BOXING = "boxing";
  const UNBOX = "unboxed";
  const STOCK_OUT = "stockout";
  const STOCK_OUT_CANCELLED = "stockout_cancelled";
  const STOCK_OUT_REJECTED = "stockout_rejected";
  const RECEIVE = "stockout_receive";
  const RECALL = "recall";
  const CHECKOUT = "checkout";
  const REPLACE = "replace";
  const TRANSFER = "transfer";

  const CONSUMER = "Consumer";
  const EMPLOYEE = "Employee";
  const DEALER = "Dealer";

  const getLogType = (record) => {
    let typeLabel = "";
    if (record.user_type === CONSUMER) {
      typeLabel += getLang(lang, "label.CONSUMER");

      const index = record.title.split("_").pop();
      typeLabel += " " + index;
    } else {
      typeLabel += getLang(lang, record.title);
    }

    return typeLabel;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography>{getLang(lang, "label.TIMELINE_END")}</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ width: 28 }}
        mt={2}
      >
        <Typography className={classes.dot} style={{ marginTop: "-13px" }}>
          <b>●</b>
        </Typography>
        <Box className={classes.connector} style={{ minHeight: 10 }} />
      </Box>
      <ul className={classes.list}>
        {traceabilityList.map((record, i) => (
          <li
            key={i}
            className={classes.item}
            id={"track_record_" + record.index}
          >
            <Box className={classes.seperator}>
              <Box
                className={classes.sequenceIndicator}
                style={{ backgroundColor: getIndicatorColor(record.user_type) }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="caption">{record.index}</Typography>
              </Box>
              <Box className={classes.connector} />
              <Box mt={-1} mb={-2}>
                <ArrowDropUpIcon style={{ color: "#D0D5DD", fontSize: 28 }} />
              </Box>
              <Box className={classes.connector} />
              {!i && (
                <Typography
                  className={classes.dot}
                  style={{ marginBottom: "-13px" }}
                >
                  <b>●</b>
                </Typography>
              )}
            </Box>
            <Accordion
              defaultExpanded={false}
              expanded={recordFocus.some((e) => e.index === record.index)}
              className={classes.content}
              onChange={(event, expanded) => {
                if (expanded) {
                  updateRecordFocus(record, true);
                } else {
                  updateRecordFocus(record);
                }
              }}
              style={{
                backgroundColor:
                  record.user_type === CONSUMER &&
                  record.status !== "active" &&
                  record.status !== "replace"
                    ? "#FEE4E2"
                    : "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.productContainer}
                style={{
                  backgroundColor:
                    record.user_type === CONSUMER &&
                    record.status !== "active" &&
                    record.status !== "replace"
                      ? "#FEE4E2"
                      : "#FFFFFF",
                }}
              >
                <Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="h6">
                      <b>{getLogType(record)}</b>
                    </Typography>
                  </Box>

                  <Box display="flex" mb={1} alignItems="flex-start">
                    <Box display="flex" alignItems="center">
                      {!!record.done_by.tier && (
                        <Box
                          className={classes.tierIcon}
                          style={{
                            backgroundColor: TIER_ICON_PROPS.find(
                              ({ tier }) => tier === record.done_by.tier
                            ).color,
                          }}
                          mr={0.5}
                        >
                          <Typography variant="caption">
                            T{record.done_by.tier}
                          </Typography>
                        </Box>
                      )}
                      <Box mr={1} className={classes.profile}>
                        {!record.done_by.picture ? (
                          <img src={DefaultImage} alt="default profile" />
                        ) : (
                          <img
                            src={record.done_by.picture}
                            alt={record.done_by.name}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box mt={0.5}>
                      {record.done_by.uuid ? (
                        <Link
                          href={
                            record.user_type === DEALER
                              ? `${MY_DEALER_LIST_PAGE}/${record.done_by.uuid}`
                              : record.user_type === EMPLOYEE
                              ? `/admin/employee/View/${record.done_by.uuid}`
                              : `${CONSUMER_ROOT}/${record.done_by.uuid}`
                          }
                          color="secondary"
                          onClick={(e) => {
                            if (record.user_type === DEALER) {
                              history.push(
                                `${MY_DEALER_LIST_PAGE}/${record.done_by.uuid}`
                              );
                            }
                            if (record.user_type === EMPLOYEE) {
                              history.push(
                                `/admin/employee/View/${record.done_by.uuid}`
                              );
                            }
                            if (record.user_type === CONSUMER) {
                              history.push(
                                `${CONSUMER_ROOT}/${record.done_by.uuid}`
                              );
                            }
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{ textAlign: "left" }}
                          >
                            {record.user_type === "Employee" &&
                            record.node?.name
                              ? `${record.node.name} - `
                              : ""}
                            {record.done_by.name || "-"}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography variant="body1">
                          {getLang(lang, "label.UNKNOWN")}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {record.user_type === CONSUMER ? (
                    <Typography variant="body1" color="textSecondary">
                      {getLang(lang, "label.LAST_ACTIVE")}:{" "}
                      {record.done_by.last_login
                        ? moment(record.done_by.last_login).format("ll LTS")
                        : "-"}
                    </Typography>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      {moment(record.date).format("ll LTS")}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box mt={0.5} px={2} pb={2}>
                  <Divider />
                  <Box mt={2}>
                    {record.user_type === CONSUMER &&
                    recordFocus.some((e) => e.index === record.index) ? (
                      <>
                        <ScanTrackDetailTimeline
                          scan_id={record.scan_id}
                          expanded={recordFocus.some(
                            (e) => e.index === record.index
                          )}
                          lang={lang}
                          language={language}
                        />
                      </>
                    ) : (
                      <>
                        {record.type === ASSIGNED_PRODUCT && (
                          <Box className={classes.field}>
                            <Typography>
                              {getLang(lang, "label.PRODUCT")}
                            </Typography>
                            <Box display="flex">
                              <Typography
                                color="textSecondary"
                                style={{ marginRight: 5 }}
                              >
                                :
                              </Typography>
                              <Box display="flex">
                                <Box mr={1} className={classes.profile}>
                                  {!serialNumber.productImage ? (
                                    <img
                                      src={DefaultImg}
                                      alt="default product"
                                    />
                                  ) : (
                                    <img
                                      src={serialNumber.productImage}
                                      alt={serialNumber.productName}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box mt={0.5}>
                                <Link
                                  href={`${PRODUCT_ROOT}/${serialNumber.productUuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(
                                      `${PRODUCT_ROOT}/${serialNumber.productUuid}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: "left" }}
                                  >
                                    {serialNumber.productName}
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {[
                          PACKING,
                          UNPACK,
                          PACKED_TO_PACKAGE,
                          REMOVE_FROM_PACKAGE,
                        ].includes(record.type) && (
                          <Box className={classes.field}>
                            <Typography>
                              {getLang(lang, "label.PACKAGE")}
                            </Typography>
                            <Box display="flex">
                              <Typography
                                color="textSecondary"
                                style={{ marginRight: 5 }}
                              >
                                :
                              </Typography>
                              <Box>
                                {record.package ? (
                                  record.type === UNPACK ||
                                  record.type === REMOVE_FROM_PACKAGE ? (
                                    <Typography
                                      variant="body1"
                                      style={{ textAlign: "left" }}
                                    >
                                      {record.package.code}
                                    </Typography>
                                  ) : (
                                    <Link
                                      href={`${INVENTORY_LIST_PAGE}/package/${record.package.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        history.push(
                                          `${INVENTORY_LIST_PAGE}/package/${record.package.uuid}`
                                        );
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{ textAlign: "left" }}
                                      >
                                        {record.package.code}
                                      </Typography>
                                    </Link>
                                  )
                                ) : (
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    -
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {[BOXING, UNBOX].includes(record.type) && (
                          <Box className={classes.field}>
                            <Typography>
                              {getLang(lang, "label.BOX_ID")}
                            </Typography>
                            <Box display="flex">
                              <Typography
                                color="textSecondary"
                                style={{ marginRight: 5 }}
                              >
                                :
                              </Typography>
                              <Box>
                                {record.box ? (
                                  record.type === UNBOX ? (
                                    <Typography
                                      variant="body1"
                                      style={{ textAlign: "left" }}
                                    >
                                      {record.box.code}
                                    </Typography>
                                  ) : (
                                    <Link
                                      href={`${INVENTORY_LIST_PAGE}/box/${record.box.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        history.push(
                                          `${INVENTORY_LIST_PAGE}/box/${record.box.uuid}`
                                        );
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{ textAlign: "left" }}
                                      >
                                        {record.box.code}
                                      </Typography>
                                    </Link>
                                  )
                                ) : (
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    -
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {[TRANSFER].includes(record.type) && (
                          <>
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.TRANSFER_ID")}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box>
                                  {record.transfer ? (
                                    <Link
                                      href={`${TRANSFER_LIST_PAGE}/${record.transfer.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        history.push(
                                          `${STOCKOUT_LIST_PAGE}/${record.transfer.uuid}`
                                        );
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{ textAlign: "left" }}
                                      >
                                        {record.transfer.reference_code}
                                      </Typography>
                                    </Link>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      -
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.TRANSFER_FROM")}
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                flexWrap="wrap"
                              >
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box display="flex" alignItems="center">
                                  <Box mr={1} className={classes.profile}>
                                    {!record.from_node?.picture ? (
                                      <img
                                        src={DefaultImage}
                                        alt="default profile"
                                      />
                                    ) : (
                                      <img
                                        src={record.from_node.picture}
                                        alt={record.from_node.name}
                                      />
                                    )}
                                  </Box>
                                </Box>
                                <Box mt={0.5}>
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: "left" }}
                                  >
                                    {record.from_node.name || "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.TRANSFER_TO")}
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                flexWrap="wrap"
                              >
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box display="flex" alignItems="center">
                                  <Box mr={1} className={classes.profile}>
                                    {!record.to_node?.picture ? (
                                      <img
                                        src={DefaultImage}
                                        alt="default profile"
                                      />
                                    ) : (
                                      <img
                                        src={record.to_node.picture}
                                        alt={record.to_node.name}
                                      />
                                    )}
                                  </Box>
                                </Box>
                                <Box mt={0.5}>
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: "left" }}
                                  >
                                    {record.to_node.name || "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.STATUS")}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {getLang(lang, record.status)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                        {[
                          STOCK_OUT,
                          STOCK_OUT_CANCELLED,
                          STOCK_OUT_REJECTED,
                          RECEIVE,
                        ].includes(record.type) && (
                          <>
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.STOCK_OUT_ID")}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box>
                                  {record.stockout ? (
                                    <Link
                                      href={`${STOCKOUT_LIST_PAGE}/${record.stockout.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        history.push(
                                          `${STOCKOUT_LIST_PAGE}/${record.stockout.uuid}`
                                        );
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{ textAlign: "left" }}
                                      >
                                        {record.stockout.reference_code}
                                      </Typography>
                                    </Link>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      -
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            {record.type === RECEIVE ? (
                              <>
                                {record.stockout.stockout_from.type ===
                                  "Upline" && (
                                  <Box className={classes.field}>
                                    <Typography>
                                      {getLang(lang, "label.RECEIVE_STATUS")}
                                    </Typography>
                                    <Box display="flex">
                                      <Typography
                                        color="textSecondary"
                                        style={{ marginRight: 5 }}
                                      >
                                        :
                                      </Typography>
                                      <Box>
                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                        >
                                          {record.status === "Auto Accepted"
                                            ? getLang(
                                                lang,
                                                "label.AUTO_RECEIVE"
                                              )
                                            : record.status === "Accepted"
                                            ? getLang(
                                                lang,
                                                "label.MANUAL_RECEIVE"
                                              )
                                            : "-"}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                                <Box className={classes.field}>
                                  <Typography>
                                    {getLang(lang, "label.STOCKOUT_BY")}
                                  </Typography>
                                  <Box
                                    display="flex"
                                    alignItems="flex-start"
                                    flexWrap="wrap"
                                  >
                                    <Typography
                                      color="textSecondary"
                                      style={{ marginRight: 5 }}
                                    >
                                      :
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                      {!!record.stockout.stockout_from
                                        ?.tier && (
                                        <Box
                                          className={classes.tierIcon}
                                          style={{
                                            backgroundColor:
                                              TIER_ICON_PROPS.find(
                                                ({ tier }) =>
                                                  tier ===
                                                  record.stockout.stockout_from
                                                    .tier
                                              ).color,
                                          }}
                                          mr={0.5}
                                        >
                                          <Typography variant="caption">
                                            T
                                            {record.stockout.stockout_from.tier}
                                          </Typography>
                                        </Box>
                                      )}
                                      <Box mr={1} className={classes.profile}>
                                        {!record.stockout.stockout_from
                                          ?.picture ? (
                                          <img
                                            src={DefaultImage}
                                            alt="default profile"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              record.stockout.stockout_from
                                                .picture
                                            }
                                            alt={
                                              record.stockout.stockout_from.name
                                            }
                                          />
                                        )}
                                      </Box>
                                    </Box>
                                    <Box mt={0.5}>
                                      <Link
                                        href={
                                          record.stockout.stockout_from.type ===
                                          "Headquarter"
                                            ? `/admin/employee/View/${record.stockout.stockout_from.uuid}`
                                            : `${MY_DEALER_LIST_PAGE}/${record.stockout.stockout_from.uuid}`
                                        }
                                        color="secondary"
                                        onClick={(e) => {
                                          if (
                                            record.stockout.stockout_from
                                              .type === "Headquarter"
                                          ) {
                                            history.push(
                                              `/admin/employee/View/${record.stockout.stockout_from.uuid}`
                                            );
                                          } else {
                                            history.push(
                                              `${MY_DEALER_LIST_PAGE}/${record.stockout.stockout_from.uuid}`
                                            );
                                          }
                                          e.preventDefault();
                                          return false;
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          style={{ textAlign: "left" }}
                                        >
                                          {record.stockout.stockout_from
                                            .type === "Headquarter" &&
                                          record.stockout.stockout_from.branch
                                            ? `${record.stockout.stockout_from.branch} - `
                                            : ""}
                                          {record.stockout.stockout_from.name ||
                                            "-"}
                                        </Typography>
                                      </Link>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <Box className={classes.field}>
                                <Typography>
                                  {getLang(lang, "label.STOCKOUT_TO")}
                                </Typography>
                                <Box
                                  display="flex"
                                  alignItems="flex-start"
                                  flexWrap="wrap"
                                >
                                  <Typography
                                    color="textSecondary"
                                    style={{ marginRight: 5 }}
                                  >
                                    :
                                  </Typography>
                                  <Box display="flex" alignItems="center">
                                    {!!record.stockout.stockout_to?.tier && (
                                      <Box
                                        className={classes.tierIcon}
                                        style={{
                                          backgroundColor: TIER_ICON_PROPS.find(
                                            ({ tier }) =>
                                              tier ===
                                              record.stockout.stockout_to.tier
                                          ).color,
                                        }}
                                        mr={0.5}
                                      >
                                        <Typography variant="caption">
                                          T{record.stockout.stockout_to.tier}
                                        </Typography>
                                      </Box>
                                    )}
                                    <Box mr={1} className={classes.profile}>
                                      {!record.stockout.stockout_to?.picture ? (
                                        <img
                                          src={DefaultImage}
                                          alt="default profile"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            record.stockout.stockout_to.picture
                                          }
                                          alt={record.stockout.stockout_to.name}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                  <Box mt={0.5}>
                                    <Link
                                      href={`${MY_DEALER_LIST_PAGE}/${record.stockout.stockout_to.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        history.push(
                                          `${MY_DEALER_LIST_PAGE}/${record.stockout.stockout_to.uuid}`
                                        );
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{ textAlign: "left" }}
                                      >
                                        {record.stockout.stockout_to.name ||
                                          "-"}
                                      </Typography>
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </>
                        )}
                        {record.type === RECALL && (
                          <Box className={classes.field}>
                            <Typography>
                              {getLang(lang, "label.RECALL_ID")}
                            </Typography>
                            <Box display="flex">
                              <Typography
                                color="textSecondary"
                                style={{ marginRight: 5 }}
                              >
                                :
                              </Typography>
                              <Box>
                                {record.recall ? (
                                  <Link
                                    href={`${RECALL_LIST_PAGE}/${record.recall.uuid}`}
                                    color="secondary"
                                    onClick={(e) => {
                                      history.push(
                                        `${RECALL_LIST_PAGE}/${record.recall.uuid}`
                                      );
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      style={{ textAlign: "left" }}
                                    >
                                      {record.recall.reference_code}
                                    </Typography>
                                  </Link>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    -
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {record.type === CHECKOUT && (
                          <>
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.CHECKOUT_ID")}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box>
                                  {record.checkout ? (
                                    <Link
                                      href={`${CHECKOUT_LIST_PAGE}/${record.checkout.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        history.push(
                                          `${CHECKOUT_LIST_PAGE}/${record.checkout.uuid}`
                                        );
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{ textAlign: "left" }}
                                      >
                                        {record.checkout.reference_code}
                                      </Typography>
                                    </Link>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      -
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.CONSUMER")}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box>
                                  {record.checkout?.consumer ? (
                                    <>
                                      <Box>
                                        <Typography
                                          variant="body1"
                                          style={{ textAlign: "left" }}
                                        >
                                          {record.checkout.consumer.name}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          style={{ textAlign: "left" }}
                                        >
                                          {record.checkout.consumer.email}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          style={{ textAlign: "left" }}
                                        >
                                          {record.checkout.consumer
                                            .phone_code ?? ""}{" "}
                                          {
                                            record.checkout.consumer
                                              .phone_number
                                          }
                                        </Typography>
                                      </Box>
                                    </>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      -
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                        {record.type === REPLACE && (
                          <Box className={classes.field}>
                            <Typography>
                              {getLang(lang, "label.WARRANTY_CLAIM_ID")}
                            </Typography>
                            <Box display="flex">
                              <Typography
                                color="textSecondary"
                                style={{ marginRight: 5 }}
                              >
                                :
                              </Typography>
                              <Box>
                                {record.reference_code ? (
                                  <Link
                                    href={`${CLAIM_ROOT}`}
                                    color="secondary"
                                    onClick={(e) => {
                                      history.push(`${CLAIM_ROOT}`);
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      style={{ textAlign: "left" }}
                                    >
                                      {record.reference_code}
                                    </Typography>
                                  </Link>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    -
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {record.user_type === EMPLOYEE &&
                          record.type !== TRANSFER && (
                            <Box className={classes.field}>
                              <Typography>
                                {getLang(lang, "label.LOCATION")}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Typography color="textSecondary">
                                  {record.node ? (
                                    <>
                                      {record.node.address_line_1}
                                      {record.node.address_line_2
                                        ? ` , ${record.node.address_line_2}`
                                        : ""}
                                      {record.node.address_postal_code &&
                                      record.node.address_city
                                        ? ` , ${record.node.address_postal_code} ${record.node.address_city}`
                                        : record.node.address_city
                                        ? ` , ${record.node.address_city}`
                                        : ""}
                                      {record.node.address_state
                                        ? ` , ${record.node.address_state}`
                                        : ""}
                                      {record.node.address_country
                                        ? ` , ${record.node.address_country}`
                                        : ""}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                      </>
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </li>
        ))}
      </ul>
      <Box mt={1}>
        <Typography>{getLang(lang, "label.TIMELINE_START")}</Typography>
      </Box>
    </Box>
  );
}
