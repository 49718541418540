import { createSlice } from "@reduxjs/toolkit";
import { BRANCH } from "modules/branch/constants";
import {
  fetchBranchDropdown,
  fetchBranchDropdownSpecial,
  fetchBranchList,
  fetchEditBranchDropdown,
  getBranchGroupDropdown,
  getCustomField
} from "./branch.action";

const initialState = {
  branchList: [],
  totalRecords: 0,
  branchDropdown: [],
  isLoadingBranchError: false,
  fetchEditBranchDropdown: [],
  branchGroupDropdown: [],
}

const branchSlice = createSlice({
  name: BRANCH,
  initialState,
  reducers: {
    resetBranchDropdown(state) {
      state.branchDropdown = initialState.branchDropdown
    }
  },
  extraReducers: {
    [fetchBranchDropdown.fulfilled]: (state, action) => {
      state.branchDropdown = action.payload.data;
    },
    [fetchBranchDropdownSpecial.fulfilled]: (state, action) => {
      state.branchDropdown = action.payload.data;
    },
    [fetchBranchDropdownSpecial.pending]: (state) => {
      state.branchDropdown = initialState.branchDropdown;
    },
    [getBranchGroupDropdown.fulfilled]: (state, action) => {
      state.branchGroupDropdown = action.payload.data;
    },
    [getBranchGroupDropdown.pending]: (state) => {
      state.branchGroupDropdown = initialState.branchGroupDropdown;
    },
    [fetchBranchList.fulfilled]: (state, action) => {
      const { payload } = action;

      state.totalRecords = payload.recordsFiltered;

      state.branchList = payload.data;
      state.isLoadingBranchError = false;
    },
    [fetchBranchList.rejected]: (state) => {
      state.isLoadingBranchError = true;
    },
    [fetchEditBranchDropdown.fulfilled]:(state,action)=>{
      state.fetchEditBranchDropdown =action.payload.data
    },
    [fetchEditBranchDropdown.rejected]: (state) => {
      state.isLoadingBranchError = true;
    },
    [getCustomField.fulfilled]: (state, action) => {
      state.customField = action.payload.data.map((field) => ({
        ...field,
        field_name: JSON.parse(field.field_name),
      }));
    }
  }
});

export const {
  resetBranchDropdown
} = branchSlice.actions;

export const branchReducer = branchSlice.reducer;
