import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import LuckyDrawPageComponent from "./luckyDraw.page";
import { LUCKY_DRAW_VIEW, LUCKY_DRAW_V2_ADD_EDIT } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

function LuckyDrawPageContainer() {
  const history = useHistory()
  const hasLuckyDrawViewAccessRight = useSelector(state => state.profile.accessRights.includes(LUCKY_DRAW_VIEW))
  const hasLuckyDrawV2AddEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const lang = useSelector(state => state.constant.languages)
  const tabLabels = [getLang(lang, "label.LUCKY_DRAW_LIST")];

  useEffect(() => {
    if (!hasLuckyDrawViewAccessRight) {
      history.push("/admin/dashboard")
    }
  }, [history, hasLuckyDrawViewAccessRight])

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  if (!hasLuckyDrawViewAccessRight) return <LoadingComponent />;

  return (
    <LuckyDrawPageComponent
      hasLuckyDrawV2AddEditAccessRight={hasLuckyDrawV2AddEditAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      lang={lang}
    />
  );
}

export default LuckyDrawPageContainer;
