import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  fetchWarranteeListTable,
  fetchWarrantyListTable,
  fetchWarrantyLogTable,
} from "../action";

export const WARRANTY = "warranty";

const initialState = {
  warrantySetting: {
    id: "",
    warrantyName: "",
    warrantyPeriod: "",
    warrantyApproval: 0,
    warrantyPreregisterEnabled: 0,
    warrantyRequiredValidation: 0,
    itemReplacementProcessEnabled: 0,
    allowDifferentProductReplacement: 0,
    warrantyTermsConditionText: true,
    warrantyTermsConditionDocument: false,
    allowMultipleClaimReason: false,
    termsConditionDetail: { value: "<p></p>", data: [] },
    fileUrl: "",
    warrantyRegisterNotificationType: 1,
    claimApplicationNotificationType: 1,
    warrantyRegisterCustomRecipient: [],
    claimApplicationCustomRecipient: [],
    customRecipient: {
      warranty_registration: [],
      claim_application: [],
    },
    claimReasons: [],
    productIds: [],
  },
  warrantyRegisterForm: [
    {
      fieldName: "name",
      labelType: "Short Text",
      type: "textField",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Name",
      choice: [],
    },
    {
      fieldName: "ic",
      labelType: "Short Text",
      type: "textField",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "IC",
      choice: [],
    },
    {
      fieldName: "email",
      labelType: "Short Text",
      type: "textField",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Email",
      choice: [],
    },
    {
      fieldName: "phone",
      labelType: "Short Text",
      type: "contactNumber",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Phone",
      choice: [],
    },
    {
      fieldName: "purchased_at",
      labelType: "Date Picker",
      type: "datePicker",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Date of Purchase",
      choice: [],
    },
    {
      fieldName: "place_of_purchase",
      labelType: "Short Text",
      type: "textField",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Place of Purchase",
      choice: [],
    },
    {
      fieldName: "proof_of_purchase",
      labelType: "Image Upload",
      type: "uploadImage",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Proof of Purchase",
      choice: [],
    },
  ],
  selectedWarrantyExtension: 0,
  warrantyExtensionListTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
    hasMoretoLoad: false,
    currentShowing: 0,
  },
  warrantyExtensionBatchTable: [],
  warrantyNameDropdown: [],
  settingType: "view",
  settingFormEditing: false,
  registerType: "view",
  extensionType: "view",
  headerType: "view",
  reimbursementType: "view",
  warrantyAddField: {
    choice: ["", "", ""],
    enableDelete: true,
    labelType: "",
    value: "",
  },
  warrantyListTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  warranteeListTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  consumerWarrantyDetail: {
    uuid: "",
    status: "",
    reason: "",
    metadata: "",
    purchasedAt: "",
    readAt: "",
    durationMonth: "",
    startAt: "",
    endAt: "",
    extCode: "",
    extMonth: "",
    extAt: "",
    isTnc: true,
    enc: "",
    serialNumber: "",
    createdAt: "",
    updatedAt: "",
    warranty: {
      warrantySettingId: "",
      warrantySettingTncUrl: "",
      claimReasonOptions: [],
    },
    product: {
      productId: "",
      attributes: [],
      productName: "",
      productNameOld: "",
      productImageUrl: "",
      productDesc: "",
    },
  },
  warrantyLogTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  warranteeClaimsTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  consumerClaimTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  consumerClaimDetail: {
    uuid: "",
    status: 0,
    enc: null,
    remark: "",
    replacement: null,
    checkoutBranch: "",
    claimAddress: "",
    rejectedReason: "",
    medias: null,
    referenceCode: "",
    productName: "",
    productNameOld: "",
    productImage: "",
    replacementProductName: "",
    replacementProductNameOld: "",
    replacementProductImage: "",
    attributes: [],
    replacement_history: [],
    serialNumber: "",
    approvedAt: "",
    createdAt: "",
    updatedAt: "",
    warranteeName: "",
    warranteeEmail: "",
    warranteeMetadata: null,
    registrationDate: "",
    warrantyName: "",
    warrantyStatus: 0,
    warrantyStart: "",
    warrantyEnd: "",
    warrantyDuration: 0,
    extensionCode: "",
    extensionPeriod: 0,
    extensionDate: "",
    claimReasonOptions: null,
    reimbursementId: "",
    pendingReimbursementExist: 0,
  },
  warrantyReimbursementTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  warrantyReimbursementDetail: {
    uuid: "",
    status: 0,
    currencyCode: "",
    amount: 0,
    remark: "",
    receiptProof: null,
    respondedAt: "",
    responderEmployee: null,
    rejectedReason: "",
    referenceCode: "",
    createdAt: "",
    product: null,
    warrantyClaim: null,
    node: null,
  },
  warrantyReimbursementForm: {
    reimbursementId: "",
    currencyCode: "",
    currencyExp: 2,
    currencyName: "",
    amount: 0,
    receiptProof: [],
    remark: "",
  },
  itemReplacementTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  isLoadingWarrantyError: false,
  isLoadingWarrantyParticipantError: false,
  isLoadingWarrantyLogError: false,
  isLoadingConsumerClaimTableError: false,
  isLoadingWarrantyReimbursementError: false,
  isLoadingItemReplacementError: false,
  isWarrantyExtensionBatchTableDirty: false,
  isWarranteeTableDirty: false,
  pageSetting: {
    length: 25,
    start: 0,
    search: "",
    product: [],
  },
};

const warrantySlice = createSlice({
  name: WARRANTY,
  initialState,
  reducers: {
    setSelectedWarrantyExtension(state, action) {
      const { payload } = action;

      state.selectedWarrantyExtension = payload || 0;
    },

    setWarrantySetting(state, action) {
      const { payload } = action;
      state.warrantySetting.warrantyName = payload.warranty_name || "";
      state.warrantySetting.warrantyPeriod = payload.duration_month || "";
      state.warrantySetting.warrantyApproval = payload.warrantyApproval || 0;
      state.warrantySetting.warrantyPreregisterEnabled =
        payload.warrantyPreregisterEnabled || 0;
      state.warrantySetting.warrantyRequiredValidation =
        payload.warrantyRequiredValidation || 0;
      state.warrantySetting.productIds = payload.product || [];
      state.warrantySetting.warrantyTermsConditionText =
        payload.warrantyTermsConditionText || false;
      state.warrantySetting.warrantyTermsConditionDocument =
        payload.warrantyTermsConditionDocument || false;
      state.warrantySetting.fileUrl = payload.fileUrl || "";
      state.warrantySetting.termsConditionDetail =
        payload.termsConditionDetail || "";
      state.warrantySetting.claimReasons = payload.claimReasons || [];
      state.warrantySetting.allowMultipleClaimReason =
        payload.allowMultipleClaimReason || false;
      state.warrantySetting.itemReplacementProcessEnabled =
        payload.itemReplacementProcessEnabled ||
        state.warrantySetting.itemReplacementProcessEnabled;
      state.warrantySetting.allowDifferentProductReplacement =
        payload.allowDifferentProductReplacement ||
        state.warrantySetting.allowDifferentProductReplacement;
      state.warrantySetting.warrantyRegisterNotificationType =
        payload.warrantyRegisterNotificationType !== null
          ? payload.warrantyRegisterNotificationType
          : state.warrantySetting.warrantyRegisterNotificationType;
      state.warrantySetting.claimApplicationNotificationType =
        payload.claimApplicationNotificationType !== null
          ? payload.claimApplicationNotificationType
          : state.warrantySetting.claimApplicationNotificationType;
      state.warrantySetting.customRecipient =
        payload.customRecipient || state.warrantySetting.customRecipient;
    },

    setWarrantySettingUpdate(state, action) {
      const { payload } = action;

      state.warrantySetting.warrantyName =
        payload.warranty_name || state.warrantySetting.warrantyName;
      state.warrantySetting.warrantyPeriod =
        payload.duration_month || state.warrantySetting.warrantyPeriod;
      state.warrantySetting.warrantyApproval =
        payload.warrantyApproval !== undefined &&
        payload.warrantyApproval !== null
          ? payload.warrantyApproval
          : state.warrantySetting.warrantyApproval;
      state.warrantySetting.warrantyPreregisterEnabled =
        payload.is_preregister_enabled !== undefined &&
        payload.is_preregister_enabled !== null
          ? payload.is_preregister_enabled
          : state.warrantySetting.warrantyPreregisterEnabled;
      state.warrantySetting.warrantyRequiredValidation =
        payload.warrantyRequiredValidation !== undefined &&
        payload.warrantyRequiredValidation !== null
          ? payload.warrantyRequiredValidation
          : state.warrantySetting.warrantyRequiredValidation;
      state.warrantySetting.productIds =
        payload.productIds || state.warrantySetting.productIds;
      state.warrantySetting.claimReasons =
        payload.claimReasons || state.warrantySetting.claimReasons;
      state.warrantySetting.allowMultipleClaimReason =
        payload.allowMultipleClaimReason ||
        state.warrantySetting.allowMultipleClaimReason;
      state.warrantySetting.allowDifferentProductReplacement =
        payload.allowDifferentProductReplacement ||
        state.warrantySetting.allowDifferentProductReplacement;

      if (payload.fileUrl) {
        state.warrantySetting.warrantyTermsConditionText =
          payload.warrantyTermsConditionText ||
          state.warrantySetting.warrantyTermsConditionText;
        state.warrantySetting.warrantyTermsConditionDocument =
          payload.warrantyTermsConditionDocument ||
          state.warrantySetting.warrantyTermsConditionDocument;
        state.warrantySetting.fileUrl =
          payload.fileUrl || state.warrantySetting.fileUrl;
        state.warrantySetting.termsConditionDetail =
          payload.termsConditionDetail ||
          state.warrantySetting.termsConditionDetail;
        state.warrantySetting.warrantyTermsConditionText =
          payload.warrantyTermsConditionText;
        state.warrantySetting.warrantyTermsConditionDocument =
          payload.warrantyTermsConditionDocument;
        if (payload.warrantyTermsConditionText) {
          state.warrantySetting.termsConditionDetail =
            payload.termsConditionDetail;
          state.warrantySetting.fileUrl = initialState.warrantySetting.fileUrl;
        } else {
          state.warrantySetting.termsConditionDetail =
            decodeURI(
              payload.fileUrl
                .split("/")
                [payload.fileUrl.split("/").length - 1].split(".pdf")[0]
            ) + ".pdf";
          state.warrantySetting.fileUrl = payload.fileUrl;
        }
      }
      state.warrantyRegisterForm =
        payload.metadata || state.warrantyRegisterForm;
    },

    setRegisterForm(state, action) {
      const { payload } = action;
      state.warrantyRegisterForm = payload;
      state.settingFormEditing = false;
      state.warrantyAddField.labelType = "";
      state.warrantyAddField.value = "";
      state.warrantyAddField.enableDelete = true;
      state.warrantyAddField.choice = ["", "", ""];
    },

    setRegisterFormEdit(state, action) {
      const { payload } = action;
      state.warrantyRegisterForm.forEach((element, index) => {
        if (index === payload) {
          element.editing = true;
          state.settingFormEditing = true;
        } else element.editing = false;
      });
    },

    setRegisterFormFinishEdit(state, action) {
      const { payload } = action;
      state.warrantyRegisterForm[payload.index].variantName =
        payload.variantName;
      state.warrantyRegisterForm[payload.index].visible = payload.visible;
      state.warrantyRegisterForm[payload.index].mandatory = payload.mandatory;
      state.warrantyRegisterForm[payload.index].editing = false;
      state.warrantyRegisterForm[payload.index].fieldType = payload.fieldType;
      state.warrantyRegisterForm[payload.index].choice = payload.choice;
      if (payload.fieldType !== "standard")
        state.warrantyRegisterForm[payload.index].fieldName =
          payload.variantName.toLowerCase().replaceAll(" ", "_");
      state.settingFormEditing = false;
    },

    setNewField(state, action) {
      const { payload } = action;
      state.warrantyAddField.labelType = payload.labelType;
      state.warrantyAddField.value = payload.value;
      state.warrantyAddField.enableDelete = payload.enableDelete;
      state.warrantyAddField.choice = payload.choice;
      state.settingFormEditing = payload.settingFormEditing;
    },

    setRegisterFormDelete(state, action) {
      const { payload } = action;
      state.warrantyRegisterForm.splice(payload, 1);
      state.settingFormEditing = false;
    },

    setWarrantyExtension(state, action) {
      const { payload } = action;

      state.warrantyExtensionListTable.data = payload;
      state.warrantyExtensionListTable.totalRecords++;
      state.warrantyExtensionListTable.filteredTotalRecords++;
      state.warrantyExtensionListTable.currentShowing =
        state.warrantyExtensionListTable.currentShowing === 0
          ? 10
          : state.warrantyExtensionListTable.currentShowing;
      state.warrantyExtensionListTable.hasMoretoLoad =
        state.warrantyExtensionListTable.data.length >
        state.warrantyExtensionListTable.currentShowing
          ? true
          : false;

      const info = {
        data: [],
        totalRecords: 0,
        filteredTotalRecords: 0,
      };
      state.warrantyExtensionBatchTable.push(info);
    },

    setWarrantyExtensionListTable(state, action) {
      const { payload } = action;

      const tempData = [];
      state.warrantyExtensionListTable.currentShowing = 10;

      state.warrantyExtensionListTable.totalRecords = payload.recordsTotal;
      state.warrantyExtensionListTable.filteredTotalRecords =
        payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          extensionPeriod: element.ext_month || "",
          effectiveDateStart: element.ext_start_at || "",
          effectiveDateEnd: element.ext_end_at || "",
        };
        tempData.push(order);
        const info = {
          data: [],
          totalRecords: 0,
          filteredTotalRecords: 0,
        };
        state.warrantyExtensionBatchTable.push(info);
      });
      state.warrantyExtensionListTable.data = tempData;
      state.warrantyExtensionListTable.hasMoretoLoad =
        state.warrantyExtensionListTable.data.length >
        state.warrantyExtensionListTable.currentShowing
          ? true
          : false;
    },

    setWarrantyExtensionBatchTable(state, action) {
      const { payload } = action;
      const tempData = [];
      if (payload.index >= 0) {
        state.warrantyExtensionBatchTable[payload.index].totalRecords =
          payload.recordsTotal;
        state.warrantyExtensionBatchTable[payload.index].filteredTotalRecords =
          payload.recordsFiltered;

        payload.data.forEach((element) => {
          const order = {
            batchNumber: element.batch_number || "",
            quantity: element.quantity || "",
            createdAt: element.created_at || "",
          };
          tempData.push(order);
        });
        state.warrantyExtensionBatchTable[payload.index].data = tempData;
      }
    },

    setWarrantyExtensionFinishEdit(state, action) {
      const { payload } = action;
      state.warrantyExtensionListTable.data[payload.index].extensionPeriod =
        payload.extensionPeriod;
      state.warrantyExtensionListTable.data[payload.index].effectiveDateStart =
        payload.effectiveDateStart;
      state.warrantyExtensionListTable.data[payload.index].effectiveDateEnd =
        payload.effectiveDateEnd;
      state.warrantyExtensionListTable.data[payload.index].extensionCode =
        payload.extensionCode || "";
      if (payload.batch_number) {
        state.warrantyExtensionBatchTable[payload.index].totalRecords++;
        state.warrantyExtensionBatchTable[payload.index].filteredTotalRecords++;
        const order = {
          batchNumber: payload.batch_number || "",
          quantity: payload.extensionCode || "",
          createdAt: new moment().format("DD MMM YYYY, HH:mm:ss") || "",
        };
        state.warrantyExtensionBatchTable[payload.index].data.unshift(order);
      }
    },

    setWarrantyExtensionDelete(state, action) {
      const { payload } = action;
      state.warrantyExtensionListTable.data.splice(payload, 1);
      state.warrantyExtensionBatchTable.splice(payload, 1);
    },

    setType(state, action) {
      const { payload } = action;
      state.headerType = payload.type;
      switch (payload.index) {
        case 0:
          state.settingType = payload.type;
          break;
        case 1:
          state.registerType = payload.type;
          break;
        case 2:
          state.extensionType = payload.type;
          break;
        default:
          state.settingType = payload.type;
          state.registerType = payload.type;
          state.extensionType = payload.type;
          break;
      }
    },

    resetWarrantySetting(state, action) {
      state.warrantySetting = initialState.warrantySetting;
    },

    resetRegisterForm(state, action) {
      state.warrantyRegisterForm = initialState.warrantyRegisterForm;
    },

    resetWarrantyExtension(state, action) {
      state.warrantyExtensionBatchTable =
        initialState.warrantyExtensionBatchTable;
      state.warrantyExtensionListTable =
        initialState.warrantyExtensionListTable;
    },

    resetRegisterFormType(state, action) {
      state.registerType = initialState.registerType;
      state.headerType = initialState.headerType;
      state.extensionType = initialState.extensionType;
      state.settingType = initialState.settingType;
      state.settingFormEditing = initialState.settingFormEditing;
      state.warrantyAddField = initialState.warrantyAddField;
    },

    setWarrantyList(state, action) {
      const { payload } = action;
      const tempData = [];

      state.warrantyListTable.totalRecords = payload.recordsTotal;
      state.warrantyListTable.filteredTotalRecords = payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          warrantyName: element.warranty_name || "",
          durationMonth: element.duration_month || 0,
          status: element.status || "",
          metadata: element.metadata || "",
          createdAt: element.created_at || "",
          updatedAt: element.updated_at || "",
          tncUrl: element.tnc_url || "",
          products: element.products || "",
          totalCount: element.totalCount || 0,
          productCount: element.productCount || 0,
        };

        tempData.push(order);
      });

      state.warrantyListTable.data = tempData;
    },

    setWarranteeList(state, action) {
      const { payload } = action;
      const tempData = [];

      state.warranteeListTable.totalRecords = payload.recordsTotal;
      state.warranteeListTable.filteredTotalRecords = payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          reason: element.reason || "",
          status: element.status || "",
          metadata: element.metadata || "",
          createdAt: element.created_at || "",
          updatedAt: element.updated_at || "",
          purchasedAt: element.purchased_at || "",
          readAt: element.read_at || "",
          durationMonth: element.duration_month || 0,
          startAt: element.start_at || "",
          endAt: element.end_at || "",
          extAt: element.ext_at || "",
          extMonth: element.extMonth || 0,
          isTnc: element.is_tnc || "",
          enc: element.enc || "",
          serialNumber: element.serialnumber || "",
          productId: element.product_uuid || "",
          productName: element.product_name || "",
          productNameOld: element.product_name_old || "",
          attributes: element.attributes || [],
          warrantyName: element.warranty_name || "",
          warrantyUUID: element.warranty_uuid || "",
          warranteeName: element.name || "",
          warranteeEmail: element.email || "",
          warranteePhone: element.contact_number || "",
        };

        if (new Date(element.end_at).getTime() < new Date().getTime()) {
          order.verified = "Expired";
        } else order.verified = "Yes";

        tempData.push(order);
      });

      state.warranteeListTable.data = tempData;
    },

    setWarrantyDetail(state, action) {
      const { payload } = action;

      state.warrantySetting.fileUrl =
        payload.data.tnc_url || state.warrantySetting.fileUrl;
      state.warrantySetting.warrantyName =
        payload.data.warranty_name || state.warrantySetting.warrantyName;
      state.warrantySetting.warrantyPeriod =
        payload.data.duration_month || state.warrantySetting.warrantyPeriod;
      state.warrantySetting.id =
        payload.data.warranty_setting_id || state.warrantySetting.id;
      state.warrantySetting.warrantyApproval =
        payload.data.isApproval || state.warrantySetting.warrantyApproval;
      state.warrantySetting.warrantyPreregisterEnabled =
        payload.data.is_preregister_enabled ||
        state.warrantySetting.warrantyPreregisterEnabled;
      state.warrantySetting.warrantyRequiredValidation =
        payload.data.required_validation ||
        state.warrantySetting.warrantyRequiredValidation;
      state.warrantySetting.allowMultipleClaimReason =
        payload.data.allowMultipleClaimReason ||
        state.warrantySetting.allowMultipleClaimReason;
      state.warrantySetting.claimReasons =
        payload.data.claimReasons || state.warrantySetting.claimReasons;
      state.warrantySetting.warrantyRegisterNotificationType =
        payload.data.warrantyRegisterNotificationType !== null
          ? payload.data.warrantyRegisterNotificationType
          : state.warrantySetting.warrantyRegisterNotificationType;
      state.warrantySetting.claimApplicationNotificationType =
        payload.data.claimApplicationNotificationType !== null
          ? payload.data.claimApplicationNotificationType
          : state.warrantySetting.claimApplicationNotificationType;
      state.warrantySetting.customRecipient =
        payload.data.customRecipient || state.warrantySetting.customRecipient;
      state.warrantySetting.warrantyRegisterCustomRecipient = payload.data
        .customRecipient
        ? payload.data.customRecipient.warranty_registration.join(";")
        : state.warrantySetting.warrantyRegisterCustomRecipient;
      state.warrantySetting.claimApplicationCustomRecipient = payload.data
        .customRecipient
        ? payload.data.customRecipient.claim_application.join(";")
        : state.warrantySetting.claimApplicationCustomRecipient;
      state.participantRegistrationForm =
        payload.metadata || state.participantRegistrationForm;
      if (payload.data.product) {
        const products = [];

        payload.data.product.forEach((product) => {
          const id = {
            id: product.id,
            name: product.name,
            attributes: product.attributes,
          };

          products.push(id);
        });
        state.warrantySetting.productIds = products;
      }

      if (
        payload.data.tnc_url &&
        payload.data.tnc_url.substr(payload.data.tnc_url.length - 3) === "pdf"
      ) {
        state.warrantySetting.termsConditionDetail =
          decodeURI(
            payload.data.tnc_url
              .split("/")
              [payload.data.tnc_url.split("/").length - 1].split(".pdf")[0]
          ) + ".pdf";
        state.warrantySetting.warrantyTermsConditionDocument = true;
        state.warrantySetting.warrantyTermsConditionText = false;
      } else {
        state.warrantySetting.warrantyTermsConditionText = true;
        state.warrantySetting.warrantyTermsConditionDocument = false;
      }
      state.warrantySetting.itemReplacementProcessEnabled =
        payload.data.itemReplacementProcessEnabled ||
        state.warrantySetting.itemReplacementProcessEnabled;
      state.warrantySetting.allowDifferentProductReplacement =
        payload.data.allowDifferentProductReplacement ||
        state.warrantySetting.allowDifferentProductReplacement;
      state.warrantySetting.warrantyEmailNotification =
        payload.data.warrantyEmailNotification ||
        state.warrantySetting.warrantyEmailNotification;
      state.warrantySetting.customRecipient =
        payload.data.customRecipient || state.warrantySetting.customRecipient;
      state.warrantyRegisterForm = payload.data.metadata;
    },

    setWarrantySettingTermConditionDetail(state, action) {
      const { payload } = action;

      state.warrantySetting.termsConditionDetail = payload;
    },
    setWarrantyRegisterForm(state, action) {
      const { payload } = action;
      state.warrantyRegisterForm = payload.warrantyRegisterForm;
    },
    setWarrantyID(state, action) {
      const { payload } = action;

      state.warrantySetting.id = payload.data.warranty_setting_id;
    },

    setWarrantyNameDropdown(state, action) {
      const { payload } = action;
      const tempData = [];

      payload.data.forEach((element) => {
        const order = {
          warrantySettingId: element.warranty_setting_id || "",
          warrantyName: element.warranty_name || "",
          warrantyDuration: element.duration_month || 0,
        };
        tempData.push(order);
      });
      state.warrantyNameDropdown = tempData;
    },

    fetchMoreWarrantyExtension(state, action) {
      const { payload } = action;
      state.warrantyExtensionListTable.currentShowing = payload;
      state.warrantyExtensionListTable.hasMoretoLoad =
        state.warrantyExtensionListTable.data.length > payload ? true : false;
    },

    setConsumerWarrantyDetail(state, action) {
      const { payload } = action;

      state.consumerWarrantyDetail.id = payload.uuid;
      state.consumerWarrantyDetail.status = payload.status;
      state.consumerWarrantyDetail.reason = payload.reason || "";
      state.consumerWarrantyDetail.metadata = payload.metadata;
      state.consumerWarrantyDetail.purchasedAt = payload.purchased_at;
      state.consumerWarrantyDetail.readAt = payload.read_at;
      state.consumerWarrantyDetail.durationMonth = payload.duration_month;
      state.consumerWarrantyDetail.startAt = payload.start_at;
      state.consumerWarrantyDetail.endAt = payload.end_at;
      state.consumerWarrantyDetail.extCode = payload.ext_code;
      state.consumerWarrantyDetail.extMonth = payload.ext_month;
      state.consumerWarrantyDetail.extAt = payload.ext_at;
      state.consumerWarrantyDetail.isTnc = payload.is_tnc ? 1 : 0;
      state.consumerWarrantyDetail.enc = payload.enc;
      state.consumerWarrantyDetail.serialNumber = payload.serialnumber;
      state.consumerWarrantyDetail.createdAt = payload.created_at;
      state.consumerWarrantyDetail.updatedAt = payload.updated_at;
      state.consumerWarrantyDetail.beforeVoidedStatus =
        payload.before_voided_status || "";
      state.consumerWarrantyDetail.beforeVoidedReason =
        payload.before_voided_reason || "";
      state.consumerWarrantyDetail.warranty.warrantySettingId =
        payload["warranty-setting"].id;
      state.consumerWarrantyDetail.warranty.warrantySettingTncUrl =
        payload["warranty-setting"].tnc_url;
      state.consumerWarrantyDetail.warranty.claimReasonOptions =
        payload["warranty-setting"].claim_reason_options;
      state.consumerWarrantyDetail.product.productId = payload.product.id;
      state.consumerWarrantyDetail.product.attributes =
        payload.product.attributes;
      state.consumerWarrantyDetail.product.productName = payload.product.name;
      state.consumerWarrantyDetail.product.productNameOld =
        payload.product.old_name;
      state.consumerWarrantyDetail.product.productImageUrl =
        payload.product.image_url;
      state.consumerWarrantyDetail.product.productDesc =
        payload.product.description;
    },

    setConsumerWarrantyDetailStatus(state, action) {
      const { payload } = action;

      state.consumerWarrantyDetail.status = payload.status;
      state.consumerWarrantyDetail.reason = payload.reason;

      if (payload.extended) {
        state.consumerWarrantyDetail.durationMonth =
          state.consumerWarrantyDetail.durationMonth + payload.extended;
      }
      if (payload.extendedUntil) {
        state.consumerWarrantyDetail.endAt = payload.extendedUntil;
      }

      state.warrantySetting.warrantyApproval = 0;
    },

    setWarrantyLogTable(state, action) {
      const { payload } = action;
      const tempData = [];

      state.warrantyLogTable.totalRecords = payload.recordsTotal;
      state.warrantyLogTable.filteredTotalRecords = payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          enc: element.enc || "",
          serialNumber: element.serialNumber || "",
          action: element.action || "",
          remarks: element.remarks || "",
          createdAt: element.created_at || "",
        };

        tempData.push(order);
      });

      state.warrantyLogTable.data = tempData;
    },

    reorderRegisterFormField(state, action) {
      const { payload } = action;

      const newForm = Array.from(payload.latestform);
      const [removed] = newForm.splice(payload.sourceIndex, 1);
      newForm.splice(payload.destinationIndex, 0, removed);

      state.warrantyRegisterForm = newForm;
    },

    setIsWarrantyExtensionBatchTableDirty(state, action) {
      state.isWarrantyExtensionBatchTableDirty = action.payload;
    },

    setIsWarranteeTableDirty(state, action) {
      state.isWarranteeTableDirty = action.payload;
    },

    setWarranteeClaimsTable(state, action) {
      state.warranteeClaimsTable = {
        ...action.payload,
        data: action.payload.data.map((e) => {
          return {
            id: e.uuid,
            referenceCode: e.reference_code || "",
            warranteeId: e.warrantee_id,
            status: e.status,
            rejectedReason: e.rejected_reason,
            remark: e.remark,
            metadata: JSON.parse(e.metadata),
            approvedAt: e.approved_at,
            createdAt: e.created_at,
            updatedAt: e.updated_at,
            claimReason: e.claim_reason.map((item) => {
              return {
                id: item.claim_reason_id,
              };
            }),
          };
        }),
      };
    },

    updateWarranteeClaimsTable(state, action) {
      const data = action.payload;
      state.warranteeClaimsTable.data[action.payload.index] = {
        id: data.uuid,
        referenceCode: data.reference_code || "",
        warranteeId: data.warrantee_id,
        status: data.status,
        rejectedReason: data.rejected_reason,
        remark: data.remark,
        metadata: JSON.parse(data.metadata),
        approvedAt: data.approved_at,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        claimReason: data.claim_reason.map((item) => {
          return {
            id: item.claim_reason_id,
          };
        }),
      };
    },

    setConsumerClaimsTable(state, action) {
      const data = action.payload;

      state.consumerClaimTable = {
        data: data.records.map((record) => {
          return {
            uuid: record.uuid,
            referenceCode: record.reference_code,
            status: record.status,
            serialNumber: record.serial_number,
            productName: record.product_name,
            productNameOld: record.product_name_old,
            attributes: record.attributes || [],
            warrantySettingName: record.warranty_name,
            warranteeName: record.warrantee_name,
            warranteeEmail: record.warrantee_email,
            replacementUuid: record.replacement_uuid,
            isReplacementCompleted: record.is_replacement_completed,
            reimbursementId: record.reimbursement_uuid,
            reimbursementStatus: record.reimbursement_status,
            createdAt: record.created_at || "",
            updatedAt: record.updated_at || "",
            approvedAt: record.approved_at || "",
          };
        }),
        totalRecords: data.totalRecords,
        filteredTotalRecords: data.filteredTotalRecords,
      };
    },

    clearConsumerClaimsTable(state, _) {
      state.consumerClaimTable = initialState.consumerClaimTable;
    },

    setConsumerClaimDetail(state, action) {
      const { payload } = action;

      const mediasJson = JSON.parse(payload.metadata);
      state.consumerClaimDetail = {
        uuid: payload.id,
        enc: payload.enc,
        status: payload.status,
        checkoutBranch: payload.checkout_branch,
        replacement: payload.replacement,
        remark: payload.remark,
        claimAddress: payload.claim_address,
        rejectedReason: payload.rejected_reason,
        medias: {
          imageFile: mediasJson.image_file,
          videoFile: mediasJson.video_file,
        } || { imageFile: [], videoFile: [] },
        referenceCode: payload.reference_code,
        productName: payload.product_name,
        productNameOld: payload.product_name_old,
        productImage: payload.product_image,
        replacementProductName: payload.replacement_product_name,
        replacementProductNameOld: payload.replacement_product_name_old,
        replacementProductImage: payload.replacement_product_image,
        replacementAttributes: payload.replacement_attributes || [],
        attributes: payload.attributes || [],
        replacementHistory: payload.replacement_history,
        serialNumber: payload.serial_number,
        approvedAt: payload.approved_at || "",
        createdAt: payload.created_at || "",
        updatedAt: payload.updated_at || "",
        warranteeName: payload.warrantee_name,
        warranteeEmail: payload.warrantee_email,
        warranteeMetadata: payload.warrantee_metadata,
        registrationDate: payload.warrantee_registration_date,
        warrantyName: payload.warranty_name,
        warrantyStatus: payload.warranty_status,
        claimReasonId: payload.claim_reason_id,
        warrantyStart: payload.warranty_start_date,
        warrantyEnd: payload.warranty_end_date,
        warrantyDuration: payload.warranty_duration,
        extensionCode: payload.warranty_extension_code,
        extensionPeriod: payload.warranty_extension_duration,
        extensionDate: payload.warranty_extension_date,
        claimReasonOptions:
          payload.claim_reason_options.map((option) => {
            return {
              id: option.id,
              uuid: option.uuid,
              isEnabled: option.is_enabled,
              reasonName: option.reason_name,
            };
          }) || [],
        reimbursementId: payload.reimbursement_id,
        pendingReimbursementExist: payload.reimbursement_resubmit_exist,
      };
    },

    clearConsumerClaimDetail(state, _) {
      state.consumerClaimDetail = initialState.consumerClaimDetail;
    },

    setWarrantyReimbursementTable(state, action) {
      const { payload } = action;

      state.warrantyReimbursementTable.data = payload.data.map((record) => {
        return {
          uuid: record.uuid,
          claimUuid: record.claim_uuid,
          claimReferenceCode: record.claim_reference_code,
          status: record.status,
          createdAt: record.created_at,
          currencyCode: record.currency_code,
          shippingFee: record.shipping_fee,
          product: {
            uuid: record.product.uuid,
            name: record.product.name,
            attributes: record.product.attributes,
            serialNumber: record.product.serial_number,
          },
          node: {
            uuid: record.node.uuid,
            name: record.node.name,
            requestEmployee: {
              uuid: record.node.request_employee.uuid,
              firstName: record.node.request_employee.first_name,
              lastName: record.node.request_employee.last_name,
            },
          },
        };
      });
      state.warrantyReimbursementTable.filteredTotalRecords =
        payload.recordsFiltered;
      state.warrantyReimbursementTable.totalRecords = payload.recordsTotal;
    },

    clearWarrantyReimbursementTable(state, _) {
      state.warrantyReimbursementTable =
        initialState.warrantyReimbursementTable;
    },

    setWarrantyReimbursementDetail(state, action) {
      const { payload } = action;
      const mediasJson = JSON.parse(payload.receipt_proof);

      state.warrantyReimbursementDetail.uuid = payload.uuid;
      state.warrantyReimbursementDetail.currencyCode = payload.currency_code;
      state.warrantyReimbursementDetail.amount = payload.amount;
      state.warrantyReimbursementDetail.status = payload.status;
      state.warrantyReimbursementDetail.remark = payload.remark;
      state.warrantyReimbursementDetail.receiptProof = {
        imageFile: mediasJson.image_file,
        videoFile: mediasJson.video_file,
      } || { imageFile: [], videoFile: [] };
      state.warrantyReimbursementDetail.respondedAt = payload.responded_at;
      state.warrantyReimbursementDetail.responderEmployee =
        payload.responder_employee
          ? {
              uuid: payload.responder_employee.uuid,
              email: payload.responder_employee.email,
              firstName: payload.responder_employee.first_name,
              lastName: payload.responder_employee.last_name,
            }
          : null;
      state.warrantyReimbursementDetail.rejectedReason =
        payload.rejected_reason;
      state.warrantyReimbursementDetail.referenceCode = payload.reference_code;
      state.warrantyReimbursementDetail.createdAt = payload.created_at;
      state.warrantyReimbursementDetail.product = {
        uuid: payload.product.uuid,
        name: payload.product.name,
        attributes: payload.product.attributes,
        serialNumber: payload.product.serial_number,
      };
      state.warrantyReimbursementDetail.warrantyClaim = {
        uuid: payload.warranty_claim.uuid,
        status: payload.warranty_claim.status,
        remark: payload.warranty_claim.remark,
        approvedAt: payload.warranty_claim.approved_at,
        rejectedReason: payload.warranty_claim.rejected_reason,
        referenceCode: payload.warranty_claim.reference_code,
        consumer: {
          uuid: payload.warranty_claim.consumer.uuid,
          email: payload.warranty_claim.consumer.email,
          fullName: payload.warranty_claim.consumer.full_name,
          phoneCode: payload.warranty_claim.consumer.phone_code,
          phoneNumber: payload.warranty_claim.consumer.phone_number,
          phoneCountry: payload.warranty_claim.consumer.phone_country,
        },
      };
      state.warrantyReimbursementDetail.node = {
        id: payload.node.uuid,
        name: payload.node.name,
        contactCode: payload.node.contact_code,
        contact: payload.node.contact,
        email: payload.node.email,
        timezone: payload.node.timezone,
        address: {
          line1: payload.node.address.line1,
          line2: payload.node.address.line2,
          city: payload.node.address.city,
          postalCode: payload.node.address.postal_code,
          state: payload.node.address.state,
          country: payload.node.address.country,
        },
        requestEmployee: {
          uuid: payload.node.request_employee.uuid,
          email: payload.node.request_employee.email,
          firstName: payload.node.request_employee.first_name,
          lastName: payload.node.request_employee.last_name,
        },
      };
    },

    clearWarrantyReimbursementDetail(state, _) {
      state.warrantyReimbursementDetail =
        initialState.warrantyReimbursementDetail;
    },

    setWarrantyReimbursementForm(state, action) {
      const { payload } = action;

      state.warrantyReimbursementForm = {
        reimbursementId: payload.reimbursementUuid,
        currencyCode: payload.currencyCode.alpha3,
        currencyExp: payload.currencyCode.exp,
        currencyName: payload.currencyCode.name,
        amount: payload.amount,
        receiptProof: payload.receiptProof,
        remark: payload.remark,
      };
    },

    clearWarrantyReimbursementForm(state, _) {
      state.warrantyReimbursementForm = initialState.warrantyReimbursementForm;
    },

    setWarrantyReimbursementEditType(state, action) {
      const { payload } = action;

      state.reimbursementType = payload;
    },

    setWarrantyReimbursementLoadingError(state, action) {
      const { payload } = action;

      state.isLoadingWarrantyReimbursementError = payload;
    },

    setItemReplacementTable(state, action) {
      const { payload } = action;

      state.itemReplacementTable.data = payload.data.map((record) => {
        return {};
      });
      state.itemReplacementTable.filteredTotalRecords = payload.recordsFiltered;
      state.itemReplacementTable.totalRecords = payload.recordsTotal;
    },

    clearItemReplacementTable(state, _) {
      state.itemReplacementTable = initialState.itemReplacementTable;
    },

    setWarrantySettingClaimReason(state, action) {
      const { payload } = action;

      state.warrantySetting.claimReasons =
        payload || state.warrantySetting.claimReasons;
    },
    updateWarrantyPageSetting(state, action) {
      const { payload } = action;
      state.pageSetting = {
        ...state.pageSetting,
        ...payload,
      };
    },
  },
  extraReducers: {
    [fetchWarrantyListTable.fulfilled]: (state, action) => {
      state.isLoadingWarrantyError = false;
    },
    [fetchWarrantyListTable.rejected]: (state, action) => {
      state.isLoadingWarrantyError = true;
    },
    [fetchWarranteeListTable.fulfilled]: (state, action) => {
      state.isLoadingWarrantyParticipantError = false;
    },
    [fetchWarranteeListTable.rejected]: (state, action) => {
      window.history.go(-1);
      state.isLoadingWarrantyParticipantError = true;
    },
    [fetchWarrantyLogTable.fulfilled]: (state, action) => {
      state.isLoadingWarrantyLogError = false;
    },
    [fetchWarrantyLogTable.rejected]: (state, action) => {
      state.isLoadingWarrantyLogError = true;
    },
  },
});

export const warrantyReducer = warrantySlice.reducer;
export const {
  setSelectedWarrantyExtension,
  setWarrantySetting,
  setRegisterForm,
  setRegisterFormEdit,
  setRegisterFormFinishEdit,
  setNewField,
  setRegisterFormDelete,
  setWarrantyRegisterForm,
  setWarrantyExtension,
  setWarrantyExtensionFinishEdit,
  setWarrantyExtensionDelete,
  setType,
  resetRegisterForm,
  resetWarrantySetting,
  resetRegisterFormType,
  setWarrantySettingTermConditionDetail,
  setWarrantyList,
  setWarrantyDetail,
  setWarrantyID,
  setWarrantyNameDropdown,
  setWarrantyExtensionListTable,
  setWarrantyExtensionBatchTable,
  fetchMoreWarrantyExtension,
  resetWarrantyExtension,
  setWarranteeList,
  setConsumerWarrantyDetail,
  setConsumerWarrantyDetailStatus,
  setWarrantyLogTable,
  setWarrantySettingUpdate,
  reorderRegisterFormField,
  setIsWarrantyExtensionBatchTableDirty,
  setIsWarranteeTableDirty,
  setWarranteeClaimsTable,
  updateWarranteeClaimsTable,
  setConsumerClaimsTable,
  clearConsumerClaimsTable,
  setConsumerClaimDetail,
  clearConsumerClaimDetail,
  setWarrantyReimbursementTable,
  clearWarrantyReimbursementTable,
  setWarrantyReimbursementDetail,
  clearWarrantyReimbursementDetail,
  setWarrantyReimbursementForm,
  clearWarrantyReimbursementForm,
  setWarrantyReimbursementEditType,
  setWarrantyReimbursementLoadingError,
  setItemReplacementTable,
  clearItemReplacementTable,
  setWarrantySettingClaimReason,
  updateWarrantyPageSetting,
} = warrantySlice.actions;
