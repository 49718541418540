import {
  createBranch,
  editBranch,
  fetchBranchList,
  editBranchStatus,
  fetchBranchDropdown,
  getBranchGroupDropdown, getCustomField
} from "./branch.action";
import { addAlert, setLoading } from "modules/notification";
import { getApiLang } from "app/feature/constants";

const fetchBranchListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchBranchList.pending.type:
        dispatch(setLoading({ id: fetchBranchList.typePrefix, state: true }));
        break;
      case fetchBranchList.fulfilled.type:
        dispatch(setLoading({ id: fetchBranchList.typePrefix, state: false }));
        break;
      case fetchBranchList.rejected.type:
        const lang = getState().constant.languages
        dispatch(setLoading({ id: fetchBranchList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code)
          })
        );
        break;
      default:
        break;
    }
  };

  
const getBranchGroupDropdownMiddleware =
({ dispatch, getState }) =>
(next) =>
async (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case getBranchGroupDropdown.pending.type:
      dispatch(setLoading({ id: getBranchGroupDropdown.typePrefix, state: true }));
      break;
    case getBranchGroupDropdown.fulfilled.type:
      dispatch(setLoading({ id: getBranchGroupDropdown.typePrefix, state: false }));
      break;
    case getBranchGroupDropdown.rejected.type:
      const lang = getState().constant.languages
      dispatch(setLoading({ id: getBranchGroupDropdown.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;
    default:
      break;
  }
};

const createBranchMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case createBranch.pending.type:
        dispatch(setLoading({ id: createBranch.typePrefix, state: true }));
        break;
      case createBranch.fulfilled.type:
        dispatch(setLoading({ id: createBranch.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CREATE_BRANCH"),
          })
        );
        break;
      case createBranch.rejected.type:
        dispatch(setLoading({ id: createBranch.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code)
          })
        );
        break;
      default:
        break;
    }
  };

const editBranchMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case editBranch.pending.type:
        dispatch(setLoading({ id: editBranch.typePrefix, state: true }));
        break;
      case editBranch.fulfilled.type:
        dispatch(setLoading({ id: editBranch.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_EDIT_BRANCH"),
          })
        );
        break;
      case editBranch.rejected.type:
        dispatch(setLoading({ id: editBranch.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const editBranchStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case editBranchStatus.pending.type:
        dispatch(setLoading({ id: editBranchStatus.typePrefix, state: true }));
        break;
      case editBranchStatus.fulfilled.type:
        dispatch(setLoading({ id: editBranchStatus.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_EDIT_BRANCH_STATUS"),
          })
        );
        break;
      case editBranchStatus.rejected.type:
        dispatch(setLoading({ id: editBranchStatus.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const fetchBranchDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const { type, payload } = action;

    switch (type) {
      case fetchBranchDropdown.pending.type:
        dispatch(
          setLoading({ id: fetchBranchDropdown.typePrefix, state: true })
        );
        break;

      case fetchBranchDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: fetchBranchDropdown.typePrefix, state: false })
        );
        break;

      case fetchBranchDropdown.rejected.type:
        const lang = getState().constant.languages
        dispatch(
          setLoading({ id: fetchBranchDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getCustomFieldMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      async (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case getCustomField.pending.type:
            dispatch(setLoading({ id: getCustomField.typePrefix, state: true }));
            break;
          case getCustomField.fulfilled.type:
            dispatch(setLoading({ id: getCustomField.typePrefix, state: false }));
            break;
          case getCustomField.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getCustomField.typePrefix, state: false }));
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, payload.code)
              })
            );
            break;
          default:
            break;
        }
      };


export const branchMiddleware = [
  fetchBranchListMiddleware,
  createBranchMiddleware,
  editBranchMiddleware,
  editBranchStatusMiddleware,
  fetchBranchDropdownMiddleware,
  getBranchGroupDropdownMiddleware,
  getCustomFieldMiddleware
];
