import generateSerialNumber from "./generateSerialNumber.api";
import getTagsDropdown from "./getTagsDropdown.api";
import getBatchRangeList from "./getBatchRangeList.api";
import getBulkEditProductList from "./getBulkEditProductList.api";
import product from "./product.api";
import products from "./products.api";
import productui from "./productui.api";
import listingSerialNumber from "./listingSerialNumber.api";
import generateBatch from "./generatesBatch.api";
import tagSerialNumber from "./tagSerialNumber.api";
import getRangeList from "./getRangeList.api";
import assignSerialNumber from "./assignSerialNumber.api";
import detailsSerialNumber from "./detailsSerialNumber.api";
import resetScanCount from "./resetScanCount.api";
import updateSerialNumberStatus from "./updateSerialNumberStatus.api";
import getRangeListByBranch from "./getRangeListByBranch.api";
import updateBatchAppAuthorizeStatus from "./updateBatchAppAuthorizeStatus.api";
import getSerialNumberScanListing from "./getSerialNumberScanListing.api";
import getCampaignAssigned from "./getCampaignAssigned.api";
import getSerialNumberActivityLog from "./getSerialNumberActivityLog.api";
import getScanTrackDetail from "./getScanTrackDetail.api";
import getAllProducts from "./allProducts.api";
import checkSerialNumberAvailability from "./checkSerialNumberAvailability.api";
import getAssignListing from "./getAssignListing.api";
import getAssignProgress from "./getAssignProgress.api";
import getAssignFailJob from "./getAssignFailJob.api";
import retryAssignFailJob from "./retryAssignFailJob.api";
import getGenerateProgress from "./getGenerateProgress.api";
import getExportFileDropdown from "./getExportFileDropdown.api";
import exportAssignSN from "./exportAssignSN.api";
import getTagDetail from "./getTagDetail.api";
import fetchTableLength from "./fetchTableLength.api";
import updateBatchScanVerifyStatus from "./updateBatchScanVerifyStatus.api";
import updateBatchRedirectTo from "./updateBatchRedirectTo.api";
import getSnSummaryCount from "./getSnSummaryCount.api";

import getAdtInfoListing from "./additionalInfo/getAdtInfoListing.api";
import addAdtInfoTitle from "./additionalInfo/addAdtInfoTitle.api";
import editAdtInfoTitle from "./additionalInfo/editAdtInfoTitle.api";
import getAdtInfoTitles from "./additionalInfo/getAdtInfoTitle.api";
import exportAdtInfoForm from "./additionalInfo/exportAdtInfoForm.api";
import validateAdtInfoForm from "./additionalInfo/validateAdtInfoForm.api";
import importAdtInfoForm from "./additionalInfo/importAdtInfoForm.api";
import checkHasPendingProcess from "./additionalInfo/checkHasPendingProcess.api";
import updateAdditionalInfo from "./additionalInfo/updateAdditionalInfo.api";
import getAdditionalInfoDropdown from "./additionalInfo/getAdditionalInfoDropdown.api";
import getAdtSnListing from "./additionalInfo/getAdtSnListing.api";
import getAdditionalInfoSequence from "./additionalInfo/getAdditionalInfoSequence.api";
import updateAdditionalInfoSequence from "./additionalInfo/updateAdditionalInfoSequence.api";
import updateSnAdtInfo from "./additionalInfo/updateSnAdtInfo.api";
import getAdtInfoImportLog from "./additionalInfo/getAdtInfoImportLog.api";
import getImportProgress from "./additionalInfo/getImportProgress.api";
import getAdtProductListing from "./additionalInfo/getAdtProductListing.api";
import deleteAdtInfoTitle from "./additionalInfo/deleteAdtInfoTitle.api";
import checkFacebookEmbed from "./checkFacebookEmbed.api";

import unassignSerialNumber from "./unassign/unassignSerialNumber.api";
import getUnassignListing from "./unassign/getUnassignListing.api";
import getUnassignProgress from "./unassign/getUnassignProgress.api";
import retryUnassignFailJob from "./unassign/retryUnassignFailJob.api";
import getUnassignSnListByProduct from "./unassign/getUnassignSnListByProduct.api";
import checkSNUnassignAvailability from "./unassign/checkSNUnassignAvailability.api";
import getUnassignDetail from "./unassign/getUnassignDetail.api";
import updateSerialNumberBlockStatus from "./updateSerialNumberBlockStatus.api";

const serialNumApi = {
  generateSerialNumber,
  getBulkEditProductList,
  product,
  products,
  productui,
  listingSerialNumber,
  generateBatch,
  tagSerialNumber,
  getTagsDropdown,
  getBatchRangeList,
  getRangeList,
  assignSerialNumber,
  detailsSerialNumber,
  resetScanCount,
  updateSerialNumberStatus,
  getRangeListByBranch,
  updateBatchAppAuthorizeStatus,
  getSerialNumberScanListing,
  getCampaignAssigned,
  getSerialNumberActivityLog,
  getScanTrackDetail,
  getAllProducts,
  checkSerialNumberAvailability,
  getAssignListing,
  getAssignProgress,
  getAssignFailJob,
  retryAssignFailJob,
  getGenerateProgress,
  getExportFileDropdown,
  exportAssignSN,
  getTagDetail,
  updateBatchScanVerifyStatus,
  updateBatchRedirectTo,
  fetchTableLength,
  getSnSummaryCount,
  unassignSerialNumber,
  getUnassignListing,
  getUnassignProgress,
  retryUnassignFailJob,
  getUnassignSnListByProduct,
  checkSNUnassignAvailability,
  getUnassignDetail,
  updateSerialNumberBlockStatus,

  getAdtInfoListing,
  editAdtInfoTitle,
  addAdtInfoTitle,
  getAdtInfoTitles,
  checkHasPendingProcess,
  validateAdtInfoForm,
  exportAdtInfoForm,
  importAdtInfoForm,
  updateAdditionalInfo,
  getAdditionalInfoDropdown,
  getAdtSnListing,
  getAdditionalInfoSequence,
  updateAdditionalInfoSequence,
  updateSnAdtInfo,
  getAdtInfoImportLog,
  getImportProgress,
  getAdtProductListing,
  deleteAdtInfoTitle,
  checkFacebookEmbed,
};

export default serialNumApi;
