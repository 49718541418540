import axios from "axios";

export default function getWarrantyClaimList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/consumer/admin/api/v1/prife/claims`, {
        start: parameters.start,
        length: parameters.length,
        filter: {
          status: parameters.status,
          search: parameters.search,
          search_column: parameters.search_column,
          from_date: parameters.from_date,
          to_date: parameters.to_date,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
