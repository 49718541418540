import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLang } from "app/feature/constants";
import { addAlert, selectLoading } from "modules/notification";
import { isFileExceedLimit } from "lib/helper";
import { convertHeicToJPG, isValidFile, toBase64 } from "lib/FileHelper";
import { uploadImageToS3, uploadFileToS3 } from "app/feature";
import { FolderType } from "lib/constants/aws_s3";
import { v4 as uuidV4 } from "uuid";

import { getCurrencyDropdown } from "modules/product-mgmt/redux/action/productPage.action";
import {
  createReimbursement,
  updateReimbursement,
} from "modules/warranty/redux";
import { clearWarrantyReimbursementForm } from "modules/warranty/redux";

import ReimbursementFormComponent from "./reimbursementForm.component";
import GeneralDialog from "components/dialog/generalDialog";

export default function ReimbursementFormContainer({
  lang,
  children,
  claimItem,
  handleTableReload,
  submitType = "create",
  formMedia,

  //  DISPLAY IMAGE DIALOG  //
  handleMediaClick,
}) {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.profile.id);
  const currencyDropdown = useSelector(
    (state) => state.productPage.currencyDropdown
  );
  const isCurrencyFetching = useSelector((state) =>
    selectLoading(state, getCurrencyDropdown.typePrefix)
  );
  const reimbursementForm = useSelector(
    (state) => state.warranty.warrantyReimbursementForm
  );

  const companyUuid = useSelector((state) => state.account.id);
  const employee = useSelector((state) => state.profile);

  const [filePreviews, setFilePreviews] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    dispatch(getCurrencyDropdown());
    if (formMedia) {
      setFilePreviews(formMedia);
    }

    return () => {
      dispatch(clearWarrantyReimbursementForm());
    };
    // eslint-disable-next-line
  }, [dispatch, formMedia]);

  const handleFileUpload = async (files) => {
    setIsFileUploading(true);
    let temp = filePreviews;

    for (const file of files) {
      const type = file.type.includes("image")
        ? "image"
        : file.type.includes("video")
        ? "video"
        : null;

      if (!type) {
        dispatch(
          addAlert({
            severity: "error",
            message: `${file.name} ${getLang(
              lang,
              "message.error.INVALID_FILE_TYPE"
            )}`,
          })
        );
        continue;
      }

      const sizeLimit = type === "image" ? 30 : type === "video" ? 100 : null;

      if (isFileExceedLimit(file.size, sizeLimit, "MB")) {
        dispatch(
          addAlert({
            severity: "error",
            message: `(${file.name}) ${getLang(
              lang,
              "message.error.FILE_SIZE_EXCEED_LIMIT_VALUE",
              { value: `${sizeLimit}MB` }
            )}`,
          })
        );
        continue;
      }

      const filePreview = { file, src: null };
      temp = [filePreview].concat(temp);
      setFilePreviews(temp);

      switch (type) {
        case "image":
          let base64Image;

          if (file.type.includes("heic") || file.type.includes("heif")) {
            base64Image = await convertHeicToJPG(file);
          } else {
            base64Image = await toBase64(file);
          }

          let filename = file.name;
          let extension = filename.substring(filename.lastIndexOf(".") + 1);
          extension =
            extension.length === 0 ? file.type.split("/")[1] : extension;

          const response = await dispatch(
            uploadImageToS3({
              uploadType: FolderType.warrantyReimbursement,
              fileName: `${uuidV4()}_${filename.substring(
                0,
                filename.lastIndexOf(".")
              )}.${extension}`,
              base64Image: base64Image,
              id: userId,
            })
          );

          const result = response.type.split("/");
          if (result[result.length - 1] === "fulfilled") {
            filePreview["src"] = response.payload.split("?")[0];
          } else {
            continue;
          }
          break;

        case "video":
          if (file && isValidFile(file)) {
            const response = await dispatch(
              uploadFileToS3({
                uploadType: FolderType.warrantyReimbursement,
                fileName: `${uuidV4()}_${file.name}`,
                file,
                id: userId,
              })
            );

            const result = response.type.split("/");
            if (result[result.length - 1] === "fulfilled") {
              filePreview["src"] = response.payload.split("?")[0];
            } else {
              continue;
            }
          }
          break;

        default:
          break;
      }
    }

    setIsFileUploading(false);
    return temp;
  };

  const handleFileRemove = (index) => {
    const temp = [...filePreviews];
    temp.splice(index, 1);
    setFilePreviews(temp);
  };

  const handleReimbursementSubmit = async () => {
    const uploadImageUrls = [...formValues.receiptProof]
      .filter((proof) => proof.file.type.includes("image"))
      .map((proof) => proof.src);
    const uploadVideoUrls = [...formValues.receiptProof]
      .filter((proof) => proof.file.type.includes("video"))
      .map((proof) => proof.src);

    const info = {
      companyUuid: companyUuid,
      nodeUuid: employee.nodeName[0].uuid,
      employeeUuid: employee.id,
      employeeEmail: employee.email,
      claimUuid: claimItem.uuid,
      claimReferenceCode: claimItem.referenceCode,
      consumerEmail: claimItem.warranteeEmail,
      currencyCode: formValues.currencyCode.alpha3,
      currencyExp: formValues.currencyCode.exp,
      currencyName: formValues.currencyCode.name,
      amount: formValues.amount,
      uploadedFileUrl: {
        videoFiles: uploadVideoUrls,
        imageFiles: uploadImageUrls,
      },
      remark: formValues.remark,
    };

    if (submitType === "create") {
      await dispatch(createReimbursement(info));
    } else if (submitType === "edit") {
      info.reimbursementID = reimbursementForm.reimbursementId;
      await dispatch(updateReimbursement(info));
    }
    handleTableReload();
  };

  return (
    <>
      <ReimbursementFormComponent
        lang={lang}
        submitType={submitType}
        currencyDropdown={currencyDropdown}
        isCurrencyFetching={isCurrencyFetching}
        filePreviews={filePreviews}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove}
        handleMediaClick={handleMediaClick}
        isFileUploading={isFileUploading}
        initialValues={reimbursementForm}
        //  SUBMIT BUTTON  //
        children={children}
        handleReimbursementSubmit={(values) => {
          setFormValues(values);
          setIsConfirmDialogOpen(true);
        }}
      />
      <GeneralDialog
        isOpen={isConfirmDialogOpen}
        handleClose={() => setIsConfirmDialogOpen()}
        handleProceed={handleReimbursementSubmit}
        title={getLang(
          lang,
          "paragraph.COMFIRM_SUBMIT_REIMBURSEMENT_FORM_TITLE"
        )}
        description={getLang(
          lang,
          "paragraph.COMFIRM_SUBMIT_REIMBURSEMENT_FORM_CONTENT"
        )}
        type="danger"
      />
    </>
  );
}
