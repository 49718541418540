import React, { useContext, useEffect } from "react";
import DefaultDashboardPanelComponent from "./defaultDashboardPanel.page";
import { useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";
import { useState } from "react";
import { getLocation } from "lib/helper";

function DefaultDashboardPanelContainer({ isCollapsible }) {
  const lang = useSelector((state) => state.constant.languages);
  const { currentFilter, activeIndex, showPopup, closePopup, popupIsShow } =
    useContext(DashboardContext);
  const [defaultGeoLocation, setDefaultGeoLocation] = useState();

  useEffect(() => {
    getLocation()
      .then((geo) => {
        setDefaultGeoLocation({ lat: geo.lat, lng: geo.lng }); // ✅ Update state when location is retrieved
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
      });
  }, []);

  return (
    <DefaultDashboardPanelComponent
      currentFilter={currentFilter}
      lang={lang}
      activeIndex={activeIndex}
      showPopup={showPopup}
      closePopup={closePopup}
      popupIsShow={popupIsShow}
      isCollapsible={isCollapsible}
      defaultGeoLocation={defaultGeoLocation}
    />
  );
}

export default DefaultDashboardPanelContainer;
