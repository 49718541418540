import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "../../../../../app/feature/constants";
import RejectClaimDialogComponent from "./rejectClaimDialog.component";

function RejectClaimDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const reasons = [
    getLang(lang, "paragraph.REJECT_CLAIMS_REASON1"),
    getLang(lang, "paragraph.REJECT_CLAIMS_REASON2"),
    getLang(lang, "paragraph.REJECT_CLAIMS_REASON3"),
  ];

  return (
    <RejectClaimDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      reasons={reasons}
      lang={lang}
      isLoading={isLoading}
    />
  );
}

RejectClaimDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RejectClaimDialogContainer;
