import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  TextField,
  Divider,
  Typography,
} from "@material-ui/core";
import SerialNumTable from "../../components/table/serialNumTable";
import FilterSvg from "assets/svg/filter.svg";
import clsx from "clsx";
//import ProductSelect from "modules/serial-number/components/select/productSelect";
import ProductSearch from "modules/stockflow/components/select/productSearch";
// import BranchSearch from "modules/stockflow/components/select/branchSearch";
import BranchSearchSelect from "modules/serial-number/components/select/branchSearchSelect";
import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import SearchableSelect from "components/select/searchableSelect";
import SnSummaryCard from "modules/serial-number/components/card/snSummaryCard";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "7.5fr 2.5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  onFocusBorder: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.secondary.main,
  },
  colorIsSelected: {
    background: "#EBEDEE",
  },
  icon: {
    borderRight: "1px solid #3A4D5430",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
    width: "100%",
    alignItems: "center",
  },
  select: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  filterContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: "12px 20px",
    marginBottom: "12px",
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  infoControlSection: {
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    padding: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  summaryContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    columnGap: theme.spacing(1.5),
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function ListingComponent({
  hasSerialNumberEditAccessRight,
  hasSerialNumberGenerateAccessRight,
  listingSerialNumberData,
  page,
  search,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isFetching,
  length,
  language,
  handleSearch,
  clearSearch,
  handleReload,
  handleStatusChange,
  paginationOptions,
  productDropdown,
  lang,
  tableFilter,
  handleViewDetail,
  showFilter,
  setShowFilter,
  isSearchResult,
  summaryCount,
  isSnCountLoading,
  isError,
  setQueryParam,
  branchDropdown,
  isBranchDropdownLoading,
  isProductDropdownLoading,
  handleBlockStatusChange,
}) {
  const classes = useStyle();
  const textRef = useRef();

  const [onFocus, setOnFocus] = useState(false);
  const columnDropdown = [
    { name: getLang(lang, "label.SERIAL_NUMBER"), value: "serial_number" },
    { name: getLang(lang, "label.SEQUENCE_NUMBER"), value: "seq_number" },
    { name: getLang(lang, "label.BATCH_NUMBER"), value: "batch_number" },
  ];

  const statusDropdown = [
    { name: "-", value: "" },
    { name: getLang(lang, "label.ACTIVE"), value: "active" },
    { name: getLang(lang, "label.SUSPEND"), value: "suspend" },
    { name: getLang(lang, "label.BLOCKED"), value: "blocked" },
    { name: getLang(lang, "label.DISABLED"), value: "disabled" },
    { name: getLang(lang, "label.MISSING"), value: "missing" },
    { name: getLang(lang, "label.FAULTY"), value: "faulty" },
    { name: getLang(lang, "label.REPLACED"), value: "replace" },
  ];

  const summaryList = [
    { title: getLang(lang, "label.TOTAL"), value: summaryCount.total },
    { title: getLang(lang, "label.ASSIGNED"), value: summaryCount.assigned },
    { title: getLang(lang, "label.ACTIVE"), value: summaryCount.active },
    { title: getLang(lang, "label.SUSPENDED"), value: summaryCount.suspend },
  ];

  return (
    <>
      <Box className={classes.summaryContainer} mt={1}>
        {summaryList.map((summary, index) => (
          <Box key={index}>
            <SnSummaryCard
              title={summary.title}
              value={summary.value}
              isLoading={isSnCountLoading}
            />
          </Box>
        ))}
      </Box>
      <Formik
        initialValues={{
          searchColumn: tableFilter.searchColumn || columnDropdown[0].value,
          search: tableFilter.search,
          status: tableFilter.status
            ? statusDropdown.find((v) => v.value === tableFilter.status)
            : statusDropdown[0],
          branch: tableFilter.branch,
          products: tableFilter.products,
          productNotAssigned: tableFilter.productNotAssigned,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Box
                display="flex"
                className={clsx(classes.dropdownSearchBox, {
                  [classes.onFocusBorder]: onFocus,
                })}
              >
                <TextField
                  style={{ minWidth: "unset" }}
                  className={classes.select}
                  select
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        style: { padding: 0 },
                      },
                      style: { marginTop: 5 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  value={formik.values.searchColumn}
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.inputRoot },
                  }}
                >
                  {columnDropdown.map((column, index) => (
                    <MenuItem
                      className={classes.menuItemText}
                      value={column.value}
                      key={index}
                      onClick={() => {
                        formik.setFieldValue("searchColumn", column.value);
                        setQueryParam("stype", column.value);
                      }}
                    >
                      {column.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider orientation="vertical" className={classes.divider} />
                <TextField
                  fullWidth={true}
                  onFocus={() => setOnFocus(true)}
                  onBlur={() => setOnFocus(false)}
                  size="small"
                  margin="none"
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                  inputRef={textRef}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                  }}
                  inputProps={{
                    className: classes.textFieldInput,
                  }}
                  value={formik.values.search}
                  onChange={(event) => {
                    formik.setFieldValue("search", event.target.value);
                    setQueryParam("q", event.target.value);
                  }}
                />
              </Box>
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                <Typography
                  variant="body2"
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                >
                  {getLang(lang, "label.ADD_FILTER")}
                </Typography>
              </Button>
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                disabled={isFetching}
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
                disabled={isFetching}
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {showFilter && (
              <Box className={classes.filterContainer}>
                <SearchableSelect
                  value={formik.values.status}
                  handleChange={(value) => {
                    formik.setFieldValue("status", value);
                    setQueryParam("status", value.value);
                  }}
                  placeholder={getLang(lang, "placeholder.STATUS")}
                  disabled={formik.isSubmitting || isFetching}
                  searchable={false}
                  panelStyle={{ width: "249px" }}
                  PaperProps={{
                    classes: { root: classes.paperRoot },
                  }}
                  dropdownItem={statusDropdown}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  isEmpty={!formik.values.status.value}
                  returnType="object"
                />
                <BranchSearchSelect
                  placeholder={getLang(lang, "placeholder.BRANCH")}
                  value={formik.values.branch}
                  handleChange={(value) => {
                    formik.setFieldValue("branch", value);
                    setQueryParam(
                      "br",
                      branchDropdown.every((i) =>
                        value.map((i) => i.node_id).includes(i.node_id)
                      )
                        ? "all"
                        : value.map((i) => i.node_id).join(",")
                    );
                  }}
                  branchDropdown={branchDropdown}
                  isBranchDropdownLoading={isBranchDropdownLoading}
                  isEmpty={formik.values.branch?.length === 0}
                  styles={{
                    padding: "0.25em 0.75em",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: 4,
                    width: "100%",
                    overflow: "hidden",
                    height: 38,
                  }}
                  disabled={isFetching}
                  handleChipDelete={(branch) => {
                    let temp = [...formik.values.branch];
                    if (temp.length > 0) {
                      const filteredBranchs = temp.filter(
                        (item) => item.node_id !== branch.node_id
                      );
                      temp = [...filteredBranchs];
                    }
                    formik.setFieldValue("branch", temp);
                    setQueryParam("br", temp.map((i) => i.node_id).join(","));
                  }}
                />
                <ProductSearch
                  searchable={true}
                  value={formik.values.products}
                  placeholder={getLang(lang, "placeholder.SEARCH_BY_PRODUCT")}
                  searchPlaceholder={getLang(
                    lang,
                    "placeholder.SEARCH_A_PRODUCT_NAME"
                  )}
                  isClearable={false}
                  emptyMessage={getLang(lang, "message.info.NO_PRODUCT_FOUND")}
                  dropdownItem={productDropdown}
                  isEmpty={formik.values.products?.length === 0}
                  selectedProduct={formik.values.products}
                  style={{
                    padding: "0.25em 0.75em",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: 4,
                    width: "100%",
                    overflow: "hidden",
                    height: 38,
                  }}
                  isNotAssignSelected={tableFilter.productNotAssigned}
                  handleSelectAll={async (selectAll) => {
                    if (selectAll) {
                      await formik.setFieldValue("products", productDropdown);
                      setQueryParam("pd", "all");
                    } else {
                      await formik.setFieldValue("products", []);
                      setQueryParam("pd", "");
                    }
                  }}
                  handleSelectNotAssigned={(selectNotAssign) => {
                    formik.setFieldValue("products", []);
                    formik.setFieldValue("productNotAssigned", selectNotAssign);
                    setQueryParam("pd", selectNotAssign ? "na" : "");
                  }}
                  handleChange={(value) => {
                    formik.setFieldValue("productNotAssigned", false);

                    let temp = [...formik.values.products];

                    if (temp.length === 0) {
                      temp.push(value);
                    } else {
                      const isProductExist = temp.find(
                        (product) => product.id === value.id
                      );

                      if (isProductExist === undefined) {
                        temp.push(value);
                      } else {
                        let filter = temp.filter(
                          (product) => product.id !== value.id
                        );

                        temp = [...filter];
                      }
                    }
                    formik.setFieldValue("products", [...temp]);
                    setQueryParam("pd", temp.map((i) => i.id).join(","));
                  }}
                  handleChipDelete={(product) => {
                    let temp = [...formik.values.products];
                    if (temp.length > 0) {
                      const filteredProducts = temp.filter(
                        (item) => item.id !== product.id
                      );
                      temp = [...filteredProducts];
                    }
                    formik.setFieldValue("products", temp);
                    setQueryParam("pd", temp.map((i) => i.id).join(","));
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  disabled={isFetching}
                />
              </Box>
            )}
          </form>
        )}
      </Formik>

      <SerialNumTable
        hasSerialNumberEditAccessRight={hasSerialNumberEditAccessRight}
        hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
        isFetching={isFetching}
        listingSerialNumberData={listingSerialNumberData}
        rowsPerPage={rowsPerPage}
        page={page}
        search={search}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        length={length}
        language={language}
        handleReload={handleReload}
        handleStatusChange={handleStatusChange}
        paginationOptions={paginationOptions}
        lang={lang}
        handleViewDetail={handleViewDetail}
        isSearchResult={isSearchResult}
        isError={isError}
        isBranchDropdownLoading={isBranchDropdownLoading}
        isProductDropdownLoading={isProductDropdownLoading}
        handleBlockStatusChange={handleBlockStatusChange}
      />
    </>
  );
}
