import { createAsyncThunk } from "@reduxjs/toolkit";
import customFeatureApi from "../../../app/api/customFeature/prife";

const INTEGRATION = "integration";

export const getPendingCheckoutListing = createAsyncThunk(
  `${INTEGRATION}/getPendingCheckoutListing`,
  async (payload, thunkApi) => {
    return customFeatureApi.getPendingCheckoutListing(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getCompletedCheckoutListing = createAsyncThunk(
  `${INTEGRATION}/getCompletedCheckoutListing`,
  async (payload, thunkApi) => {
    return customFeatureApi.getCompletedCheckoutListing(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getCheckoutDetail = createAsyncThunk(
  `${INTEGRATION}/getCheckoutDetail`,
  async (payload, thunkApi) => {
    return customFeatureApi.getCheckoutDetail(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getCheckoutBoxList = createAsyncThunk(
  `${INTEGRATION}/getCheckoutBoxList`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .getCheckoutBoxList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutProductList = createAsyncThunk(
  `${INTEGRATION}/getCheckoutProductList`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .getCheckoutProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutProductDetail = createAsyncThunk(
  `${INTEGRATION}/getCheckoutProductDetail`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .getCheckoutProductDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateCheckoutDeliveryInfo = createAsyncThunk(
  `${INTEGRATION}/updateCheckoutDeliveryInfo`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .updateCheckoutDeliveryInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutDeliveryInfo = createAsyncThunk(
  `${INTEGRATION}/getCheckoutDeliveryInfo`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .getCheckoutDeliveryInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateCheckoutDetail = createAsyncThunk(
  `${INTEGRATION}/updateCheckoutDetail`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .updateCheckoutDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getWarrantyClaimList = createAsyncThunk(
  `${INTEGRATION}/getWarrantyClaimList`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .getWarrantyClaimList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
)

export const getWarrantyClaimDetail = createAsyncThunk(
  `${INTEGRATION}/getWarrantyClaimDetail`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .getWarrantyClaimDetail(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
)

export const processWarrantyClaim = createAsyncThunk(
  `${INTEGRATION}/processWarrantyClaim`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .processWarrantyClaim(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
)

export const handoffWarrantyClaim = createAsyncThunk(
  `${INTEGRATION}/handoffWarrantyClaim`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .handoffWarrantyClaim(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
)

export const updateClaimDetail = createAsyncThunk(
  `${INTEGRATION}/updateClaimDetail`,
  async (payload, { rejectWithValue }) => {
    return customFeatureApi
      .updateClaimDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
)
