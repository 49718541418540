import React from "react";
import { makeStyles, Box, Typography, Paper, Grow } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import RefreshTable from "components/table/refreshTable";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  greyText: {
    color: "#a1a1a1",
  },
  balanceContainer: {
    height: 16,

    "& > div": {
      height: "100%",
    },
  },
  usageContainer: {
    height: 10,
    backgroundColor: "#E9ECEF",
    "& > div": {
      backgroundColor: "#FF3333",
      height: "100%",
    },
  },
  usageIndicator: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    width: "fit-content",
    padding: 8,
    borderRadius: 4,
  },
  indicatorLine: {
    height: 50,
    borderLeft: "1px dotted black",
  },
  floatIndicatorContainer: {
    marginTop: -15,
  },
  card: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function CreditBalanceOverviewPanelComponent({
  lang,
  isFetching,
  isError,
  handleReload,
  disbursementInfo,
  overview,
}) {
  const classes = useStyle();
  return (
    <Box>
      <Box mb={0.5}>
        <Typography variant="body1">
          <b>{getLang(lang, "label.CREDIT_BALANCE_OVERVIEW")}</b>
        </Typography>
      </Box>
      {isFetching ? (
        <>
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
        </>
      ) : isError ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RefreshTable handleReload={handleReload} />
        </Box>
      ) : (
        <>
          <Box mt={1} mb={10}>
            <Box display="flex" alignItems="flex-end" flex={1} mb={1}>
              {overview.reloadRecords.map((r, i) => (
                <Box
                  key={i}
                  style={{
                    width: `${r.overview_percent}%`,
                  }}
                  display="flex"
                  justifyContent="center"
                >
                  <Grow in={true} key={i} timeout={500}>
                    <Paper elevation={3} style={{ width: "fit-content" }}>
                      <Box display="flex" alignItems="center" px={1} py={0.5}>
                        <Box
                          height={12}
                          width={12}
                          style={{
                            backgroundColor: r.color,
                          }}
                          mr={1.5}
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          onMouseEnter={() => {
                            const element = document.getElementById(
                              "reload_date" + r.uuid
                            );
                            if (element) {
                              element.style.display = "block";
                            }
                          }}
                          onMouseLeave={() => {
                            const element = document.getElementById(
                              "reload_date" + r.uuid
                            );
                            if (element) {
                              element.style.display = "none";
                            }
                          }}
                        >
                          <Typography
                            id={"reload_date" + r.uuid}
                            variant="caption"
                            style={{
                              display: "none",
                            }}
                          >
                            {r.reload_date} {getLang(lang, "label.RELOAD")}
                          </Typography>
                          <Typography variant="caption">
                            {disbursementInfo.currency}{" "}
                            {r.amount.toFixed(2).toLocaleString("en-US")}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Grow>
                </Box>
              ))}
            </Box>
            <Box
              display="flex"
              className={classes.balanceContainer}
              overflow="hidden"
              borderRadius={4}
              flex={1}
            >
              {overview.reloadRecords.map((r, i) => (
                <Box
                  position="relative"
                  key={i}
                  style={{
                    backgroundColor: r.color,
                    width: `${r.overview_percent}%`,
                  }}
                />
              ))}
            </Box>

            <Box position="relative">
              <Box
                width="1px"
                display="flex"
                justifyContent={
                  overview.creditUsagePercent <= 8
                    ? "flex-start"
                    : overview.creditUsagePercent >= 92
                    ? "flex-end"
                    : "center"
                }
                style={{ left: `${overview.creditUsagePercent}%` }}
                position="absolute"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={
                    overview.creditUsagePercent <= 8
                      ? "flex-start"
                      : overview.creditUsagePercent >= 92
                      ? "flex-end"
                      : "center"
                  }
                  style={{
                    marginLeft: overview.creditUsagePercent <= 8 ? -25 : 0,
                    marginRight: overview.creditUsagePercent >= 92 ? -25 : 0,
                  }}
                  className={classes.floatIndicatorContainer}
                >
                  <ArrowDropUpIcon
                    style={{
                      marginBottom: -17,
                      fontSize: 50,
                      color: "#FF3333",
                    }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.usageIndicator}
                  >
                    <Box
                      height={12}
                      width={12}
                      style={{
                        backgroundColor: "#FF3333",
                      }}
                      mr={1.5}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="caption"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {getLang(lang, "label.CREDIT_USAGE")}:{" "}
                        {overview.creditUsagePercent}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            mt={2}
            style={{
              display: "flex",
              justifyContent: "flex-start",

              gap: 20,
            }}
          >
            <Box style={{
           flexGrow: 1
            }}>
              {getLang(lang, "label.LIFETIME_CREDIT")}
              <Paper elevation={3} className={classes.card}>
                <Box
                  p={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Box>
                    <Typography>
                      {getLang(lang, "label.CREDIT")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{(overview.creditBalances.lifetime.credit).toFixed(2).toLocaleString("en-US")}</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {getLang(lang, "label.TRANSACTION")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{overview.creditBalances.lifetime.transaction}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
            <Box style={{
           flexGrow: 1
            }}>
              {getLang(lang, "label.CURENT_BALANCE")}
              <Paper elevation={3} className={classes.card}>
                <Box
                  p={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Box>
                    <Typography>
                      {getLang(lang, "label.CREDIT")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{(overview.creditBalances.current.credit).toFixed(2).toLocaleString("en-US")}</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {getLang(lang, "label.TRANSACTION")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{overview.creditBalances.current.transaction}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box style={{
           flexGrow: 1
            }}>
              {getLang(lang, "label.UNALLOCATED_CREDIT")}
              <Paper elevation={3} className={classes.card}>
                <Box
                  p={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Box>
                    <Typography>
                      {getLang(lang, "label.CREDIT")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{(overview.creditBalances.unallocated.credit).toFixed(2).toLocaleString("en-US")}</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {getLang(lang, "label.TRANSACTION")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{overview.creditBalances.unallocated.transaction}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box style={{
           flexGrow: 4
            }}>
             {getLang(lang, "label.ALLOCATED_CREDIT")}
              <Paper elevation={3} className={classes.card}>
                <Box
                  p={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Box>
                    <Typography>
                      {getLang(lang, "label.CREDIT")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{(overview.creditBalances.allocated.credit).toFixed(2).toLocaleString("en-US")}</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {getLang(lang, "label.TRANSACTION")}
                    </Typography>
                  </Box>
                  <Box style={{
                    'textAlign': 'end'
                  }}>
                    <Typography>
                      <b>{overview.creditBalances.allocated.transaction}</b>
                    </Typography>
                  </Box>
                  <Box style={{
                    display: "flex",
                    gap: 10,
                    
                  }}>
                  <Box style={{
           flexGrow: 1
            }}>
             {getLang(lang, "label.UNCLAIMED_BALANCE")}
              <Paper elevation={3} className={classes.card}>
                <Box
                  p={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                    <Typography>
                      {getLang(lang, "label.CREDIT")}
                    </Typography>
                    <Typography>
                      <b>{(overview.creditBalances.unclaimed_allocated.credit).toFixed(2).toLocaleString("en-US")}</b>
                    </Typography>
                  </Box>
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                    <Typography>
                      {getLang(lang, "label.TRANSACTION")}
                    </Typography>
                    <Typography>
                      <b>{overview.creditBalances.unclaimed_allocated.transaction}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
            <Box style={{
           flexGrow: 1
            }}>
             {getLang(lang, "label.CLAIMED_BALANCE")}
              <Paper elevation={3} className={classes.card}>
                <Box
                  p={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                    <Typography>
                      {getLang(lang, "label.CREDIT")}
                    </Typography>
                    <Typography>
                      <b>{(overview.creditBalances.claimed_allocated.credit).toFixed(2).toLocaleString("en-US")}</b>
                    </Typography>
                  </Box>
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                    <Typography>
                      {getLang(lang, "label.TRANSACTION")}
                    </Typography>
                    <Typography>
                      <b>{overview.creditBalances.claimed_allocated.transaction}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>

          <Box mt={2.5}>
            <Typography variant="body2" className={classes.greyText}>
              {getLang(lang, "label.NOTE")}:
            </Typography>
            <Typography variant="body2" className={classes.greyText}>
              {getLang(lang, "paragraph.CREDIT_OVERVIEW_NOTE")}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
