import axios from "axios";

export default function addItemToProductOrder(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/distributor/admin/api/v1/shipping-order/add-items`, {
        shipping_order_uuid: parameters.shipping_order_uuid,
        sku: parameters.sku,
        item_list: parameters.item_list,
        advance_stock_list: parameters.advance_stock_list,
        knock_off_quantity: parameters.knock_off_quantity,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
