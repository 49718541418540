import { GetDealerListing } from "./dealer/getDealerListing.api";
import { SetDealerUpdateStatus } from "./dealer/setDealerUpdateStatus.api";
import dealerDetails from "./dealer/dealerDetails.api";
import { getDealerRewardList } from "./dealer/getRewardList.api";
import { getDealerPointHistoryList } from "./dealer/getPointHistoryList.api";
import { getDealerPointExpiringList } from "./dealer/getPointExpiringList.api";
import { getDealerStockoutList } from "./dealer/getStockoutList.api";
import { getDealerRecallList } from "./dealer/getRecallList.api";
import { getDealerOrderList } from "./dealer/getOrderList.api";
import { getDealerReceiveList } from "./dealer/getReceiveList.api";
import { getDealerRequestList } from "./dealer/getRequestList.api";
import { getAllDealerBoxesListing } from "./dealer/getAllBoxesListing.api";
import { getAllDealerPackagesListing } from "./dealer/getAllPackagesListing.api";
import { getAllDealerProductsListing } from "./dealer/getAllProductsListing.api";
import { getAllDealerLooseItemListing } from "./dealer/getAllLooseItemListing.api";
import { resendVerificationEmail } from "./dealer/resendVerificationEmail.api";
import { getIncentiveProgramList } from "./dealerIncentiveProgram/getIncentiveProgramList.api";
import { createIncentiveProgram } from "./dealerIncentiveProgram/createIncentiveProgram.api";
import { getNewProgramProductDropdown } from "./dealerIncentiveProgram/getNewProgramProductDropdown.api";
import { getEditProgramProductDropdown } from "./dealerIncentiveProgram/getEditProgramProductDropdown.api";
import { GetProgramDetails } from "./dealerIncentiveProgram/getProgramDetails.api";
import { UpdateIncentiveProgramStatus } from "./dealerIncentiveProgram/updateIncentiveProgramStatus.api";
import { UpdateProgramDetails } from "./dealerIncentiveProgram/updateProgramDetails.api";
import { getStockModuleSetting } from "./dealerIncentiveProgram/getStockModuleSetting.api";
import { deleteIncentiveProgram } from "./dealerIncentiveProgram/deleteIncentiveProgram.api";
import { GetDealerListDropdown } from "./dealer/getDealerDropdownData.api";
import { updateDealerUpline } from "./dealer/updateDealerUpline.api";
import { GetRewardListing } from "./rewardCenter/getRewardListing.api";
import { SetRewardUpdateStatus } from "./rewardCenter/setRewardUpdateStatus.api";
import { DeleteReward } from "./rewardCenter/deleteReward.api";
import rewardDetails from "./rewardCenter/rewardDetails.api";
import { getRedemptionList } from "./rewardCenter/getRedemptionList.api";
import { getRedemptionDetails } from "./rewardCenter/getRedemptionDetails.api";
import { respondRedemption } from "./rewardCenter/respondRedemption.api";
import incentivePoint from "./dealer/incentivePoint";
import { BulkUpdateIncentiveProgramStatus } from "./dealerIncentiveProgram/bulkUpdateIncentiveProgramStatus.api";
import updateBulkDealerStatus from "./dealer/updateBulkDealerStatus.api";
import { BulkUpdateRewardStatus } from "./rewardCenter/bulkUpdateRewardStatus.api";
import { getStockoutList } from "./stockout/getStockoutList.api";
import { getStockoutDetail } from "./stockout/getStockoutDetail.api";
import { getStockoutReceiverDropdown } from "./stockout/getStockoutReceiverDropdown.api";
import { getStockoutRequestorDropdown } from "./stockout/getStockoutRequestorDropdown.api";
import { getStockoutItemList } from "./stockout/getStockoutItemList.api";
import { getStockoutCustomFieldDropdown } from "./stockout/getStockoutCustomFieldDropdown.api";
import { getRecallList } from "./recall/getRecallList.api";
import { getRecallDetail } from "./recall/getRecallDetail.api";
import { getRecallDealerDropdown } from "./recall/getRecallDealerDropdown.api";
import { getRecallRequestorDropdown } from "./recall/getRecallRequestorDropdown.api";
import { getRecallItemList } from "./recall/getRecallItemList.api";
import { getOrderList } from "./order/getOrderList.api";
import { getOrderDetail } from "./order/getOrderDetail.api";
import { getOrderRequestorDropdown } from "./order/getOrderRequestorDropdown.api";
import { getOrderReceiverDropdown } from "./order/getOrderReceiverDropdown.api";
import { getProductDetail } from "./inventory/getProductDetail.api";
import { getProductStockListing } from "./inventory/getProductStockListing.api";
import { getSerialNumberActivityLog } from "./inventory/getSerialNumberActivityLog.api";
import { getAllPackagesListing } from "./inventory/getAllPackagesListing.api";
import { getPackageDetail } from "./inventory/getPackageDetail.api";
import { getPackageActivityLog } from "./inventory/getPackageActivityLog.api";
import { getAllBoxesListing } from "./inventory/getAllBoxesListing.api";
import { getBoxDetail } from "./inventory/getBoxDetail.api";
import { getBoxActivityLog } from "./inventory/getBoxActivityLog.api";
import { getAllLooseItemListing } from "./inventory/getAllLooseItemListing.api";
import { getLooseItemDetail } from "./inventory/getLooseItemDetail.api";
import { getLooseItemStockListing } from "./inventory/getLooseItemStockListing.api";
import { getStockProductDropdown } from "./inventory/getStockProductDropdown.api";
import { getStockHolderDropdown } from "./inventory/getStockHolderDropdown.api";
import { getDealerInvitedList } from "./dealer/getDealerInvitedList.api";
import { getDealerInvitedSummary } from "./dealer/getDealerInvitedSummary.api";
import { getDealerInvitedTrending } from "./dealer/getDealerInvitedTrending.api";
import { getTotalStockoutTrending } from "./dealer/getTotalStockoutTrending.api";
import { getTotalRecallTrending } from "./dealer/getTotalRecallTrending.api";
import { getStockoutByProduct } from "./dealer/getStockoutByProduct.api";
import { exportOrder } from "./order/exportOrder.api";
import { getTransferList } from "./transfer/getTransferList.api";
import { getTransferDetail } from "./transfer/getTransferDetail.api";
import { getTransferReceiverDropdown } from "./transfer/getTransferReceiverDropdown.api";
import { getTransferSenderDropdown } from "./transfer/getTransferSenderDropdown.api";
import { getTransferItemBoxList } from "./transfer/getTransferItemBoxList.api";
import { getTransferItemBoxProductList } from "./transfer/getTransferItemBoxProductList.api";
import { getTransferItemProductList } from "./transfer/getTransferItemProductList.api";
import { getTransferItemProductSNList } from "./transfer/getTransferItemProductSNList.api";
import { approveTransfer } from "./transfer/approveTransfer.api";
import { updateTransferDeliveryInfo } from "./transfer/updateTransferDeliveryInfo.api";
import { getTransferDeliveryInfo } from "./transfer/getTransferDeliveryInfo.api";
import { rejectApproveTransfer } from "./transfer/rejectApproveTransfer.api";
import { getReceiveTransferList } from "./receiveTransfer/getReceiveTransferList.api";
import { getReceiveTransferDetail } from "./receiveTransfer/getReceiveTransferDetail.api";
import { getReceiveTransferReceiverDropdown } from "./receiveTransfer/getReceiveTransferReceiverDropdown.api";
import { getReceiveTransferSenderDropdown } from "./receiveTransfer/getReceiveTransferSenderDropdown.api";
import { getReceiveTransferItemBoxList } from "./receiveTransfer/getReceiveTransferItemBoxList.api";
import { getReceiveTransferItemBoxProductList } from "./receiveTransfer/getReceiveTransferItemBoxProductList.api";
import { getReceiveTransferItemProductList } from "./receiveTransfer/getReceiveTransferItemProductList.api";
import { getReceiveTransferItemProductSNList } from "./receiveTransfer/getReceiveTransferItemProductSNList.api";
import { getReceiveTransferDeliveryInfo } from "./receiveTransfer/getReceiveTransferDeliveryInfo.api";
import { getRequestTransferSendList } from "./requestTransfer/getRequestTransferSendList.api";
import { getRequestTransferReceiveList } from "./requestTransfer/getRequestTransferReceiveList.api";
import { getRequestDetail } from "./requestTransfer/getRequestDetail.api";
import { getRequestSenderDropdown } from "./requestTransfer/getRequestSenderDropdown.api";
import { getRequestReceiverDropdown } from "./requestTransfer/getRequestReceiverDropdown.api";
import { approveRequestTransfer } from "./requestTransfer/approveRequestTransfer.api";
import { rejectApproveRequestTransfer } from "./requestTransfer/rejectApproveRequestTransfer.api";
import { getRequestTransferRequestBranches } from "./requestTransfer/getRequestTransferRequestBranches.api";
import { getRequestTransferTargetBranches } from "./requestTransfer/getRequestTransferTargetBranches.api";
import { getRequestStockList } from "./requestTransfer/getRequestStockList.api";
import { getRequestTransferAddressList } from "./requestTransfer/getRequestTransferAddressList.api";
import { addRequestTransferAddress } from "./requestTransfer/addRequestTransferAddress.api";
import { updateRequestTransferAddress } from "./requestTransfer/updateRequestTransferAddress.api";
import { createRequestTransfer } from "./requestTransfer/createRequestTransfer.api";
import { getProductListingByBranch } from "./inventory/getProductListingByBranch.api";
import { getProductListingByDealer } from "./inventory/getProductListingByDealer.api";
import { getBranchInventory } from "./inventory/getBranchInventory.api";
import { getDealerInventory } from "./inventory/getDealerInventory.api";
import { getCheckoutList } from "./checkout/getCheckoutList.api";
import { getCheckoutDetail } from "./checkout/getCheckoutDetail.api";
import { getCheckoutProductDetail } from "./checkout/getCheckoutProductDetail.api";
import { getCheckoutByDropdown } from "./checkout/getCheckoutByDropdown.api";
import { getCheckoutProductList } from "./checkout/getCheckoutProductList.api";
import { getCheckoutBoxList } from "./checkout/getCheckoutBoxList.api";
import { updateCheckoutDetail } from "./checkout/updateCheckoutDetail.api";
import { updateCheckoutDeliveryInfo } from "./checkout/updateCheckoutDeliveryInfo.api";
import { getCheckoutDeliveryInfo } from "./checkout/getCheckoutDeliveryInfo.api";
import { getStockTakeList } from "./stocktake/getStockTakeList.api";
import { getStockTakeDetail } from "./stocktake/getStockTakeDetail.api";
import { createStockTake } from "./stocktake/createStockTake.api";
import { cancelStockTake } from "./stocktake/cancelStockTake.api";
import { updateStockTake } from "./stocktake/updateStockTake.api";
import { endStockTake } from "./stocktake/endStockTake.api";
import { getStockTakeItemBoxList } from "./stocktake/getStockTakeItemBoxList.api";
import { getStockTakeItemBoxProductList } from "./stocktake/getStockTakeItemBoxProductList.api";
import { getStockTakeItemProductList } from "./stocktake/getStockTakeItemProductList.api";
import exportBranchInventory from "./inventory/exportBranchInventory.api";
import exportDealerInventory from "./inventory/exportDealerInventory.api";
import exportProductSummaryByBranch from "./inventory/exportProductSummaryByBranch.api";
import exportProductSummaryByDealer from "./inventory/exportProductSummaryByDealer.api";
import { getStockTakeItemProductSNList } from "./stocktake/getStockTakeItemProductSNList.api";
import { detectCourier } from "./detectCourier.api";
import generatePoPdf from "./requestTransfer/generatePoPdf.api";
import generateDnPdf from "./transfer/generateDnPdf.api";
import getDailyStockoutSummary from "./stockout/getDailyStockoutSummary.api";
import getDailyProductStockoutSummary from "./stockout/getDailyProductStockoutSummary.api";

const stockflowApi = {
  GetDealerListing,
  SetDealerUpdateStatus,
  dealerDetails,
  updateDealerUpline,
  GetDealerListDropdown,
  GetRewardListing,
  getIncentiveProgramList,
  createIncentiveProgram,
  getNewProgramProductDropdown,
  getEditProgramProductDropdown,
  GetProgramDetails,
  UpdateIncentiveProgramStatus,
  UpdateProgramDetails,
  getStockModuleSetting,
  deleteIncentiveProgram,
  SetRewardUpdateStatus,
  DeleteReward,
  rewardDetails,
  getRedemptionList,
  getRedemptionDetails,
  respondRedemption,
  incentivePoint,
  BulkUpdateIncentiveProgramStatus,
  updateBulkDealerStatus,
  BulkUpdateRewardStatus,
  getStockoutList,
  getStockoutDetail,
  getStockoutReceiverDropdown,
  getStockoutRequestorDropdown,
  getStockoutItemList,
  getStockoutCustomFieldDropdown,
  getRecallList,
  getRecallDetail,
  getRecallDealerDropdown,
  getRecallRequestorDropdown,
  getRecallItemList,
  getOrderList,
  getOrderDetail,
  getOrderRequestorDropdown,
  getOrderReceiverDropdown,
  getProductDetail,
  getProductStockListing,
  getSerialNumberActivityLog,
  getAllPackagesListing,
  getPackageDetail,
  getPackageActivityLog,
  getAllBoxesListing,
  getBoxDetail,
  getBoxActivityLog,
  getAllLooseItemListing,
  getLooseItemDetail,
  getLooseItemStockListing,
  getStockProductDropdown,
  getStockHolderDropdown,
  resendVerificationEmail,
  getDealerStockoutList,
  getDealerRecallList,
  getDealerReceiveList,
  getDealerOrderList,
  getDealerRequestList,
  getAllDealerBoxesListing,
  getAllDealerPackagesListing,
  getAllDealerProductsListing,
  getAllDealerLooseItemListing,
  getDealerRewardList,
  getDealerPointHistoryList,
  getDealerPointExpiringList,
  getDealerInvitedTrending,
  getDealerInvitedList,
  getDealerInvitedSummary,
  getTotalStockoutTrending,
  getTotalRecallTrending,
  getStockoutByProduct,
  exportOrder,
  getTransferList,
  getTransferDetail,
  getTransferReceiverDropdown,
  getTransferSenderDropdown,
  getTransferItemBoxList,
  getTransferItemBoxProductList,
  getTransferItemProductList,
  getTransferItemProductSNList,
  approveTransfer,
  rejectApproveTransfer,
  getReceiveTransferList,
  getReceiveTransferDetail,
  getReceiveTransferReceiverDropdown,
  getReceiveTransferSenderDropdown,
  getReceiveTransferItemBoxList,
  getReceiveTransferItemBoxProductList,
  getReceiveTransferItemProductList,
  getReceiveTransferItemProductSNList,
  getRequestTransferSendList,
  getRequestTransferReceiveList,
  getRequestDetail,
  getRequestSenderDropdown,
  getRequestReceiverDropdown,
  approveRequestTransfer,
  rejectApproveRequestTransfer,
  getRequestTransferRequestBranches,
  getRequestTransferTargetBranches,
  getRequestStockList,
  getRequestTransferAddressList,
  addRequestTransferAddress,
  updateRequestTransferAddress,
  createRequestTransfer,
  getProductListingByBranch,
  getProductListingByDealer,
  getBranchInventory,
  getDealerInventory,
  getCheckoutList,
  getCheckoutDetail,
  getCheckoutProductDetail,
  getCheckoutByDropdown,
  getCheckoutProductList,
  getCheckoutBoxList,
  updateCheckoutDetail,
  getStockTakeList,
  getStockTakeDetail,
  createStockTake,
  updateStockTake,
  cancelStockTake,
  getStockTakeItemBoxList,
  getStockTakeItemBoxProductList,
  getStockTakeItemProductList,
  exportBranchInventory,
  exportDealerInventory,
  exportProductSummaryByBranch,
  exportProductSummaryByDealer,
  getStockTakeItemProductSNList,
  endStockTake,
  detectCourier,
  updateCheckoutDeliveryInfo,
  getCheckoutDeliveryInfo,
  updateTransferDeliveryInfo,
  getTransferDeliveryInfo,
  getReceiveTransferDeliveryInfo,
  generatePoPdf,
  generateDnPdf,
  getDailyStockoutSummary,
  getDailyProductStockoutSummary
};

export default stockflowApi;
