import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CLAIM_LIST_EDIT } from "lib/constants/accessRights";
import {
  fetchConsumerClaimDetail,
  processWarranteeClaims,
  updateClaimDetail,
} from "modules/warranty/redux";

import ConsumerClaimDetailPanelComponent from "./consumerClaimDetailPanel.component";
import DisplayImageDialog from "modules/warrantee/components/dialog/displayImageDialog";
import ApproveClaimExtendDialog from "modules/warrantee/components/dialog/approveClaimExtendDialog";
import { showBackdrop, hideBackdrop } from "modules/backdrop";
import ApproveClaimDialog from "modules/warrantee/components/dialog/approveClaimDialog";
import RejectClaimDialog from "modules/warrantee/components/dialog/rejectClaimDialog";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert, selectLoading } from "modules/notification";
import ReplacementHistoryDialog from "../../dialog/replacementHistoryDialog";

function ConsumerClaimDetailPanelContainer({ claimItem, handleProcessed }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const profileLang = useSelector((state) => state.profile.language);
  const hasClaimListEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(CLAIM_LIST_EDIT)
  );

  const isUpdating = useSelector((state) =>
    selectLoading(state, updateClaimDetail.typePrefix)
  );

  const [displayImage, setDisplayImage] = useState(null);

  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isExtendDialogOpen, setIsExtendDialogOpen] = useState(false);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);

  const [formValue, setformValue] = useState({
    extendPeriod: claimItem.warrantyDuration,
  });
  const [isEdit, setIsEdit] = useState(false);

  const handleRejectClick = async (value) => {
    await dispatch(showBackdrop());
    await dispatch(
      processWarranteeClaims({
        index: null,
        id: claimItem.uuid,
        status: "rejected",
        reason:
          value.selected === "others" ? value.otherReason : value.selected,
      })
    );

    handleProcessed();

    await dispatch(hideBackdrop());
    setIsRejectDialogOpen(false);
  };

  const handleView = async (value) => {
    window.open(value, "_blank");
  };

  const handleApproveClick = async () => {
    await dispatch(showBackdrop());
    await dispatch(
      processWarranteeClaims({
        index: null,
        id: claimItem.uuid,
        status: "approved",
        extendPeriod: formValue.extendPeriod,
      })
    );

    handleProcessed();

    await dispatch(hideBackdrop());
    setIsApproveDialogOpen(false);
  };

  const handleUpdate = async (values) => {
    let param = {
      uuid: claimItem.uuid,
      duration_month: values.warrantyDuration,
      end_at: values.warrantyEnd,
      remark: values.remark,
      metadata: values.metadata,
    };

    dispatch(updateClaimDetail(param))
      .then(unwrapResult)
      .then(() => {
        dispatch(
          fetchConsumerClaimDetail({
            claimId: claimItem.uuid,
            reimbursementId: "",
          })
        );
      });
  };

  const showAlert = (message) => {
    dispatch(
      addAlert({
        severity: "error",
        message: message,
      })
    );
  };

  const showReplacementHistory = () => {
    setIsHistoryDialogOpen(true);
  };

  return (
    <>
      <ConsumerClaimDetailPanelComponent
        lang={lang}
        profileLang={profileLang}
        claim={claimItem}
        handleView={handleView}
        handleMediaClick={(src, type) => setDisplayImage({ src, type })}
        onApproveClick={() => setIsExtendDialogOpen(true)}
        onRejectClick={() => setIsRejectDialogOpen(true)}
        hasClaimListEditAccessRight={hasClaimListEditAccessRight}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        handleUpdate={handleUpdate}
        isUpdating={isUpdating}
        showAlert={showAlert}
        showReplacementHistory={showReplacementHistory}
      />

      <ApproveClaimExtendDialog
        isOpen={isExtendDialogOpen}
        handleConfirm={(value) => {
          setformValue({
            ...formValue,
            extendPeriod: value.warrantyPeriod,
          });
          setIsApproveDialogOpen(true);
        }}
        handleClose={() => setIsExtendDialogOpen(false)}
        initialValue={formValue}
      />

      <ApproveClaimDialog
        isOpen={isApproveDialogOpen}
        handleConfirm={handleApproveClick}
        handleClose={() => setIsApproveDialogOpen(false)}
      />

      <RejectClaimDialog
        isOpen={isRejectDialogOpen}
        handleSubmit={handleRejectClick}
        handleClose={() => setIsRejectDialogOpen(false)}
      />

      <DisplayImageDialog
        isOpen={!!displayImage}
        handleClose={() => {
          setDisplayImage(null);
        }}
        src={!!displayImage ? displayImage.src : null}
        type={!!displayImage ? displayImage.type : null}
      />
      <ReplacementHistoryDialog
        claim={claimItem}
        isOpen={isHistoryDialogOpen}
        handleClose={() => setIsHistoryDialogOpen(false)}
      />
    </>
  );
}

export default ConsumerClaimDetailPanelContainer;
