import { createSlice } from "@reduxjs/toolkit";
import {
  BRANCHGROUP,
  fetchGroupList,
  getAllAvailableBranchesForRules,
  getAvailableBranches,
  getBranchesInGroup,
  getGroupRules,
  getGroupSummary,
} from "./group.action";

const initialState = {
  groupList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  group: null,
  isLoadingGroupError: false,
  availableBranches: [],
  selectedBranches: [],
  isLoadingAvailableBranchError: false,
  rules: [],
  customRules: [],
  isLoadingRulesError: false,
  allBranches: [],
  branchesInGroup: [],
};

const groupSlice = createSlice({
  name: BRANCHGROUP,
  initialState,
  reducers: {
    resetBranchGroupListing(state) {
      state.groupList = initialState.groupList;
    },
    resetGroup(state) {
      state.group = initialState.group;
      state.branchesInGroup = initialState.branchesInGroup;
    },
    resetAvailableBranches(state) {
      state.availableBranches = initialState.availableBranches;
      state.selectedBranches = initialState.selectedBranches;
    },
    resetRules(state) {
      state.rules = initialState.rules;
      state.customRules = initialState.customRules;
    },
    resetAllBranches(state) {
      state.allBranches = initialState.allBranches;
    },
    updateGroupDetail(state, action) {
      const { payload } = action;
      state.group["name"] = payload.name;
      state.group["role"] = payload.role;
    },
  },
  extraReducers: {
    [fetchGroupList.pending]: (state) => {
      state.groupList = initialState.groupList;
    },
    [fetchGroupList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.groupList.list = payload.data;
      state.groupList.totalRecords = payload.recordsTotal;
      state.groupList.totalFiltered = payload.recordsFiltered;
    },
    [fetchGroupList.rejected]: (state) => {
      state.groupList.isError = true;
    },
    [getGroupSummary.pending]: (state) => {
      state.group = initialState.group;
      state.isLoadingGroupError = false;
    },
    [getGroupSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.group = payload;
    },
    [getGroupSummary.rejected]: (state) => {
      state.isLoadingGroupError = true;
    },
    [getAvailableBranches.pending]: (state) => {
      state.availableBranches = initialState.availableBranches;
      state.selectedBranches = initialState.selectedBranches;
      state.isLoadingAvailableBranchError = false;
    },
    [getAvailableBranches.fulfilled]: (state, action) => {
      const { payload } = action;
      state.availableBranches = payload.available;
      state.selectedBranches = payload.selected;
    },
    [getAvailableBranches.rejected]: (state) => {
      state.isLoadingAvailableBranchError = true;
    },
    [getGroupRules.pending]: (state) => {
      state.rules = initialState.rules;
      state.customRules = initialState.customRules;
      state.isLoadingRulesError = false;
    },
    [getGroupRules.fulfilled]: (state, action) => {
      const { payload } = action;
      state.rules = payload.rules;
      state.customRules = payload.custom_rules;
    },
    [getGroupRules.rejected]: (state) => {
      state.isLoadingRulesError = true;
    },
    [getAllAvailableBranchesForRules.pending]: (state) => {
      state.allBranches = initialState.allBranches;
    },
    [getAllAvailableBranchesForRules.fulfilled]: (state, action) => {
      const { payload } = action;
      state.allBranches = payload;
    },
    [getBranchesInGroup.pending]: (state) => {
      state.branchesInGroup = initialState.branchesInGroup;
    },
    [getBranchesInGroup.fulfilled]: (state, action) => {
      const { payload } = action;
      state.branchesInGroup = payload;
    },
  },
});

export const {
  resetBranchGroupListing,
  resetGroup,
  resetAvailableBranches,
  resetRules,
  resetAllBranches,
  updateGroupDetail,
} = groupSlice.actions;

export const branchGroupReducer = groupSlice.reducer;
