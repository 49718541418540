import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Divider,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { ActionButton, BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
// import GroupTransferRulePanel from "modules/group/components/panel/groupTransferRulePanel";
import GroupBranchTable from "modules/group/components/table/groupBranchTable";
import { Formik } from "formik";
import RoleSelect from "modules/group/components/select/roleSelect";
import GroupTransferRulePanel from "modules/group/components/panel/groupTransferRulePanel";

const useStyle = makeStyles((theme) => ({
  panelContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em",
    gridTemplateAreas: "'left-panel right-panel'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'left-panel left-panel''right-panel right-panel'",
    },
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: "2fr 8fr",
    rowGap: theme.spacing(2),
  },
  removeButton: {
    color: "#F04438",
    cursor: "pointer",
  },
}));

export default function GroupDetailsComponent({
  group,
  isFetching,
  isError,
  handleReload,
  lang,
  hasGroupEditAccessRight,
  editBranch,
  handleDeleteGroup,
  editRules,
  isEditing,
  setIsEditing,
  updateGroupInfo,
  isUpdatingGoupDetail,
}) {
  const classes = useStyle();

  const actionItems = [
    {
      label: getLang(lang, "label.EDIT"),
      action: () => {
        setIsEditing(true);
      },
      disabled: !hasGroupEditAccessRight || isEditing,
    },
    {
      label: getLang(lang, "label.DELETE"),
      divider: true,
      errorText: true,
      action: () => handleDeleteGroup(),
      disabled: !hasGroupEditAccessRight,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box gridArea="top" mb={2}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Typography variant="h6" color="primary" style={{ marginLeft: 5 }}>
              {getLang(lang, "label.GROUP")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Typography variant="h6" color="primary">
              {getLang(lang, "label.GROUP_DETAILS")}
            </Typography>
          </Box>
        </Box>
      </Box>
      {isFetching ? (
        <Box gridArea="main" mt={6} style={{ textAlign: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      ) : isError ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <RefreshTable handleReload={handleReload} />
        </Box>
      ) : !!group ? (
        <Box>
          <Paper style={{ marginBottom: 16 }}>
            <Box pt={2} pb={3} px={3}>
              <Box mb={2} display="flex" justifyContent="space-between">
                <Typography variant="h6">
                  <b>{getLang(lang, "label.GROUP_INFO")}</b>
                </Typography>
                {!group.is_default && (
                  <Box>
                    <ActionButton
                      popperStyle={{ zIndex: 999 }}
                      actionItems={actionItems}
                    />
                  </Box>
                )}
              </Box>
              <Formik
                initialValues={{ name: group?.name || "", role: group?.role }}
                enableReinitialize
                onSubmit={updateGroupInfo}
              >
                {(formik) => (
                  <>
                    <Box className={classes.fieldRow} alignItems="center">
                      <Typography variant="body1">
                        <b>{getLang(lang, "label.GROUP_NAME")}</b>{" "}
                        {!!isEditing && <span style={{ color: "red" }}>*</span>}
                      </Typography>
                      {isEditing ? (
                        <Box>
                          <Box display="flex" alignItems="center">
                            <TextField
                              className={classes.editNameField}
                              required
                              hiddenLabel
                              variant="outlined"
                              margin="small"
                              size="small"
                              placeholder={getLang(
                                lang,
                                "placeholder.ENTER_GROUP_NAME"
                              )}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontSize: "16px",
                                },
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{ paddingRight: "6px" }}
                                  >
                                    <Box ml={1}>
                                      <Typography
                                        variant="caption"
                                        style={{ color: "#b9b9b9" }}
                                      >
                                        {formik.values.name.length}/50
                                      </Typography>
                                    </Box>
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{ maxLength: 50 }}
                              {...formik.getFieldProps("name")}
                              disabled={isUpdatingGoupDetail}
                            />
                            {/* <Box ml={1}>{formik.values.name.length}/50</Box> */}
                          </Box>
                        </Box>
                      ) : (
                        <Typography variant="body1">{group.name}</Typography>
                      )}
                    </Box>
                    <Box
                      className={classes.fieldRow}
                      alignItems="center"
                      mt={2}
                    >
                      <Typography variant="body1">
                        <b>{getLang(lang, "label.ROLE")}</b>
                      </Typography>
                      {isEditing ? (
                        <Box>
                          <Box display="flex" alignItems="center">
                            <RoleSelect
                              handleChange={(val) =>
                                formik.setFieldValue("role", val)
                              }
                              disabled={isUpdatingGoupDetail}
                              value={formik.values.role}
                              styles={{
                                height: 38,
                                backgroundColor: "white",
                                border: "1px solid #c4c4c4",
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Typography variant="body1">
                          {group.role ? group.role.name : "-"}
                        </Typography>
                      )}
                    </Box>
                    {!!isEditing && (
                      <Box ml={2} mt={2.5} display="flex" justifyContent="end">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            formik.handleReset();
                            setIsEditing(false);
                          }}
                          disabled={isUpdatingGoupDetail}
                          style={{ height: 30 }}
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          onClick={() => formik.handleSubmit()}
                          disabled={
                            isUpdatingGoupDetail || !formik.values.name.trim()
                          }
                          style={{ height: 30, marginLeft: 10 }}
                        >
                          {getLang(lang, "label.UPDATE")}
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Formik>
            </Box>
          </Paper>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                my={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flex={1}
              >
                <Typography variant="h6">
                  <b>{getLang(lang, "label.GROUP_BRANCH")}</b>
                </Typography>

                {hasGroupEditAccessRight && !group.is_default && (
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    type={"button"}
                    style={{ height: 30 }}
                    onClick={() => {
                      editBranch();
                    }}
                  >
                    {getLang(lang, "label.EDIT")}
                  </Button>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box mt={0.25} px={3} pb={3}>
                <Divider />
                <Box mt={2}>
                  <GroupBranchTable
                    rows={group.branches}
                    lang={lang}
                    isError={isError}
                    isFetching={isFetching}
                    handleReload={handleReload}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box mt={2}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box
                  my={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                >
                  <Typography variant="h6">
                    <b>
                      {getLang(lang, "label.STOCK_TRANSFER_RULES_SETTINGS")}
                    </b>
                  </Typography>
                  {hasGroupEditAccessRight && (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      type={"button"}
                      style={{ height: 30 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        editRules();
                      }}
                    >
                      {getLang(lang, "label.EDIT")}
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box mt={0.25} px={3} pb={3}>
                  <Divider />
                  <Box mt={2}>
                    <GroupTransferRulePanel lang={lang} group={group} />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
