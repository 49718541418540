/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import ReplacementHistoryDialogComponent from "./replacementHistoryDialog.component";

function ReplacementHistoryDialogContainer({ handleClose, isOpen, claim }) {
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  return (
    <>
      <ReplacementHistoryDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        claim={claim}
        lang={lang}
        language={language}
      />
    </>
  );
}

export default ReplacementHistoryDialogContainer;
