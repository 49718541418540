import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "35px 30px",
  },
  customDialogActionRoot: {
    justifyContent: "center",
  },
  customDialogTitle: {
    paddingTop: 0,
    color: theme.palette.primary.main,
  },
  customDialogButton: {},
  customDialogPaperWithXs: {
    maxWidth: 360,
  },
  dialogRemarksCaption: {
    fontWeight: 200,
    marginLeft: 5,
  },
  dialogRemarks: {
    color: "#FD646F",
  },
  dangerDialog: {
    backgroundColor: "#EB6D76",
    color: "white !important",
  },
  successDialog: {
    backgroundColor: "#6AAF68",
    color: "white !important",
  },
  defaultDialog: {
    backgroundColor: "#ffffff",
    color: "black !important",
  },
  dangerCancelButton: {
    color: "white",
    borderColor: "white !important",
  },
  successCancelButton: {
    backgroundColor: theme.palette.primary.main,
  },
  dangerText: {
    color: "white",
  },
  successText: {
    color: "white",
  },
  defaultText: {
    color: "black",
  },
}));

export default function ConfirmationDialogComponent({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  remarks,
  type,
  proceedLabel,
  rejectLabel,
  rejectOption = true,
  textAlignLeft = false,
  descriptionLineBreak = false,
  maxWidth="xs",
}) {
  const classes = useStyle();
  const lang = useSelector((state) => state.constant.languages);
  const formatDescription = (desc) => {
    return desc?.props?.children?.map((item, index) =>
      typeof item === "string"
        ? item.split(".").map((subItem, subIndex) => (
          <React.Fragment key={subIndex}>
            {subItem}
            {subIndex < item.split(".").length - 1 && (
              <>
                .
                <br />
                <br />
              </>
            )}
          </React.Fragment>
        ))
        : item
    );
  };

  return (
    <Dialog
      classes={{
        paper: clsx(classes.root, {
          [classes.dangerDialog]: type === "danger",
          [classes.successDialog]: type === "success",
          [classes.defaultDialog]: type === "default",
        }),
        paperWidthXs: maxWidth !== "xs" ? '' : classes.customDialogPaperWithXs,
      }}
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog"
      maxWidth={maxWidth !== "xs" ? maxWidth : "xs"}
    >
      <DialogTitle
        classes={{
          root: clsx(classes.customDialogTitle, {
            [classes.dangerText]: type === "danger",
            [classes.successText]: type === "success",
            [classes.defaultText]: type === "default",
          }),
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          classes={{
            root: clsx({
              [classes.dangerText]: type === "danger",
              [classes.successText]: type === "success",
              [classes.defaultText]: type === "default",
            }),
          }}
          style={textAlignLeft ? { textAlign: "left" } : {}}
        >
          {descriptionLineBreak ? formatDescription(description) : description}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.customDialogActionRoot }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {rejectOption && (
              <Button
                variant={type === "danger" ? "outlined" : "contained"}
                onClick={handleClose}
                color="primary"
                classes={{
                  root: clsx(classes.customDialogButton, {
                    [classes.dangerCancelButton]: type === "danger",
                  }),
                }}
              >
                {rejectLabel ? rejectLabel : getLang(lang, "label.CANCEL")}
              </Button>
            )}
            <Button onClick={handleProceed} color="primary" variant="contained">
              {proceedLabel ? proceedLabel : getLang(lang, "label.CONFIRM")}
            </Button>
          </>
        )}
      </DialogActions>
      {remarks && (
        <Box
          marginTop="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          classes={{
            root: clsx(classes.dialogRemarks, {
              [classes.dangerText]: type === "danger",
              [classes.successText]: type === "success",
              [classes.defaultText]: type === "default",
            }),
          }}
        >
          <ErrorIcon />
          <Typography
            classes={{
              root: classes.dialogRemarksCaption,
            }}
            variant="body2"
            display="block"
          >
            {remarks}
          </Typography>
        </Box>
      )}
    </Dialog>
  );
}
