import axios from "axios";

export default function updateClaimDetail(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/consumer/admin/api/v1/prife/claim/${parameters.uuid}/update`, {
        duration_month: parameters.duration_month,
        end_at: parameters.end_at,
        remark: parameters.remark,
        metadata: parameters.metadata,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
