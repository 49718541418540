import React from "react";

import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  Link,
  Backdrop,
} from "@material-ui/core";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { getLang } from "app/feature/constants";
import DefaultImg from "assets/img/img-default.png";
import moment from "moment";
import "moment/min/locales.min";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { ROOT } from "modules/serial-number/config/serial-number.route";

const style = (theme) => ({
  container: {
    backgroundColor: "#EBEDEE",
    overflowY: "auto",
    padding: 16,
  },
  list: {
    paddingInlineStart: "0px",
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
  },
  dot: {
    color: "#8e96a3",
    lineHeight: "13px",
    fontSize: 20,
  },
  seperator: {
    display: "flex",
    flexDirection: "column",
    flex: 0,
    minWidth: 40,
    alignItems: "center",
    position: "absolute",
    height: "100%",
  },
  timelineIndicator: {
    boxShadow: "none",
    color: "#ffffff",
    borderRadius: "50%",
    aspectRatio: "1 / 1",
    height: 24,
    width: 24,
    textAlign: "center",
    margin: "5px 0px",
    alignSelf: "center",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: 25,
    borderLeft: "2px dotted #8e96a3",
  },
  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  prodImage: {
    // borderRadius: "50%",
    border: "1px solid",
    borderColor: theme.palette.divider,
    height: 54,
    width: 54,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0,
    },
  },
});

const useStyles = makeStyles(style);

export default function ReplacementHistoryDialogComponent({
  isOpen,
  handleClose,
  lang,
  language,
  claim,
}) {
  const classes = useStyles();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={1}
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.REPLACEMENT_HISTORY")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Divider />
      <Box className={classes.container}>
        <ul className={classes.list}>
          {claim.replacementHistory.map((sn, i) => (
            <li key={i} className={classes.item}>
              <Box className={classes.seperator} mt={2.5}>
                <Box
                  className={classes.timelineIndicator}
                  style={{
                    backgroundColor:
                      i !== claim.replacementHistory.length - 1
                        ? "#8e96a3"
                        : "#32D583",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {i !== claim.replacementHistory.length - 1 ? (
                    <CloseIcon style={{ color: "white", fontSize: 14 }} />
                  ) : (
                    <CheckIcon style={{ color: "white", fontSize: 14 }} />
                  )}
                </Box>
                {i !== claim.replacementHistory.length - 1 && (
                  <>
                    <Box className={classes.connector} />
                    <Box mt={-1.5} mb={-2}>
                      <ArrowDropDownIcon
                        style={{
                          color: "#8e96a3",
                          fontSize: 36,
                        }}
                      />
                    </Box>
                    <Box className={classes.connector} />
                  </>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                mb={i !== claim.replacementHistory.length - 1 ? 3.5 : 0}
                ml={6}
                p={1}
                style={{
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <Box className={classes.prodImage}>
                  <img src={sn.product_image || DefaultImg} alt="product" />
                </Box>
                <Box ml={2} overflow="hidden">
                  <Typography
                    variant="body2"
                    style={{ wordWrap: "break-word" }}
                  >
                    <b>{sn.product_name}</b>
                    <span style={{ color: "grey" }}>
                      {!!sn.product_name_old && ` (${sn.product_name_old})`}
                    </span>
                  </Typography>
                  {!!sn.attributes && !!sn.attributes.length && (
                    <Typography
                      className={classes.attributeText}
                      variant="body2"
                    >
                      {sn.attributes.join(", ")}
                    </Typography>
                  )}
                  <Box mt={0.5}>
                    <Link
                      href={`${ROOT}/details/${sn.enc}`}
                      color="secondary"
                      onClick={(e) => {
                        history.push(`${ROOT}/details/${sn.enc}`);
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <Typography variant="body2" fontWeight="fontWeightBold">
                        {sn.serial_number}
                      </Typography>
                    </Link>
                  </Box>
                  {i !== claim.replacementHistory.length - 1 && (
                    <Box mt={0.5}>
                      <Typography
                        variant="body2"
                        style={{ wordWrap: "break-word" }}
                      >
                        {getLang(lang, "label.REPLACED_ON")}: {}
                        {moment(sn.completed_at).format("lll")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    </Dialog>
  );
}
