import axios from "axios";

const endpoint_url = "/authentication/api/basic/check-accept-new-agreement";

const checkAcceptNewAgreement = (userUuid) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, {user_uuid: userUuid})
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default checkAcceptNewAgreement;
