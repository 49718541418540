import React from "react";
import { makeStyles, Box, Typography, Button, Paper } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(6),
    display: "flex",
    justifyContent: "center",
  },
  box: {
    width: "70%",
  },
});

const useStyles = makeStyles(style);

export default function RefreshTableComponent({ handleReload }) {
  const classes = useStyles();
  const lang = useSelector(state => state.constant.languages);

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.box}>
        <Typography
          variant="body2"
          color="primary"
          style={{ fontWeight: "bold", paddingBottom: "16px" }}
        >
          {getLang(lang, "label.NO_DATA_RECEIVED")}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: "#9CA6A9", paddingBottom: "16px" }}
        >
          {getLang(lang, "paragraph.SOMETHING_WENT_WRONG")}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleReload}
          disableElevation
        >
          {getLang(lang, "label.REFRESH_PAGE")}
        </Button>
      </Box>
    </Paper>
  );
}
