import React from "react";
import AdditionalInfoAccordionComponent from "./additionalInfoAccordion.component";
import { useSelector } from "react-redux";

function AdditionalInfoAccordionContainer({
  info,
  adtInfoIdx,
  invalidRangeError,
  setInvalidRangeError,
  isUpdating,
  formik,
  currentLang,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const onBlur = (type, name, rangeIdx, infoIdx, value, range, inputRanges) => {
    let error = false;

    if (range.from || range.to || range.value) {
      // check if the input value exist in other ranges

      if (type === 1) {
        let from = range.from ? getSnFormat(range.from) : [];
        let to = range.to ? getSnFormat(range.to) : [];

        if (range.from && range.to) {
          if (from[0] !== to[0] || from[2] !== to[2] || from[1] > to[1]) {
            error = true;
          } else {
            from = Number(from[1]);
            to = Number(to[1]);
            // check if the sn is valid format with digit
            if (isNaN(from) || isNaN(to) || from > to) {
              error = true;
            }
          }
        }
      } else {
        let val = Number(value);

        let temp = [...inputRanges];
        temp.splice(rangeIdx, 1);
        if (val >= 0) {
          let idx = temp.findIndex((r) =>
            r.from && r.to
              ? Number(r.from) <= val && val <= Number(r.to)
              : r.from
              ? Number(r.from) === val
              : r.to
              ? Number(r.to) === val
              : false
          );
          if (idx >= 0) {
            error = true;
          }
        }

        if (range.from && range.to) {
          // check if current range overlapped with other ranges
          let overlapIdx = temp.findIndex((r) =>
            range.from && range.to
              ? r.from && r.to
                ? (Number(r.from) <= Number(range.from) &&
                    Number(range.to) <= Number(r.to)) ||
                  (Number(r.from) >= Number(range.from) &&
                    Number(range.to) >= Number(r.to))
                : r.from
                ? Number(range.from) <= Number(r.from) &&
                  Number(r.from) <= Number(range.to)
                : r.to
                ? Number(range.from) <= Number(r.to) &&
                  Number(r.to) <= Number(range.to)
                : false
              : null
          );
          if (overlapIdx >= 0) {
            error = true;
          }
        }

        // check if to is larger or equal than from
        let validRange = true;
        if (name.endsWith("from")) {
          if (range.to) {
            validRange = val <= Number(range.to);
          }
        } else {
          if (range.from) {
            validRange = val >= Number(range.from);
          }
        }
        if (!validRange) error = true;
      }

      let rangeError = { ...invalidRangeError };
      if (error) {
        if (
          Object.keys(rangeError).length &&
          rangeError.hasOwnProperty(infoIdx)
        ) {
          let errorList = [...rangeError[infoIdx]];
          if (!errorList.includes(rangeIdx)) {
            errorList.push(rangeIdx);
          }
          rangeError = {
            ...rangeError,
            [infoIdx]: errorList,
          };
        } else {
          rangeError = {
            ...rangeError,
            [infoIdx]: [rangeIdx],
          };
        }
      } else {
        if (
          Object.keys(rangeError).length &&
          rangeError.hasOwnProperty(infoIdx)
        ) {
          let errorList = [...rangeError[infoIdx]].filter(
            (r) => r !== rangeIdx
          );
          if (errorList.length) {
            rangeError = {
              ...rangeError,
              [infoIdx]: errorList,
            };
          } else {
            delete rangeError[infoIdx];
          }
        }
      }
      setInvalidRangeError(rangeError);
    } else {
      let rangeError = { ...invalidRangeError };
      delete rangeError[infoIdx];
      setInvalidRangeError(rangeError);
    }
  };

  const getSnFormat = (input) => {
    const regex = /^([A-Z]*)(0*\d+)([A-Z]*)$/;
    const matches = input.match(regex);

    if (!matches) {
      return [null, input, null];
    }

    // Extract the parts ensuring we return null for empty parts
    const prefix = matches[1] || null;
    const number = matches[2] || null;
    const suffix = matches[3] || null;

    return [prefix, number, suffix];
  };

  const updateRow = (action, rangeIdx, infoIdx, formik) => {
    if (action === "remove") {
      let temp = [...formik.values.additionalInfo];
      let ranges = [...temp[infoIdx].ranges];

      if (ranges.length > 1) ranges.splice(rangeIdx, 1);
      else
        ranges = [
          {
            from: "",
            to: "",
            value: temp[infoIdx].format === 1 ? { EN: "" } : "",
          },
        ];

      temp[infoIdx] = {
        ...temp[infoIdx],
        ranges,
      };
      formik.setFieldValue("additionalInfo", temp);

      let invalidRange = { ...invalidRangeError };
      let newR = [];
      invalidRange[infoIdx].forEach((i) => {
        if (i !== rangeIdx) {
          if (i > rangeIdx) {
            newR.push(i - 1);
          } else {
            newR.push(i);
          }
        }
      });
      invalidRange[infoIdx] = newR;
      if (!newR.length) {
        delete invalidRange[infoIdx];
      }
      setInvalidRangeError(invalidRange);
    }

    if (action === "add") {
      let temp = [...formik.values.additionalInfo];
      let ranges = [...temp[infoIdx].ranges];

      let valueObj = {};
      formik.values.usedLang.forEach((l) => {
        valueObj[l.code] = "";
      });

      ranges.push({
        from: "",
        to: "",
        value: temp[infoIdx].format === 1 ? valueObj : "",
      });
      temp[infoIdx] = {
        ...temp[infoIdx],
        ranges,
      };
      formik.setFieldValue("additionalInfo", temp);
    }
  };

  return (
    <AdditionalInfoAccordionComponent
      lang={lang}
      onBlur={onBlur}
      invalidRangeError={invalidRangeError}
      updateRow={updateRow}
      isUpdating={isUpdating}
      info={info}
      adtInfoIdx={adtInfoIdx}
      formik={formik}
      currentLang={currentLang}
      setInvalidRangeError={setInvalidRangeError}
    />
  );
}

export default AdditionalInfoAccordionContainer;
