import axios from "axios";

export default function updateSpecialRequest(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/master-list/update", {
        master_list_uuid: parameters.master_list_uuid,
        remark: parameters.remark,
        reference_no: parameters.reference_no,
        sku_list: parameters.sku_list,
        uploaded_files: parameters.uploaded_files,
        currency: parameters.currency,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
