import axios from "axios";

export default function getReceiveTransferListing(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/distributor/admin/api/v1/prife/receive-transfer/list`, {
        start: parameters.start,
        length: parameters.length,
        order: parameters.order,
        order_by: parameters.order_by,
        filter: {
          search: parameters.search,
          status: parameters.status,
          from_date: parameters.from_date,
          to_date: parameters.to_date,
          transfer_from: parameters.transfer_from,
          transfer_to: parameters.transfer_to,
          respond_date: parameters.respond_date,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
