import React, { useEffect } from "react";
import SplashScreen from "../components/screen/splash";
import { compose } from "@reduxjs/toolkit";
import { connect, useDispatch } from "react-redux";
import {
  setCurrentModule,
  initializeApp,
  isInitializing,
  isUserVerified,
  isSessionChecked,
} from "../redux";
import {
  isCompanyApproved,
  isCompanySuspended,
  isAccountSuspended,
  isCompanyDisabled,
} from "modules/private/redux/selector";
import { useHistory, useLocation } from "react-router-dom";
import StripeWrapper from "../components/stripeWrapper";
import {
  SIGN_UP_VERIFICATION,
  LOGIN,
} from "modules/public/config/public.route";
import {SUSPENDED, SIGN_UP_STEPS, DEACTIVATED} from "modules/private/config/route";
import { useSelector } from "react-redux";
import { getApiLang, getLang } from "app/feature/constants";
import authApi from "app/api/authentication";
import { addAlert } from "modules/notification";

const MainPage = React.lazy(() => import("./main.page"));

function MainContainer({
  setCurrentModule,
  isAuthenticated,
  initializeApp,
  isInitializing,
  isUserVerified,
  isCompanyApproved,
  isSessionChecked,
  error,
  isAppInitialized,
  isCompanySuspended,
  isCompanyDisabled,
  isAccountSuspended,
  isMaintenance,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const lang = useSelector((state) => state.constant.languages);
  const userUuid = useSelector((state) => state.profile.id);
  /**
   * this effect will keep track of user navigation,
   * when there is a change in location, setCurrentModule action will be dispatched
   * this is to change the app title according to current module
   */
  useEffect(() => {
    document.title = "Admin – SmartKood";
    setCurrentModule(location.pathname);
  }, [location, setCurrentModule]);

  useEffect(() => {
    if (isSessionChecked) {
      if (!isUserVerified) {
        history.push(SIGN_UP_VERIFICATION);
      }

      initializeApp();
    }
  }, [isSessionChecked, isUserVerified, history, initializeApp]);

  useEffect(() => {
    if (
      (process.env.REACT_APP_MAINTENANCE_MODE === "true" || isMaintenance) &&
      window.location.pathname !== "/maintenance"
    ) {
      window.location.replace("/maintenance");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaintenance]);

  useEffect(() => {
    if (userUuid) {
      authApi.checkAcceptNewAgreement(userUuid).then((response) => {
        if (parseInt(response.data.is_accept_new_agreement) !== 1 && window.location.pathname !== "/admin/dashboard" && isCompanyApproved) {
          history.push("/admin/dashboard");
        }
      }).catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUuid]);

  useEffect(() => {
    if (isAppInitialized) {
      if (!isCompanyApproved) {
        history.push(SIGN_UP_STEPS);
      }
    }
  }, [isAppInitialized, isCompanyApproved, history]);

  useEffect(() => {
    if (isAppInitialized) {
      if (isCompanySuspended || isAccountSuspended) {
        history.push(SUSPENDED);
      }
      if (isCompanyDisabled) {
        history.push(DEACTIVATED);
      }
    }
  }, [isAppInitialized, isCompanySuspended, history, isAccountSuspended, isCompanyDisabled]);

  useEffect(() => {
    if (isSessionChecked && !isAuthenticated) {
      history.push(LOGIN);
    }
  }, [isAuthenticated, isSessionChecked, history]);

  return !isSessionChecked || isInitializing || error ? (
    <SplashScreen
      error={error}
      errorMessage={getLang(lang, "message.error.ERROR_NOT_AVAILABLE_NOW")}
    />
  ) : (
    <StripeWrapper>
      <MainPage />
    </StripeWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  initializeApp: () => dispatch(initializeApp()),
  setCurrentModule: (pathname) => dispatch(setCurrentModule(pathname)),
});

const mapStateToProps = (state) => ({
  isInitializing: isInitializing(state),
  error: state.session.initialize.error,
  isUserVerified: isUserVerified(state),
  isCompanyApproved: isCompanyApproved(state),
  isAuthenticated: state.session.isAuthenticated,
  isSessionChecked: isSessionChecked(state),
  isAppInitialized: !state.session.initialize.loading,
  isCompanyDisabled: isCompanyDisabled(state),
  isCompanySuspended: isCompanySuspended(state),
  isAccountSuspended: isAccountSuspended(state),
  isMaintenance: state.session.isMaintenance,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(MainContainer)
);
