import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  TablePagination,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import RefreshTable from "components/table/refreshTable";
import { Edit as EditIcon } from "@material-ui/icons";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import languages from "lib/constants/languages";
import { TablePaginationActions } from "components/tablePagination";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      right: "-2px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center",
  },
}));

export default function AdtInfoTitleTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  lang,
  supportedLang,
  isSelectAll,
  handleSelectAll,
  selectedIds,
  handleSelectTitle,
  handleEdit,
  handleAdd,
  totalFiltered,
  page,
  rowsPerPage,
  paginationOptions,
  handleChangePage,
  handleChangeRowPerPage,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {rows.length > 0 || isFetching ? (
        <Box>
          <TableContainer style={{ border: "1px solid #dcdcdc" }}>
            <Table className={classes.table} aria-label="product table">
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell padding="checkbox">
                    <Checkbox
                      className={clsx({
                        [classes.selectAll]: isSelectAll,
                      })}
                      checked={isSelectAll}
                      onChange={(event) => handleSelectAll()}
                    />
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.FORMAT")}
                  </StyledTableCell>
                  {languages
                    .filter(
                      (l) => supportedLang.includes(l.code) || l.code === "EN"
                    )
                    .map((l, index) => (
                      <StyledTableCell key={index}>{l.label}</StyledTableCell>
                    ))}
                  <StickyRightTableCell>
                    <Box style={{ padding: "8px 16px" }}>
                      {getLang(lang, "label.ACTION")}
                    </Box>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow
                        key={index}
                        columnCount={supportedLang.length + 3}
                      />
                    ))
                  : rows.map((row, index) => {
                      let isSelected =
                        (isSelectAll && !selectedIds.includes(row.id)) ||
                        (!isSelectAll && selectedIds.includes(row.id));

                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StickyLeftTableCell padding="checkbox">
                            <Checkbox
                              className={clsx({
                                [classes.selectOne]: isSelected,
                              })}
                              checked={isSelected}
                              onChange={(event) =>
                                handleSelectTitle(row, event.target.checked)
                              }
                            />
                          </StickyLeftTableCell>
                          <StyledTableCell key={index}>
                            {row.format === 2
                              ? getLang(lang, "label.DATE")
                              : row.format === 3
                              ? getLang(lang, "label.NUMBER")
                              : row.format === 4
                              ? getLang(lang, "label.URL_LINK")
                              : getLang(lang, "label.TEXT")}
                          </StyledTableCell>
                          {languages
                            .filter(
                              (l) =>
                                supportedLang.includes(l.code) ||
                                l.code === "EN"
                            )
                            .map((l, index) => (
                              <StyledTableCell key={index}>
                                {row.title[l.code] ?? "-"}
                              </StyledTableCell>
                            ))}
                          <StickyRightTableCell>
                            <Box display="flex" justifyContent="center">
                              <EditIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleEdit(row);
                                }}
                              />
                            </Box>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Box>
      ) : !isFetching && rows.length === 0 ? (
        <TableContainer style={{ border: "1px solid #dcdcdc" }}>
          <Table
            className={classes.table}
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell padding="checkbox">
                  <Checkbox disabled={true} />
                </StickyLeftTableCell>
                <StyledTableCell width={48}>
                  {getLang(lang, "label.NUM")}
                </StyledTableCell>
                {languages
                  .filter(
                    (l) => supportedLang.includes(l.code) || l.code === "EN"
                  )
                  .map((l, index) => (
                    <StyledTableCell key={index}>{l.label}</StyledTableCell>
                  ))}
                <StickyRightTableCell>
                  <Box style={{ padding: "8px 16px" }}>
                    {getLang(lang, "label.ACTION")}
                  </Box>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={3} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "message.info.NO_TITLE_AVAILABLE")}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 8 }}
              onClick={handleAdd}
            >
              {getLang(lang, "label.ADD_TITLE")}
            </Button>
          </Box>
        </TableContainer>
      ) : null}
    </>
  );
}
