import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION } from "../constants";
import {
  getUnprocessClaimNotification,
  getUnprocessMasterListNotification,
  getUnprocessShippingOrderNotification,
} from "./notification.action";

const initialState = {
  inProcess: [],
  alerts: [],
  notificationCount: {
    claim: 0,
    masterList: 0,
    shippingOrder: 0,
  },
};

const notificationSlice = createSlice({
  name: NOTIFICATION,
  initialState,
  reducers: {
    addAlert(state, action) {
      state.alerts = [...state.alerts, action.payload];
    },
    resetAlert(state, action) {
      state.alerts = [];
    },
    closeSingleAlert(state, action) {
      state.alerts.shift();
    },
    setLoading(state, action) {
      /**
       * payload: {id: string, state: boolean}
       */
      const { payload } = action;
      if (payload.state) state.inProcess = [...state.inProcess, payload];
      else
        state.inProcess = state.inProcess.filter(({ id }) => id !== payload.id);
    },
  },
  extraReducers: {
    [getUnprocessClaimNotification.fulfilled]: (state, action) => {
      state.notificationCount.claim = action.payload;
    },
    [getUnprocessClaimNotification.rejected]: (state) => {
      state.notificationCount.claim = 0;
    },
    [getUnprocessMasterListNotification.fulfilled]: (state, action) => {
      state.notificationCount.masterList = action.payload;
    },
    [getUnprocessMasterListNotification.rejected]: (state) => {
      state.notificationCount.masterList = 0;
    },
    [getUnprocessShippingOrderNotification.fulfilled]: (state, action) => {
      state.notificationCount.shippingOrder = action.payload;
    },
    [getUnprocessShippingOrderNotification.rejected]: (state) => {
      state.notificationCount.shippingOrder = 0;
    },
  },
});

export const { addAlert, resetAlert, setLoading, closeSingleAlert } =
  notificationSlice.actions;
export const { reducer } = notificationSlice;
