import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Divider,
  Button,
} from "@material-ui/core";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import { BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";
import DefaultImg from "assets/img/img-default.png";
import moment from "moment";
import { getLang } from "app/feature/constants";
import TransferItemsListPanel from "modules/stockflow/components/panel/transferItemsListPanel";
import ReceiveItemsListPanel from "modules/stockflow/components/panel/receiveItemsListPanel";
import DeliveryInfoPanel from "modules/stockflow/components/panel/deliveryInfoPanel";
import "moment/min/locales.min";
import DOMPurify from "dompurify";

const useStyle = makeStyles((theme) => ({
  panelContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em",
    gridTemplateAreas: "'left-panel right-panel'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'left-panel left-panel''right-panel right-panel'",
    },
  },
  productContainer: {
    marginBottom: "16px",
    padding: "20px 16px",
    borderRadius: "8px",
  },
  list: {
    maxHeight: "380px",
    overflowY: "auto",
    padding: "4px 16px 16px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  field: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    columnGap: theme.spacing(2),
  },
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "20px 16px",
    marginBottom: 16,
  },
  largeProfile: {
    height: 80,
    width: 80,
    minWidth: 80,
    padding: "2px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  respondProfile: {
    height: 32,
    width: 32,
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      border: "1px solid #D0D5DD",
      borderRadius: "50%",
    },
  },
  branchProfile: {
    height: 40,
    width: 40,
    minWidth: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
  },
  productImage: {
    height: 50,
    width: 50,
    minWidth: 50,
    borderRadius: "4px",
    border: "1px solid #0000001A",
    padding: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
    },
  },
  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  seperator: {
    display: "flex",
    flexDirection: "column",
    flex: 0,
    alignItems: "center",
  },
  dot: {
    width: 12,
    height: 12,
    boxShadow: "none",
    backgroundColor: "#A749F0",
    color: "#ffffff",
    padding: 4,
    borderRadius: "50%",
    textAlign: "center",
    margin: "5px 0px",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: "25px",
    borderLeft: "2px dotted #D0D5DD",
  },
  flow: {
    paddingInlineStart: "0px",
  },
  tierIcon: {
    borderRadius: "4px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "2px",
    left: "-1px",
  },
  tierLabel: {
    color: "#FFFFFF",
  },
  statusChip: {
    color: "#FFFFFF",
    padding: "7px 18px",
    marginLeft: "10px",
    borderRadius: "4px",
  },
  summaryBox: {
    border: "1px solid #D0D5DD",
    padding: "8px 10px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "1em",
  },
}));

export default function ReceiveTransferDetailComponent({
  transfer,
  isFetching,
  isError,
  handleReload,
  lang,
  id,
  language,
  showApprovalConfirm,
  fetchDeliveryInfo,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const PENDING_APPROVAL = "Pending Aproval";
  const PENDING_TO_RECEIVE = "Pending To Receive";
  const PARTIAL_RECEIVE = "Partial Receive";
  const RECEIVED = "Received";
  const RECEIVED_MISSING = "Receievd (Missing)";
  const RECEIVED_FORCED = "Received (Forced)";
  const AUTO_RECEIVED_ALL = "Auto Completed (Receive All)";
  const AUTO_RECEIVED_PARTIAL = "Auto Completed (Partial)";
  const AUTO_RECEIVED_MISSING = "Auto Completed (Missing)";
  const REJECTED = "Rejected";
  const CANCELLED = "Cancelled";

  const statusColor = {
    [PENDING_APPROVAL]: "#FDB022",
    [PENDING_TO_RECEIVE]: "#FDB022",
    [PARTIAL_RECEIVE]: "#FDB022",
    [RECEIVED]: "#32D583",
    [RECEIVED_MISSING]: "#32D583",
    [RECEIVED_FORCED]: "#32D583",
    [AUTO_RECEIVED_ALL]: "#32D583",
    [AUTO_RECEIVED_PARTIAL]: "#32D583",
    [AUTO_RECEIVED_MISSING]: "#32D583",
    [REJECTED]: "#F97066",
    [CANCELLED]: "#98A2B3",
  };

  const statusPanelColor = {
    [PENDING_APPROVAL]: "#FFFCF5",
    [PENDING_TO_RECEIVE]: "#FFFCF5",
    [PARTIAL_RECEIVE]: "#FFFCF5",
    [RECEIVED]: "#F6FEF9",
    [RECEIVED_MISSING]: "#F6FEF9",
    [RECEIVED_FORCED]: "#F6FEF9",
    [AUTO_RECEIVED_ALL]: "#F6FEF9",
    [AUTO_RECEIVED_PARTIAL]: "#F6FEF9",
    [AUTO_RECEIVED_MISSING]: "#F6FEF9",
    [REJECTED]: "#FFFBFA",
    [CANCELLED]: "#FCFCFD",
  };

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.RECEIVE_TRANSFER")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.RECEIVE_TRANSFER_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box gridArea="panel">
        {!isFetching && !isError && transfer && (
          <>
            <Box
              display="flex"
              alignItems="center"
              px={2}
              py={2.5}
              mb={2}
              style={{ backgroundColor: statusPanelColor[transfer.status] }}
            >
              <Typography variant="h5">
                <b>
                  {getLang(lang, "label.TRANSFER_ID")}:{" "}
                  {transfer.reference_code}
                </b>
              </Typography>
              <Box
                className={classes.statusChip}
                style={{ backgroundColor: statusColor[transfer.status] }}
              >
                <Typography variant="h5">
                  <b>{getLang(lang, `db_label.${transfer.status}`)}</b>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.panelContainer}>
              <Box gridArea="left-panel">
                <Paper
                  className={classes.panel}
                  elevation={3}
                  style={{ padding: "8px 16px" }}
                >
                  <Box mt={2}>
                    <ul className={classes.flow}>
                      <li className={classes.item}>
                        <Box className={classes.seperator}>
                          <Box
                            className={classes.dot}
                            style={{ backgroundColor: "#344054" }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          />
                          <Box className={classes.connector} />
                        </Box>
                        <Box ml={2} mb={3} mt={-0.5}>
                          <Box display="flex" alignItems="center" mb={0.5}>
                            <Typography variant="h6">
                              {getLang(lang, "label.TRANSFER_FROM")}
                            </Typography>
                          </Box>
                          <Box mb={1} display="flex" alignItems="center">
                            <Box className={classes.largeProfile} mr={1.5}>
                              {transfer.transfer_from?.picture ? (
                                <img
                                  src={transfer.transfer_from.picture}
                                  alt={transfer.transfer_from.name}
                                />
                              ) : (
                                <img src={DefaultImg} alt="default profile" />
                              )}
                            </Box>
                            <Box>
                              <Box mb={0.5}>
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {transfer.transfer_from.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  <span>
                                    {transfer.transfer_from.phone_code}{" "}
                                  </span>
                                  <span>
                                    {transfer.transfer_from.phone_number}
                                  </span>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {transfer.transfer_from.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </li>
                      <li className={classes.item}>
                        <Box className={classes.seperator}>
                          <Box
                            className={classes.dot}
                            style={{
                              backgroundColor: [
                                PENDING_TO_RECEIVE,
                                CANCELLED,
                                REJECTED,
                              ].includes(transfer.status)
                                ? "#98A2B3"
                                : "#344054",
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          />
                        </Box>
                        <Box ml={2} mt={-0.5}>
                          <Box display="flex" alignItems="center" mb={0.5}>
                            <Typography
                              variant="h6"
                              color={
                                [
                                  PENDING_TO_RECEIVE,
                                  CANCELLED,
                                  REJECTED,
                                ].includes(transfer.status)
                                  ? "textSecondary"
                                  : "textPrimary"
                              }
                            >
                              {getLang(lang, "label.TRANSFER_TO")}
                            </Typography>
                          </Box>
                          <Box mb={1} display="flex" alignItems="center">
                            <Box className={classes.largeProfile} mr={1.5}>
                              {transfer.transfer_to?.picture ? (
                                <img
                                  src={transfer.transfer_to.picture}
                                  alt={transfer.transfer_to.name}
                                />
                              ) : (
                                <img src={DefaultImg} alt="default profile" />
                              )}
                            </Box>
                            <Box>
                              <Box mb={0.5}>
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {transfer.transfer_to.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  <span>
                                    {transfer.transfer_to.phone_code}{" "}
                                  </span>
                                  <span>
                                    {transfer.transfer_to.phone_number}
                                  </span>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {transfer.transfer_to.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </li>
                    </ul>
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.ORDER_INFORMATION")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  {transfer.status !== PENDING_TO_RECEIVE &&
                    transfer.status !== PENDING_APPROVAL && (
                      <>
                        <Box my={2}>
                          <Box className={classes.field}>
                            <Typography variant="body1">
                              {[
                                RECEIVED,
                                RECEIVED_MISSING,
                                RECEIVED_FORCED,
                                AUTO_RECEIVED_ALL,
                                AUTO_RECEIVED_MISSING,
                                AUTO_RECEIVED_PARTIAL,
                              ].includes(transfer.status)
                                ? getLang(lang, "label.RECEIVED_DATE")
                                : transfer.status === REJECTED
                                ? getLang(lang, "label.REJECTED_DATE")
                                : transfer.status === PARTIAL_RECEIVE
                                ? getLang(lang, "label.LAST_RECEIVE_DATE")
                                : getLang(lang, "label.CANCELLED_DATE")}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                              <span>: </span>
                              <span>
                                {moment
                                  .utc(transfer.respond_date)
                                  .local()
                                  .format("lll")}
                              </span>
                            </Typography>
                          </Box>
                          {(transfer.status === RECEIVED ||
                            transfer.status === RECEIVED_MISSING ||
                            transfer.status === RECEIVED_FORCED) && (
                            <Box className={classes.field} mt={2}>
                              <Typography variant="body1">
                                {getLang(lang, "label.SCANNED_STATUS")}
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                <span>: </span>
                                <span>
                                  {transfer.received_method
                                    ? getLang(
                                        lang,
                                        `db_label.${transfer.received_method}`
                                      )
                                    : "-"}
                                </span>
                              </Typography>
                            </Box>
                          )}
                          {(transfer.status === REJECTED ||
                            transfer.status === CANCELLED) && (
                            <Box className={classes.field} mt={2}>
                              <Typography variant="body1">
                                {getLang(lang, "label.REASON")}
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                <span>: </span>
                                <span>{transfer.reason ?? "-"}</span>
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Divider />
                      </>
                    )}
                  <Box mt={2}>
                    <Box className={classes.field}>
                      <Typography variant="body1">
                        {getLang(lang, "label.TRANSFER_DATE")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {moment
                            .utc(transfer.transfer_date)
                            .local()
                            .format("lll")}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.REMARK_BY_SENDER")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ marginRight: 3 }}
                        >
                          :{" "}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {transfer.remarks
                            ? generateLink(transfer.remarks)
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                {!!transfer.delivery_info && (
                  <Paper className={classes.panel} elevation={3}>
                    <Box>
                      <Typography>
                        <b>{getLang(lang, "label.DELIVERY_INFO")}</b>
                      </Typography>
                    </Box>
                    {transfer.delivery_info.status === "delivered" && (
                      <Box mt={2} className={classes.deliveredCard}>
                        <Typography variant="body1">
                          <b>{getLang(lang, `label.DELIVERED`)}</b>
                        </Typography>
                        <Typography variant="body2">
                          {getLang(lang, "paragraph.DELIVERED_DESC")}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      mt={2}
                      className={classes.sectionCard}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Box>
                          <Typography
                            variant="body1"
                            display="inline"
                            className={classes.greyText}
                          >
                            {getLang(lang, "label.TRACKING_NUMBER")}:&nbsp;
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ color: "#6AAF68" }}
                            display="inline"
                          >
                            <b>{transfer.delivery_info.tracking ?? "-"}</b>
                          </Typography>
                        </Box>
                        <Box mt={1}>
                          <Typography
                            variant="body2"
                            className={classes.greyText}
                            display="inline"
                          >
                            {getLang(lang, "label.COURIER_COMPANY")}:&nbsp;
                          </Typography>
                          <Typography variant="body2" display="inline">
                            <b>{transfer.delivery_info.courier_name ?? "-"}</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {!!transfer.delivery_info.courier_code && (
                      <Box
                        mt={2}
                        className={classes.sectionCard}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <DeliveryInfoPanel
                          fetchData={fetchDeliveryInfo}
                          storeName="stockflowReceiveTransfer"
                        />
                      </Box>
                    )}
                  </Paper>
                )}
              </Box>
              <Box gridArea="right-panel">
                {transfer.status === PENDING_APPROVAL && (
                  <Paper
                    elevation={0}
                    style={{ marginBottom: 16, padding: 10 }}
                  >
                    <Box display="flex" height={42}>
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        size="small"
                        type="submit"
                        style={{
                          flex: 1,
                          marginRight: 16,
                          backgroundColor: "#F04438",
                        }}
                        onClick={() => showApprovalConfirm(false)}
                      >
                        {getLang(lang, "label.REJECT")}
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        size="small"
                        type="submit"
                        style={{ flex: 1 }}
                        onClick={() => showApprovalConfirm(true)}
                      >
                        {getLang(lang, "label.APPROVE")}
                      </Button>
                    </Box>
                  </Paper>
                )}
                <Box>
                  {[
                    PARTIAL_RECEIVE,
                    RECEIVED,
                    RECEIVED_FORCED,
                    RECEIVED_MISSING,
                    AUTO_RECEIVED_ALL,
                    AUTO_RECEIVED_PARTIAL,
                    AUTO_RECEIVED_MISSING,
                  ].includes(transfer.status) ? (
                    <ReceiveItemsListPanel
                      totalBox={transfer.total_transfer_boxes}
                      totalReceiveBox={transfer.total_received_boxes}
                      totalPackage={transfer.total_transfer_packages}
                      totalReceivePackage={transfer.total_received_packages}
                      status={transfer.status}
                      id={id}
                      isReceiver={true}
                    />
                  ) : (
                    <TransferItemsListPanel
                      totalBox={transfer.total_transfer_boxes}
                      totalPackage={transfer.total_transfer_packages}
                      status={transfer.status}
                      id={id}
                      isReceiver={true}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
        {!isFetching && isError && (
          <Box className={classes.tabContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isFetching && (
          <Box gridArea="main" mt={6} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
