import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import {
  getReceiveTransferList,
  resetReceiveTransferListing,
} from "modules/stockflow/redux";
import moment from "moment";
import ReceiveTransferListingComponent from "./receiveTransferListing.component";
import { useHistory } from "react-router-dom";

function ReceiveTransferListingContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  const isFetching = useSelector((state) =>
    selectLoading(state, getReceiveTransferList.typePrefix)
  );

  const totalFiltered = useSelector(
    (state) => state.stockflowReceiveTransfer.receiveTransferList.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowReceiveTransfer.receiveTransferList.totalRecords
  );
  const transferList = useSelector(
    (state) => state.stockflowReceiveTransfer.receiveTransferList.list
  );
  const isError = useSelector(
    (state) => state.stockflowReceiveTransfer.isLoadingReceiveTransferListError
  );

  const isSearchResult = transferList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    status: "",
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    transferFrom: [],
    transferTo: [],
    respondDate: null,
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetReceiveTransferListing());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("receive_transfer_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("receive_transfer_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(resetReceiveTransferListing());
      handleDataLoad();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    dispatch(resetReceiveTransferListing());
  }, [dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      filter: {
        search: tableFilter.search,
        status: tableFilter.status,
        from_date: tableFilter.fromDate,
        to_date: tableFilter.toDate,
        transfer_from: tableFilter.transferFrom,
        transfer_to: tableFilter.transferTo,
        respond_date: tableFilter.respondDate,
      },
    };
    dispatch(getReceiveTransferList(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      fromDate: filter.dateRange?.from
        ? moment(filter.dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: filter.dateRange?.to
        ? moment(filter.dateRange.to).format("YYYY-MM-DD")
        : "",
      status: filter.status?.value ?? "",
      transferFrom: filter.transferFrom,
      transferTo: filter.transferTo,
      respondDate: filter.respondDate,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      status: "",
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      transferFrom: [],
      transferTo: [],
      respondDate: null,
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      transferFrom: [],
      transferTo: [],
      respondDate: "",
      status: "",
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        "receive_transfer_filter",
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  return (
    <>
      <ReceiveTransferListingComponent
        transfers={transferList}
        language={language}
        isFetching={isFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={handleDataLoad}
        isSearchResult={isSearchResult}
        paginationOptions={paginationOptions}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}

export default ReceiveTransferListingContainer;
