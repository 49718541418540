import axios from "axios";

export default function approvalOrder(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/distributor/admin/api/v1/master-list/${parameters.uuid}/approval`,
        {
          status: parameters.status,
          remark: parameters.remark,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
