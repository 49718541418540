import axios from "axios";

export default function getBranchListDropdown(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/master-list/branch-dropdown", {
        length: parameters.length,
        start: parameters.start,
        search: parameters.search,
        role: parameters.role,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
