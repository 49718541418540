import React, { useState } from "react";
import UpdateSnAdtInfoDialogComponent from "./updateSnAdtInfoDialog.component";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addAlert, selectLoading } from "modules/notification";
import {
  updateAdtInfoValues,
  updateSnAdtInfo,
} from "modules/serial-number/redux";
import { getLang } from "app/feature/constants";
import moment from "moment";

function UpdateSnAdtInfoDialogContainer({
  isOpen,
  handleClose,
  additionalInfos,
  enc,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateSnAdtInfo.typePrefix)
  );
  const [languageTabIndex, setLanguageTabIndex] = useState(0);

  const handleSubmit = async (values) => {
    let originalIds = additionalInfos.map((a) => a.id);

    let adtValues = [];
    values.additionalInfo.forEach((a) => {
      if (originalIds.includes(a.id)) {
        originalIds = originalIds.filter((id) => id !== a.id);
      }

      if (a.id) {
        adtValues.push({
          id: a.id,
          title: a.title,
          format: a.format,
          value: a.value,
        });
      }
    });

    dispatch(
      updateSnAdtInfo({
        enc: enc,
        info: adtValues,
        deleted_info_ids: originalIds,
      })
    ).then(() => {
      dispatch(
        updateAdtInfoValues(
          adtValues.map((a) => {
            if (a.format === 2) {
              a.value = moment(a.value).format("DD MMM YYYY");
            }
            return a;
          })
        )
      );
      handleClose();
    });
  };

  const showAlert = (message) => {
    dispatch(
      addAlert({
        severity: "error",
        message: message,
      })
    );
  };

  const validate = (formik) => {
    let valid = true;
    let hasEmptyField = false;
    // formik.setTouched({});

    let additionalInfo = [];

    formik.values.additionalInfo.forEach((info, infoIdx) => {
      if (!info.title) {
        if (info.value) {
          valid = false;
          dispatch(
            addAlert({
              severity: "warning",
              message: getLang(
                lang,
                "message.warning.ADDITIONAL_INFO_TITLE_EMPTY"
              ),
            })
          );
        } else {
          return;
        }
      } else {
        if (!info.value) {
          valid = false;
          hasEmptyField = true;
          // formik.setFieldTouched(`additionalInfo.[${infoIdx}].value`, true);
        } else if (info.format === 1) {
          formik.values.usedLang.forEach((lang) => {
            let val = info.value?.[lang.code];
            if (!val || !val.trim()) {
              valid = false;
              hasEmptyField = true;
              formik.setFieldTouched(
                `additionalInfo.[${infoIdx}].value.[${lang.code}]`,
                true
              );
            }
          });
        }
      }

      additionalInfo.push({
        id: info.id,
        title: info.title,
        format: info.format,
        value: info.value,
      });
    });

    if (!additionalInfo.length) {
      additionalInfo.push({
        id: null,
        title: null,
        format: 1,
        value: "",
      });
      // valid = false;
    }

    formik.setFieldValue("additionalInfo", additionalInfo);

    if (hasEmptyField) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "label.NOT_ALL_LANG_FILLED"),
        })
      );
    }

    return valid;
  };

  const handleAddLang = (lang, formik) => {
    const temp = [...formik.values.usedLang];
    temp.push(lang);
    setLanguageTabIndex(temp.length - 1);

    let adtTemp = [...formik.values.additionalInfo];

    adtTemp = adtTemp.map((a) => {
      let value = a.format === 1 ? { ...a.value, [lang.code]: "" } : a.value;

      return {
        ...a,
        value,
      };
    });

    formik.setFieldValue("usedLang", temp);
    formik.setFieldValue("additionalInfo", adtTemp);
  };

  const handleRemoveLang = (formik) => {
    let toRemove = formik.values.usedLang[languageTabIndex].code;

    const temp = [...formik.values.usedLang].filter(
      (item) => item.code !== formik.values.usedLang[languageTabIndex].code
    );

    let adtTemp = [...formik.values.additionalInfo];

    adtTemp = adtTemp.map((a) => {
      let value = a.value;
      if (a.format === 1) {
        value = { ...a.value };
        delete value[toRemove];
      }
      return {
        ...a,
        value,
      };
    });

    setLanguageTabIndex(temp.length - 1);

    formik.setFieldValue("usedLang", temp);

    formik.setFieldValue("additionalInfo", adtTemp);
  };

  return (
    <UpdateSnAdtInfoDialogComponent
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      isSubmitting={isSubmitting}
      addAlert={showAlert}
      additionalInfos={additionalInfos}
      languageTabIndex={languageTabIndex}
      setLanguageTabIndex={setLanguageTabIndex}
      validate={validate}
      handleAddLang={handleAddLang}
      handleRemoveLang={handleRemoveLang}
    />
  );
}

UpdateSnAdtInfoDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UpdateSnAdtInfoDialogContainer;
