import axios from "axios";

export default function updateSoReceiver(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/shipping-order/update-receiver", {
        shipping_order_uuid: parameters.shipping_order_uuid,
        receiver_name: parameters.receiver_name,
        address_line_1: parameters.address_line_1,
        address_line_2: parameters.address_line_2,
        city: parameters.city,
        state: parameters.state,
        postcode: parameters.postcode,
        country: parameters.country,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
