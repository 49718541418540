/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import RoleSelectComponent from "./roleSelect.component";
import RoleSelectPanelComponent from "./roleSelectPanel.component";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import accountApi from "app/api/account";
import { addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

function RoleSelectContainer({
  handleChange,
  disabled,
  value,
  styles,
  textStyles,
  placeholder,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getData();
  }, [dispatch]);

  const getData = () => {
    setIsError(false);
    setIsLoading(true);
    accountApi
      .getAvailableGroupRoles()
      .then((res) => {
        setRoles(res.data);
      })
      .catch((error) => {
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, error.code) })
        );
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectRole = (role) => {
    handleChange(role);
    setAnchorEl(null);
  };

  return (
    <Box position="relative" width="100%">
      <RoleSelectComponent
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <RoleSelectPanelComponent
        value={value}
        handleClose={handleClose}
        isFetching={isLoading}
        roles={roles}
        handleSelectRole={handleSelectRole}
        lang={lang}
        anchorEl={anchorEl}
        open={open}
        handleReload={getData}
        isError={isError}
      />
    </Box>
  );
}

export default RoleSelectContainer;
