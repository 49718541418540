import axios from "axios";

const endpoint_url = "/authentication/api/basic/accept-new-agreement";

const acceptNewAgreement = (userUuid) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, {user_uuid: userUuid})
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default acceptNewAgreement;
