import axios from "axios";

export default function getPendingCheckoutListing(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/prife/checkout", {
        status: "pending",
        length: parameters.rowsPerPage,
        start: parameters.page * parameters.rowsPerPage,
        order: parameters.order,
        order_by: parameters.orderBy,
        nodes: parameters.nodeIds,
        filter: {
          do_number: parameters.doNumberSearch,
          from_date: parameters.fromDate,
          to_date: parameters.toDate,
          product_uuid: parameters.products.map(e => e.id),
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
