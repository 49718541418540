import React from "react";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  TextFields as TextFieldsIcon,
  DateRangeOutlined as DateIcon,
  Filter1 as NumberIcon,
  ErrorRounded as ErrorRoundedIcon,
  CheckCircle as CheckIcon,
  Link as LinkIcon,
} from "@material-ui/icons";
import CopyIcon from "assets/img/copyIcon.png";
import clsx from "clsx";
import AdditionalFieldTitleWithLangSelect from "../../select/additionalFieldTitleWithLangSelect";
import { ErrorMessage, Field } from "formik";
import DatePickerScrollDialog from "../../dialog/datePickerScrollDialog";

const useStyle = makeStyles((theme) => ({
  panel: {
    "& .MuiAccordionSummary-root.Mui-focused": {
      backgroundColor: "#3A4D54 !important",
    },
  },
  squareButton: {
    height: 45,
    width: 45,
    minWidth: "fit-content",
    padding: 0,
    backgroundColor: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  accordionHeader: {
    backgroundColor: "#3A4D54",
    borderRadius: 4,
    padding: "0px 16px",
  },
  greyText: {
    color: "#98A2B3",
  },
  icon: {
    height: 20,
    width: 20,
  },
  button: {
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
    borderRadius: 4,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  selectedButton: {
    border: "2px solid #6AAF68",
  },
  headerInputRow: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
    width: "100%",
    height: 42,
  },
}));

export default function SingleSnAdditionalInfoAccordionComponent({
  lang,
  isUpdating,
  formik,
  info,
  adtInfoIdx,
  currentLang,
}) {
  const classes = useStyle();

  return (
    <Accordion defaultExpanded={true} style={{ marginBottom: 16 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls={`panel${adtInfoIdx + 1}a-content`}
        id={`panel${adtInfoIdx + 1}a-header`}
        className={classes.accordionHeader}
      >
        <Box
          my={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          <Box
            display="flex"
            alignItems="center"
            className={classes.headerInputRow}
          >
            <AdditionalFieldTitleWithLangSelect
              value={info}
              handleChange={(field) => {
                let temp = [...formik.values.additionalInfo];
                temp[adtInfoIdx] = {
                  ...temp[adtInfoIdx],
                  id: field.id,
                  title: field.title,
                  format: field.format,
                  value: field.format === 1 ? { EN: "" } : "",
                };
                formik.setFieldValue("additionalInfo", temp);

                let ids = [...formik.values.selectedTitleIds];
                if (!ids.includes(field.id)) {
                  ids.push(field.id);
                }
                formik.setFieldValue("selectedTitleIds", ids);
              }}
              placeholder={getLang(lang, "label.TITLE")}
              style={{ height: 38, flex: 1 }}
              userLang={currentLang}
              selectedIds={formik.values.selectedTitleIds}
            />
          </Box>

          <Box display="flex" alignItems="center" ml={2}>
            <Button
              variant="outlined"
              className={classes.squareButton}
              color="primary"
              disableElevation
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                let temp = [...formik.values.additionalInfo];
                temp.push({
                  ...temp[adtInfoIdx],
                  id: "",
                  title: null,
                  value: "",
                });
                formik.setFieldValue("additionalInfo", temp);
              }}
              disabled={isUpdating}
            >
              <img src={CopyIcon} alt="copy" className={classes.icon} />
            </Button>
            <Button
              variant="outlined"
              className={classes.squareButton}
              disableElevation
              color="primary"
              type="button"
              style={{ marginLeft: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                formik.setFieldValue("selectedDeleteIndex", adtInfoIdx);
              }}
              disabled={isUpdating}
            >
              <DeleteIcon size={18} style={{ color: "#F04438" }} />
            </Button>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box p={2} display="flex" flexDirection="column">
          {info.title && (
            <>
              <Box>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.FORMAT")}</b>
                </Typography>
                <Box display="flex" alignItems="center">
                  {info.format === 1 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <TextFieldsIcon />
                      <Box ml={1}>
                        <Typography>{getLang(lang, "label.TEXT")}</Typography>
                      </Box>
                    </Button>
                  )}
                  {info.format === 2 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <DateIcon />
                      <Box ml={1}>
                        <Typography>{getLang(lang, "label.DATE")}</Typography>
                      </Box>
                    </Button>
                  )}
                  {info.format === 3 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <NumberIcon />
                      <Box ml={1}>
                        <Typography>{getLang(lang, "label.NUMBER")}</Typography>
                      </Box>
                    </Button>
                  )}
                  {info.format === 4 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <LinkIcon />
                      <Box ml={1}>
                        <Typography>
                          {getLang(lang, "label.URL_LINK")}
                        </Typography>
                      </Box>
                    </Button>
                  )}
                </Box>
              </Box>
              <Box my={2}>
                <Divider />
              </Box>
            </>
          )}
          <Box>
            {info.format === 1 ? (
              <Box
                ref={(el) => {
                  if (el) {
                    el.style.setProperty("display", "flex", "important");

                    el.style.setProperty("align-items", "center", "important");
                  }
                }}
              >
                <Box
                  flex={1}
                  mr={1}
                  p={0.75}
                  borderRadius={4}
                  border="1px solid #D0D5DD"
                >
                  <Box alignItems="center" display="flex">
                    <Field
                      name={`additionalInfo.[${adtInfoIdx}].value.[${currentLang}]`}
                    >
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            multiline={true}
                            rowsMax={3}
                            onChange={(e) => {
                              let temp = [...formik.values.additionalInfo];
                              temp[adtInfoIdx] = {
                                ...temp[adtInfoIdx],
                                value: {
                                  ...temp[adtInfoIdx]["value"],
                                  [currentLang]: e.target.value,
                                },
                              };
                              formik.setFieldValue("additionalInfo", temp);
                            }}
                            value={info.value[currentLang] ?? ""}
                            placeholder={getLang(
                              lang,
                              "placeholder.PLEASE_ENTER_DESCRIPTION"
                            )}
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                padding: 0,
                                background: "white",
                                border: "none",
                              },
                            }}
                            inputProps={{
                              maxLength: 1000,
                              style: {
                                padding: "7px",
                                maxHeight: 70,
                                overflow: "auto !important",
                                minHeight: 19,
                              },
                            }}
                            style={{ width: "100%" }}
                          />
                        );
                      }}
                    </Field>
                    {formik.errors.additionalInfo?.[adtInfoIdx]?.value?.[
                      currentLang
                    ] &&
                      formik.touched.additionalInfo?.[adtInfoIdx]?.value?.[
                        currentLang
                      ] && (
                        <ErrorRoundedIcon
                          style={{
                            color: "#f44336",
                            marginLeft: 8,
                          }}
                        />
                      )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography variant="caption" style={{ color: "#D0D5DD" }}>
                      {info.value?.[currentLang]?.length ?? 0}/1000
                    </Typography>
                  </Box>
                </Box>
                <CheckIcon
                  style={{
                    color: info.value?.[currentLang]?.length
                      ? "#32D583"
                      : "#D0D5DD",
                  }}
                />
              </Box>
            ) : info.format === 2 ? (
              <Box display="flex" alignItems="center">
                <DatePickerScrollDialog
                  label=""
                  handleChange={(value) => {
                    let temp = [...formik.values.additionalInfo];
                    temp[adtInfoIdx] = {
                      ...temp[adtInfoIdx],
                      value: value.format("YYYY-MM-DD"),
                    };
                    formik.setFieldValue("additionalInfo", temp);
                  }}
                  value={info.value}
                  displayFormat="DD/MM/YYYY"
                  error={
                    formik.errors.additionalInfo?.[adtInfoIdx]?.value &&
                    formik.touched.additionalInfo?.[adtInfoIdx]?.value
                  }
                />
                <CheckIcon
                  style={{
                    color: info.value ? "#32D583" : "#D0D5DD",
                    marginLeft: 8,
                  }}
                />
              </Box>
            ) : info.format === 4 ? (
              <Box
                ref={(el) => {
                  if (el) {
                    el.style.setProperty("display", "flex", "important");

                    el.style.setProperty("align-items", "center", "important");
                  }
                }}
              >
                <Box
                  flex={1}
                  mr={1}
                  p={0.75}
                  borderRadius={4}
                  border="1px solid #D0D5DD"
                >
                  <Box alignItems="center" display="flex">
                    <Field name={`additionalInfo.[${adtInfoIdx}].value`}>
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            onChange={(e) => {
                              let temp = [...formik.values.additionalInfo];
                              temp[adtInfoIdx] = {
                                ...temp[adtInfoIdx],
                                value: e.target.value,
                              };
                              formik.setFieldValue("additionalInfo", temp);
                            }}
                            type="text"
                            value={info.value ?? ""}
                            placeholder={getLang(
                              lang,
                              "placeholder.ENTER_URL_HERE"
                            )}
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                padding: 0,
                                background: "white",
                                border: "none",
                              },
                            }}
                            inputProps={{
                              style: {
                                padding: "7px",
                                maxHeight: 70,
                                overflow: "auto !important",
                                minHeight: 19,
                              },
                            }}
                            style={{ width: "100%" }}
                          />
                        );
                      }}
                    </Field>
                    {formik.errors.additionalInfo?.[adtInfoIdx]?.value &&
                      formik.touched.additionalInfo?.[adtInfoIdx]?.value && (
                        <ErrorRoundedIcon
                          style={{
                            color: "#f44336",
                            marginLeft: 8,
                          }}
                        />
                      )}
                  </Box>
                  <FormHelperText
                    error={
                      formik.errors.additionalInfo?.[adtInfoIdx]?.value &&
                      formik.touched.additionalInfo?.[adtInfoIdx]?.value
                    }
                    style={{ paddingLeft: 8 }}
                  >
                    {ErrorMessage({
                      name: `additionalInfo.[${adtInfoIdx}].value`,
                    })}
                  </FormHelperText>
                </Box>
                <CheckIcon
                  style={{
                    color: info.value ? "#32D583" : "#D0D5DD",
                  }}
                />
              </Box>
            ) : (
              <Box
                ref={(el) => {
                  if (el) {
                    el.style.setProperty("display", "flex", "important");

                    el.style.setProperty("align-items", "center", "important");
                  }
                }}
              >
                <Box
                  flex={1}
                  mr={1}
                  p={0.75}
                  borderRadius={4}
                  border="1px solid #D0D5DD"
                >
                  <Box alignItems="center" display="flex">
                    <Field name={`additionalInfo.[${adtInfoIdx}].value`}>
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            onChange={(e) => {
                              let temp = [...formik.values.additionalInfo];
                              temp[adtInfoIdx] = {
                                ...temp[adtInfoIdx],
                                value: e.target.value,
                              };
                              formik.setFieldValue("additionalInfo", temp);
                            }}
                            type="number"
                            value={info.value ?? ""}
                            placeholder={getLang(
                              lang,
                              "placeholder.PLEASE_ENTER_NUMBER_DESCRIPTION"
                            )}
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                padding: 0,
                                background: "white",
                                border: "none",
                              },
                            }}
                            inputProps={{
                              style: {
                                padding: "7px",
                                maxHeight: 70,
                                overflow: "auto !important",
                                minHeight: 19,
                              },
                            }}
                            style={{ width: "100%" }}
                          />
                        );
                      }}
                    </Field>
                    {formik.errors.additionalInfo?.[adtInfoIdx]?.value &&
                      formik.touched.additionalInfo?.[adtInfoIdx]?.value && (
                        <ErrorRoundedIcon
                          style={{
                            color: "#f44336",
                            marginLeft: 8,
                          }}
                        />
                      )}
                  </Box>
                </Box>
                <CheckIcon
                  style={{
                    color: info.value ? "#32D583" : "#D0D5DD",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
