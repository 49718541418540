const API_HOST = process.env.REACT_APP_API_HOST_URL;

const saveFile = async (blob, filename) => {
  const a = document.createElement("a");

  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

const exportDispatchInstruction = async ({ id, token, diId }) => {
  let response = await fetch(
    `${API_HOST}/distributor/admin/api/v1/dispatch-instruction/${id}/export-di`,
    {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let blob = await response.blob();

  saveFile(blob, `dispatch_instruction_${diId}.pdf`);
};

export default exportDispatchInstruction;
