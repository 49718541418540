import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Clear as ClearIcon,
  Error as ErrorIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eceff0",
    borderRadius: 4,
    width: "100%",
    //height: 43,
    overflow: "hidden",
  },
  inputText: {
    fontSize: "0.875rem",
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "500",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  clearButton: {
    minWidth: 0,
  },
}));

function SearchableSelectComponent({
  handleClick,
  handleClear,
  value,
  placeholder,
  disabled,
  style,
  anchorRef,
  isEmpty,
  id,
  open,
  textStyle,
  isClearable,
  error,
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      aria-describedby={id}
      className={classes.root}
      disableRipple
      ref={anchorRef}
      onClick={handleClick}
      disabled={disabled}
      style={{ paddding: 12, ...style }}
    >
      <Typography
        variant="body2"
        className={clsx(classes.inputText, {
          [classes.filled]: !isEmpty,
        })}
        style={textStyle}
      >
        {isEmpty
          ? placeholder
          : value?.name !== undefined
          ? value?.name
          : value}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {isClearable && !isEmpty && (
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        )}
        {!disabled &&
          (open ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          ))}
        {!!error && (
          <ErrorIcon
            style={{ color: "#f44336", fontSize: 23, marginRight: -6 }}
          />
        )}
      </Box>
    </ButtonBase>
  );
}

SearchableSelectComponent.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default SearchableSelectComponent;
