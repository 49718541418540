const API_HOST = process.env.REACT_APP_API_HOST_URL;

const exportProductListingData = async ({ ...parameters }) => {
  openWindowWithPost(
    `${API_HOST}/analytic/export/v1/productlist/export-product-listing-data?&token=${parameters.token}&length=${parameters.length}&start=${parameters.start}&search=${parameters.search}&orderBy=${parameters.orderBy}&orderDirection=${parameters.orderDirection}&search_by=${parameters.search_by}&country=${parameters.country}&city=${parameters.city_id}&branch_uuid=${parameters.branch_uuid}&period=${parameters.period}&start_date=${parameters.start_date}&end_date=${parameters.end_date}`
  );
};

function openWindowWithPost(url, data) {
  // console.log("SENDED URL", url);
  var form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";

  var input = document.createElement("input");
  input.type = "hidden";
  input.name = "data";
  input.value = data;
  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export default exportProductListingData;
