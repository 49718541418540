import axios from "axios";

export default function getShippingOrderListDropdown({
  length,
  start,
  search,
  role_id,
}) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/master-list/shipping-order/list", {
        length,
        start,
        search,
        role_id,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
