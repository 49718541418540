import axios from "axios";

export default function getMasterList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/master-list/list", {
        length: parameters.length,
        start: parameters.start,
        filter: {
          search: parameters.filter.search,
          search_column: parameters.filter.search_column,
          order_type: parameters.filter.order_type,
          requestor: parameters.filter.requestors,
          products: parameters.filter.products,
          status: parameters.filter.status,
          role: parameters.filter.role,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
