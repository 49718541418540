import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import moment from "moment";
import { DateRangePicker } from "rsuite";

const useStyle = makeStyles((theme) => ({
  datePicker: {
    "& .rs-picker-toggle-wrapper": {
      display: "flex",
      height: "100%",
    },
    "& .rs-picker-default .rs-picker-toggle.rs-btn": {
      borderRadius: 4,
      backgroundColor: "#eceff0 !important",
      border: "none !important",
      display: "flex",
    },
    "& .rs-picker-toggle-caret": {
      height: "calc(100% - 14px)",
    },
    "& .rs-picker-disabled": {
      opacity: 1,
    },
    "& .rs-stack": {
      width: "100%",
      "& p": {
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
}));

export default function DateRangePickerComponent({
  handleChange,
  value,
  disabled,
  placeholder,
  minDate,
  maxDate,
  disableFuture,
  format,
}) {
  const classes = useStyle();

  return (
    <Box className={classes.datePicker} id="date-range-picker">
      <DateRangePicker
        character=" - "
        disabled={disabled}
        cleanable={false}
        placeholder={placeholder}
        format={format ?? "dd MMM yyyy"}
        preventOverflow={true}
        placement="autoVerticalEnd"
        disabledDate={(date) => {
          let disable = false;
          if (minDate) {
            disable = date.getTime() < Date(minDate);
          }
          if (maxDate) {
            disable = date.getTime() > Date(maxDate);
          }
          if (disableFuture) {
            disable = date.getTime() > Date.now();
          }
          return disable;
        }}
        editable={false}
        onChange={handleChange}
        value={value}
        ranges={[]}
        renderValue={(value, format) => (
          <Typography>
            {moment(value[0]).format("DD MMM YYYY")} -{" "}
            {moment(value[1]).format("DD MMM YYYY")}
          </Typography>
        )}
      />
    </Box>
  );
}
