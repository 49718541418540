import React, { useState, useEffect } from "react";
import RolePanelComponent from "./rolePanel.component";
import RoleDialog from "../../dialog/newRoleDialog";
import { fetchRoleList } from "modules/role-access-right/redux";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { ROLE_ADD, ROLE_EDIT, ROLE_DELETE } from 'lib/constants/accessRights';
import { generatePaginationOptions } from "lib/helper";

function RolePanelContainer() {
  const currentUserRoleId = useSelector(state => state.profile.roleId);
  const hasRoleAddAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_ADD));
  const hasRoleEditAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_EDIT));
  const hasRoleDeleteAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_DELETE));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);
  const dispatch = useDispatch();

  const roleData = useSelector((state) => state.roleAccessRight.roleList.data);
  const totalRecords = useSelector(
    (state) => state.roleAccessRight.roleList.totalRecords
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchRoleList.typePrefix)
  );

  useEffect(() => {
    dispatch(
      fetchRoleList({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search
      })
    );
  }, [search, rowsPerPage, page, dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleReload = () => {
    dispatch(
      fetchRoleList({
        length: rowsPerPage,
        start: rowsPerPage * page,
        ...search
      })
    );
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleSearch = async (values) => {
    setPage(0);
    setSearch(values);
  };

  return (
    <>
      <RolePanelComponent
        currentUserRoleId={currentUserRoleId}
        hasRoleAddAccessRight={hasRoleAddAccessRight}
        hasRoleEditAccessRight={hasRoleEditAccessRight}
        hasRoleDeleteAccessRight={hasRoleDeleteAccessRight}
        data={roleData}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
      <RoleDialog
        handleReload={handleReload}
      />
    </>
  );
}

export default RolePanelContainer;
