import React, { useState } from "react";
import { makeStyles, Box, Button, Typography, TextField, MenuItem, Divider } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import defaultPhoto from "assets/img/default-profile-picture.png";
import googleLoginIcon from "assets/img/googleLoginIcon.png";
import facebookLoginIcon from "assets/img/facebookLoginIcon.png";
import lineLoginIcon from "assets/img/lineLoginIcon.png";
import appleLoginIcon from "assets/img/appleLoginIcon.png";
import zaloLoginIcon from "assets/img/zaloLoginIcon.png";
import { TablePaginationActions } from "components/tablePagination";
import { ActionButton } from "components/button";
import { Alert } from "@material-ui/lab";
import RefreshTable from "components/table/refreshTable";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/consumer/config/consumer.route";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import clsx from "clsx";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "12fr 3fr 3fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  onFocusBorder: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.secondary.main,
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
    width: "100%",
    alignItems: "center",
  },
  select: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px",
  },
}));

export default function ConsumerTableComponent({
  consumers,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  isLoadingConsumerError,
  handleReload,
  paginationOptions,
  handleEditLoyaltyPoint,
  lang,
  clearSearch,
  searchColumn,
  setSearchColumn,
  searchData,
  setSearchData,
  handleSubmit,
}) {
  const classes = useStyle()
  const history = useHistory()
  const [onFocus, setOnFocus] = useState(false);
  const columnDropdown = [
    { name: getLang(lang, "label.EMAIL"), value: 0 },
    { name: getLang(lang, "label.NAME"), value: 1 },
  ];

  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      <Box p={2} mb={2} className={classes.searchContainer}>
        <Box
          display="flex"
          className={clsx(classes.dropdownSearchBox, {
            [classes.onFocusBorder]: onFocus,
          })}
        >
          <TextField
            style={{ minWidth: "unset" }}
            className={classes.select}
            select
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  style: { padding: 0 },
                },
                style: { marginTop: 5 },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
            }}
            value={searchColumn}
            size="small"
            margin="none"
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.inputRoot },
            }}
          >
            {columnDropdown.map((column, index) => (
              <MenuItem
                className={classes.menuItemText}
                value={column.value}
                key={index}
                onClick={() => {
                  setSearchColumn(column.value);
                }}
              >
                {column.name}
              </MenuItem>
            ))}
          </TextField>
          <Divider orientation="vertical" className={classes.divider} />
          <TextField
            fullWidth={true}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
            size="small"
            margin="none"
            placeholder={getLang(lang, "placeholder.SEARCH")}
            InputProps={{
              disableUnderline: true,
              margin: "none",
            }}
            inputProps={{
              className: classes.textFieldInput,
            }}
            value={searchData}
            onChange={(event) => {
              setSearchData(event.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !isFetching)
              handleSubmit()
            }}
          />
        </Box>
        <Button
          variant="text"
          disableElevation
          color="primary"
          size="small"
          disabled={isFetching}
          onClick={() => {
            clearSearch()
          }}
        >
          {getLang(lang, "label.CLEAR")}
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          size="small"
          type="submit"
          disabled={isFetching}
          onClick={() => {
            handleSubmit()
          }}
        >
          {getLang(lang, "label.SEARCH")}
        </Button>
      </Box>
      {consumers.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="counterfeir report table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PROFILE_PICTURE")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.EMAIL")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.CONTACT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.GENDER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.DATE_OF_BIRTH")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LOYALTY_POINT_BALANCE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SOCIAL_MEDIA_LOGIN")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.JOINED_DATE")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow key={index} columnCount={11} />
                  ))
                : consumers.map((item, index) => {
                    const actionItems = [
                      {
                        label: getLang(lang, "label.VIEW_DETAILS"),
                        action: () => {
                          history.push(`${ROOT}/${item.id}`);
                        },
                      },
                      // {
                      //   label: getLang(lang, "label.EDIT_LOYALTY_POINT"),
                      //   action: () =>
                      //     handleEditLoyaltyPoint({ item }),
                      // }
                    ];

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StickyLeftTableCell>
                          <StyledTableCell>
                            {index + 1 + rowsPerPage * page}
                          </StyledTableCell>
                        </StickyLeftTableCell>
                        <StyledTableCell>
                          <img
                            src={item.profilePicture ?? defaultPhoto}
                            alt="profile"
                            style={{
                              width: 60,
                              height: 60,
                              objectFit: "contain",
                              borderRadius: "50%",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.firstName +
                            " " +
                            (item.lastName ? item.lastName : "")}
                        </StyledTableCell>
                        <StyledTableCell>{item.email}</StyledTableCell>
                        <StyledTableCell>
                          {item.phoneCode ? `${item.phoneCode} ` : ""}
                          {item.phoneNumber || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.gender
                            ? item.gender === "Male"
                              ? getLang(lang, "label.MALE")
                              : getLang(lang, "label.FEMALE")
                            : "-"}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </StyledTableCell>
                        <StyledTableCell>{item.dateOfBirth ? moment(item.dateOfBirth).format('ll') : "-"}</StyledTableCell>
                        <StyledTableCell>{(item.point || "0").toLocaleString("en-US")}</StyledTableCell>
                        <StyledTableCell align="center">
                          {!(item.googleId === null || item.googleId === "") ? (
                            <img
                              src={googleLoginIcon}
                              alt="Google Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={googleLoginIcon}
                              alt="Google Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                          {!(
                            item.facebookId === null || item.facebookId === ""
                          ) ? (
                            <img
                              src={facebookLoginIcon}
                              alt="Facebook Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={facebookLoginIcon}
                              alt="Facebook Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                          {!(item.lineId === null || item.lineId === "") ? (
                            <img
                              src={lineLoginIcon}
                              alt="Line Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={lineLoginIcon}
                              alt="Line Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                          {!(item.appleId === null || item.appleId === "") ? (
                            <img
                              src={appleLoginIcon}
                              alt="Apple Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                              }}
                            />
                          ) : (
                            <img
                              src={appleLoginIcon}
                              alt="Apple Login"
                              style={{
                                width: 36,
                                height: 36,
                                padding: 5,
                                opacity: 0.1,
                              }}
                            />
                          )}
                          {
                            item.zaloId !== undefined &&
                            (!(item.zaloId === null || item.zaloId === "") ? (
                              <img
                                src={zaloLoginIcon}
                                alt="Zalo Login"
                                style={{
                                  width: 36,
                                  height: 36,
                                  padding: 5,
                                }}
                              />
                            ) : (
                              <img
                                src={zaloLoginIcon}
                                alt="Zalo Login"
                                style={{
                                  width: 36,
                                  height: 36,
                                  padding: 5,
                                  opacity: 0.1,
                                }}
                              />
                            ))
                          }
                        </StyledTableCell>
                        <StyledTableCell>{moment(item.joinedDate).format('lll')}</StyledTableCell>
                        <StickyRightTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </div>
                          </StyledTableCell>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !search &&
        !isLoadingConsumerError && (
          <Box>
            <Table
              className={classes.table}
              size="small"
              aria-label="counterfeir report table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PROFILE_PICTURE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.EMAIL")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CONTACT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.GENDER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.DATE_OF_BIRTH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.LOYALTY_POINT_BALANCE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SOCIAL_MEDIA_LOGIN")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.JOINED_DATE")}
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
              <Typography variant="body1">
                {getLang(lang, "paragraph.NO_CONSUMER")}
              </Typography>
            </Box>
          </Box>
        )
      )}

      {!isFetching && consumers.length === 0 && search && (
        <Alert severity="info">
          {getLang(lang, "message.info.NO_RECORD_EXIST")}
        </Alert>
      )}

      {!isFetching && isLoadingConsumerError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && consumers.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  );
}
