import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import countries from "lib/constants/countries";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
  },
  option: {
    "& > span": {
      flex: 1,
    },
  },
  label: {
    fontSize: "smaller",
  },
  paper: {
    width: "200%",
  },
}));

const filterOptions = (type) =>
  createFilterOptions({
    stringify: (option) =>
      type === "phone"
        ? option.label + option.code + option.phone
        : option.code + option.label,
  });

export default function CountrySelectComponent({
  textFieldProps,
  formProps,
  type = "country",
  inputProps,
  lang,
}) {
  const classes = useStyle();
  return (
    <Autocomplete
      {...formProps}
      fullWidth={textFieldProps.fullWidth || false}
      options={countries}
      disableClearable
      noOptionsText={getLang(lang, "label.NO_RESULT_FOUND")}
      classes={{
        option: classes.option,
        paper: clsx({
          [classes.paper]: type === "phone",
        }),
        inputRoot: clsx({
          [classes.inputRoot]: textFieldProps.variant === "filled",
        }),
      }}
      autoHighlight
      filterOptions={filterOptions(type)}
      getOptionLabel={(option) =>
        type === "phone" ? `${option.code} ${option.phone}` : option.label
      }
      renderOption={(option) => (
        <React.Fragment>
          <span>
            {option.label} ({option.code})
          </span>{" "}
          {type === "countryOnly" || type === "country" ? null : option.phone}
        </React.Fragment>
      )}
      renderInput={(params) => {
        return (
          <TextField
            name={type === "phone" ? "phone code" : "country"}
            placeholder={
              type === "phone"
                ? getLang(lang, "placeholder.COUNTRY_CODE")
                : getLang(lang, "placeholder.COUNTRY")
            }
            {...params}
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                padding: "5px",
                borderRadius: "4px",
                backgroundColor:
                  type === "phone" || type === "countryOnly" ? "#ECEFF0" : null,
                ...textFieldProps.inputPropStyle,
              },
            }}
            label={
              type === "country" ? (
                <>
                  <span style={{ display: "inline-block", fontSize: "14px" }}>
                    {getLang(lang, "label.COUNTRY")}{" "}
                    {textFieldProps.required && (
                      <span
                        style={{ color: "#DE350B", display: "inline-block" }}
                      >
                        *
                      </span>
                    )}{" "}
                  </span>
                </>
              ) : null
            }
            inputProps={{
              ...params.inputProps,
              style: {
                ...inputProps,
                paddingLeft: "8px",
              },
              autoComplete: "off", // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
}
