import axios from "axios";

export default function getDispatchInstructionList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/dispatch-instruction/list", {
        length: parameters.length,
        start: parameters.start,
        filter: {
          search: parameters.filter.search,
          search_column: parameters.filter.search_column,
          products: parameters.filter.products,
          receiver_country: parameters.filter.receiver_country,
          status: parameters.filter.status,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
