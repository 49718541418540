import React, { useEffect, useRef, useState } from "react";
import { isFileExceedLimit, getUploadS3FilePath } from "lib/helper";
import { dataURLtoFile, isDataUrl } from "lib/generalUtility";
import { uploadFile, uploadJSON } from "app/feature/s3/s3.utility";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import BasicInfoFormComponent from "./basicInfoForm.component";
import { toBase64 } from "lib/FileHelper";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import DraftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import htmlToDraft from "html-to-draftjs";
import { ldState } from "modules/lucky-draw-v2/utils/constants";

const allowedImageExtension = [".jpeg", ".jpg", ".png", ".webp"];

export default function BasicInfoFormContainer({
  uuid,
  setNewUuid,
  initialValues,
  setCurrentStep,
  getLuckyDrawDetail,
  type,
  ldCurrentState,
  progress,
}) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dragging, setDragging] = useState(false);
  const lang = useSelector((state) => state.constant.languages);
  const companyId = useSelector((state) => state.account.id);
  const [enableGameRules, setEnableGameRules] = useState(true);

  const formRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (initialValues && formRef.current) {
      formRef.current.setValues({
        name: initialValues?.name ?? "",
        description: initialValues?.description ?? "",
        image: initialValues?.image ?? "",
        imagePath: "",
        gameRulesType: initialValues?.gameRulesType ?? "text",
        rules:
          initialValues?.gameRulesType === "text"
            ? transformHTMLToRaw(initialValues?.rules ?? "<p></p>")
            : EditorState.createEmpty(),
        gameRulesFile:
          initialValues?.gameRulesType === "file"
            ? initialValues?.rules ?? ""
            : "",
        gameRulesLink:
          initialValues?.gameRulesType === "link"
            ? initialValues?.rules ?? ""
            : "",
        tncType: initialValues?.tncType ?? "text",
        tnc:
          initialValues?.tncType === "text"
            ? transformHTMLToRaw(initialValues?.tnc ?? "<p></p>")
            : EditorState.createEmpty(),
        tncFile:
          initialValues?.tncType === "file" ? initialValues?.tnc ?? "" : "",
        tncLink:
          initialValues?.tncType === "link" ? initialValues?.tnc ?? "" : "",
        showConfirmDialog: false,
        isEditing: false,
      });
    }

    if (!initialValues?.gameRulesType && initialValues?.name) {
      setEnableGameRules(false);
    }
  }, [initialValues]);

  const transformHTMLToRaw = (html) => {
    const blocksFromHTML = htmlToDraft(html);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(content);
  };

  const handleDrop = (dropped) => {
    setDragging(false);
    const rawFile = dropped[0];
    if (!validImageFormat(rawFile.name)) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.INVALID_IMAGE_FORMAT"),
        })
      );
      return;
    }

    if (isFileExceedLimit(rawFile.size, 12, "MB")) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.FILE_TOO_LARGE"),
        })
      );
      return;
    }
    // const image = URL.createObjectURL(rawFile);
    // setBufferImg(image);
    // setCropping(true);
  };

  const validImageFormat = (file) => {
    for (let index = 0; index < allowedImageExtension.length; index++) {
      if (file.toLowerCase().indexOf(allowedImageExtension[index]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const uploadFileToS3 = async ({ uploadType, file, id, fileName }) => {
    const filePath = await getUploadS3FilePath({
      type: uploadType,
      id,
      fileName,
    });

    const result = await uploadFile({ filePath, file });

    if (!result.success) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.ERROR_UPLOAD_FILE"),
        })
      );
      return null;
    }

    return result.url;
  };

  const uploadLuckyDrawFile = (file, uploadType) => {
    if (file) {
      const termAndConditionFileName = file.name;
      return uploadFileToS3({
        fileName: `${Date.now()}_${termAndConditionFileName}`,
        file,
        id: companyId,
        uploadType,
      });
    }
    dispatch(
      addAlert({
        severity: "error",
        message: getLang(lang, "message.error.ERROR_UPLOAD_FILE"),
      })
    );
  };

  const uploadImageToS3 = async ({ uploadType, base64Image, id, fileName }) => {
    const filePath = await getUploadS3FilePath({
      type: uploadType,
      id,
      fileName,
    });

    const file = await dataURLtoFile(base64Image, filePath);
    const result = await uploadFile({ filePath, file });

    if (!result.success) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.FAILED_UPLOAD_IMAGE"),
        })
      );
      return null;
    }

    return result.url;
  };

  const uploadJSONToS3 = async ({ uploadType, data, fileName, id }) => {
    const filePath = await getUploadS3FilePath({
      type: uploadType,
      id,
      fileName,
    });

    const result = await uploadJSON({ fileName: filePath, data }).catch(
      () => {}
    );

    if (!result.success) {
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, "error.ERROR_UPLOAD_FILE"),
        })
      );
      return null;
    }

    return result.url;
  };

  const uploadLuckyDrawImage = (base64Image, imageName) => {
    if (base64Image && isDataUrl(base64Image)) {
      return uploadImageToS3({
        fileName: `${uuidv4()}_${imageName}`,
        base64Image,
        id: companyId,
        uploadType: "lucky_draw_image",
      });
    }
  };

  const uploadEditorStateJSON = (json, uploadType) => {
    if (Boolean(json)) {
      const termAndConditionFileName = "JsonFile";
      return uploadJSONToS3({
        uploadType,
        data: { value: json, data: [] },
        fileName: `${Date.now()}_${termAndConditionFileName}.json`,
        id: companyId,
      });
    }
    dispatch(
      addAlert({
        severity: "error",
        message: getApiLang(lang, "error.ERROR_UPLOAD_FILE"),
      })
    );
  };

  const handleDragEnter = () => {
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleSubmit = async (values) => {
    if (
      values.isEditing &&
      (!ldCurrentState ||
        (ldCurrentState !== ldState.ended &&
          ldCurrentState !== ldState.drawStart))
    ) {
      setIsSubmitting(true);

      const gameRulesContent = DraftToHtml(
        convertToRaw(values.rules.getCurrentContent())
      );
      if (
        enableGameRules &&
        ((values.gameRulesType === "text" &&
          (!gameRulesContent || gameRulesContent === "<p></p>\n")) ||
          (values.gameRulesType === "file" && !values.gameRulesFile) ||
          (values.gameRulesType === "link" && !values.gameRulesLink))
      ) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.EMPTY_GAME_RULE"),
          })
        );
        setIsSubmitting(false);
        return;
      }
      const tncContent = DraftToHtml(
        convertToRaw(values.tnc.getCurrentContent())
      );
      if (
        (values.tncType === "text" &&
          (!tncContent || tncContent === "<p></p>\n")) ||
        (values.tncType === "file" && !values.tncFile) ||
        (values.tncType === "link" && !values.tncLink)
      ) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.EMPTY_TNC"),
          })
        );
        setIsSubmitting(false);
        return;
      }

      const base64Image =
        Boolean(values.image) && typeof values.image === "string"
          ? null
          : await toBase64(values.image);
      const imageUrl = Boolean(base64Image)
        ? await uploadLuckyDrawImage(base64Image, values.image.name)
        : values.image;
      const gameRulesUrl = enableGameRules
        ? values.gameRulesType === "link"
          ? values.gameRulesLink
          : values.gameRulesType === "text"
          ? await uploadEditorStateJSON(gameRulesContent, "luckyDrawRules")
          : Boolean(values.gameRulesFile) &&
            typeof values.gameRulesFile === "string"
          ? values.gameRulesFile
          : await uploadLuckyDrawFile(values.gameRulesFile, "luckyDrawRules")
        : null;
      const tnc =
        values.tncType === "link"
          ? values.tncLink
          : values.tncType === "text"
          ? await uploadEditorStateJSON(tncContent, "luckyDrawTermAndCondition")
          : Boolean(values.tncFile) && typeof values.tncFile === "string"
          ? values.tncFile
          : await uploadLuckyDrawFile(
              values.tncFile,
              "luckyDrawTermAndCondition"
            );
      const body = {
        type: type,
        luckyDrawUuid: uuid,
        title: values.name,
        description: values.description,
        imageUrl: imageUrl,
        gameRulesUrl,
        gameRulesType: gameRulesUrl ? values.gameRulesType : null,
        tnc,
        tncType: values.tncType,
      };

      const response = Boolean(uuid)
        ? luckyDrawV2Api.updateLuckyDraw(body)
        : luckyDrawV2Api.createLuckyDraw(body);

      response
        .then((res) => {
          let tempUuid = null;
          if (Boolean(res.data.data)) {
            tempUuid = res.data.data.uuid;
            setNewUuid(tempUuid);
          }

          getLuckyDrawDetail(tempUuid || uuid);

          const params = new URLSearchParams(window.location.search);
          if (!params.get("lucky_draw") && (tempUuid || uuid)) {
            params.set("lucky_draw", tempUuid || uuid);
          }
          params.set("step", values.goToPreview ? (type === 2 ? 6 : 5) : 2);
          window.history.replaceState(null, null, `?${params}`);

          setCurrentStep(values.goToPreview ? (type === 2 ? 5 : 4) : 1);
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, error.code),
            })
          );
        })
        .finally(() => {
          if (isMounted.current) {
            setIsSubmitting(false);
          }
        });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.set("step", values.goToPreview ? (type === 2 ? 6 : 5) : 2);
      window.history.replaceState(null, null, `?${params}`);

      setCurrentStep(values.goToPreview ? (type === 2 ? 5 : 4) : 1);
    }
  };

  const handleGameRulesChange = (event) => {
    setEnableGameRules(event.target.checked);
  };

  return (
    <BasicInfoFormComponent
      initialValues={initialValues}
      handleDrop={handleDrop}
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      dragging={dragging}
      lang={lang}
      handleSubmitForm={handleSubmit}
      isSubmitting={isSubmitting}
      formRef={formRef}
      uuid={uuid}
      type={type}
      ldCurrentState={ldCurrentState}
      progress={progress}
      enableGameRules={enableGameRules}
      handleGameRulesChange={handleGameRulesChange}
    />
  );
}
