import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Link,
} from "@material-ui/core";
import { TablePaginationActions } from "components/tablePagination";
import { StyledTableCell, StyledTableRow } from "components/table";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { ROOT } from "modules/group/config/group.route";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  chip: {
    backgroundColor: "#E4E7EC",
    padding: "2px 8px",
    borderRadius: 16,
    marginRight: 8,
  },
}));

export default function GroupListTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  hasGroupAddAccessRight,
  hasGroupEditAccessRight,
  rowsPerPage,
  page,
  isSearchResult,
  totalFiltered,
  handlePageChange,
  handleRowPerPageChange,
  paginationOptions,
  handleViewDetail,
  lang,
  openCreateDialog,
  handleDeleteGroup,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <Box px={2}>{getLang(lang, "label.GROUP_NAME")}</Box>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH_COUNT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCHES")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ROLE")}
                  </StyledTableCell>
                  <StickyRightTableCell style={{ textAlign: "center" }}>
                    <Box px={2}>{getLang(lang, "label.ACTION")}</Box>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={5}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_EDIT"),
                        action: () => {
                          handleViewDetail(`${ROOT}/${row.uuid}`);
                        },
                      });

                      if (!row.is_default) {
                        actionItems.push({
                          label: getLang(lang, "label.DELETE"),
                          divider: true,
                          errorText: true,
                          action: () => {
                            handleDeleteGroup(row);
                          },
                          disabled: !hasGroupEditAccessRight,
                        });
                      }

                      return (
                        <StyledTableRow
                          key={row.uuid}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            <Link
                              href={`${ROOT}/${row.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(`${ROOT}/${row.uuid}`);
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography variant="caption">
                                {row.name}
                              </Typography>
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>{row.branch_count}</span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex">
                              {row.branches.length ? (
                                <>
                                  {row.branches?.slice(0, 3).map((b, i) => (
                                    <Box className={classes.chip} key={i}>
                                      {b.name}
                                    </Box>
                                  ))}
                                  {row.branch_count > 3 && (
                                    <Box className={classes.chip}>
                                      + {row.branch_count - 3}
                                    </Box>
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>{row.role ? row.role.name : "-"}</span>
                          </StyledTableCell>
                          <StickyRightTableCell style={{ textAlign: "center" }}>
                            <Box px={2}>
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </Box>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowPerPageChange}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from.toLocaleString("en-US")}-${to.toLocaleString(
                "en-US"
              )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
            }
          />
        </>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <Box px={2}>{getLang(lang, "label.GROUP_NAME")}</Box>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BRANCH_COUNT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BRANCHES")}
                </StyledTableCell>
                <StickyRightTableCell style={{ textAlign: "center" }}>
                  <Box px={2}>{getLang(lang, "label.ACTION")}</Box>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {isSearchResult
                ? getLang(lang, "paragraph.NO_BRANCH_GROUP_EXIST")
                : getLang(lang, "paragraph.YOU_DONT_HAVE_BRANCH_GROUP")}
            </Typography>
            {hasGroupAddAccessRight ? (
              <Box mt={2}>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={() => {
                    openCreateDialog();
                  }}
                >
                  {getLang(lang, "label.ADD_BRANCH_GROUP")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
}
