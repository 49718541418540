import React, { useEffect } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Button,
} from "@material-ui/core";
import SetWinnerSelect from "../../select/setWinnerSelect";
import { ErrorSVG, DustBinSVG } from "../../../utils/static";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import { PRIZE_TYPE_PRESET } from "modules/lucky-draw-v2/constants";
const style = (theme) => ({
  prizeDialog: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    borderTop: "1px solid rgba(58, 77, 84, 0.1)",
    borderBottom: "none",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th:last-child, td:last-child": {
      borderRight: "none",
    },
    "& th,td": {
      fontSize: ".75rem",
      padding: "10px 16px 10px 16px",
      backgroundColor: theme.palette.common.white,
      borderRight: "solid 1px rgba(0, 0, 0, 0.1)",
    },
  },
  prizeTable: {
    border: "1px solid rgba(58, 77, 84, 0.1)",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    placeContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
});

const useStyles = makeStyles(style);

export default function SetWinnerTableComponent({
  luckyDrawUuid,
  hasLuckyDrawEditAccessRight,
  dialog,
  quantity,
  winners,
  handleChange,
  balance,
  handleDelete,
  handleAddWinner,
  handleAddWinnerIntoPrize,
  lang,
  prize,
  excludeList,
  isEditable,
  isUnlimited,
}) {
  const classes = useStyles();

  let count = 1;
  // let availableBalance = winners.filter(winner => winner.status === null).length
  const [availableBalance, setAvailableBalance] = React.useState(0);
  const fakeArray = [];
  for (let index = 0; index < quantity; index++) {
    fakeArray.push(index);
  }

  useEffect(() => {
    if (!isUnlimited) {
      setAvailableBalance(prize.quantity - winners.length);
    }
  }, [winners, prize, isUnlimited]);

  return (
    <Box
      className={clsx({
        [classes.prizeDialog]: false,
      })}
    >
      {fakeArray.length > 0 ? (
        <React.Fragment>
          <Typography
            variant="body2"
            color="primary"
            style={{ padding: "8px 16px" }}
          >
            {getLang(lang, "label.PRESET_WINNER_LIST")}
          </Typography>
          <Box>
            <TableContainer>
              <Table
                className={clsx(classes.table, {
                  [classes.prizeTable]: false,
                })}
                size="small"
                aria-label="product table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{getLang(lang, "label.NUM")}</TableCell>
                    <TableCell>
                      {getLang(lang, "label.SERIAL_NUMBER")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fakeArray.map((row, index) => {
                    let winner = winners.filter(
                      (v) => Number(v.prize_type) === PRIZE_TYPE_PRESET
                    )[index];
                    return (
                      Number(winner.prize_type) === PRIZE_TYPE_PRESET && (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell component="th" scope="row">
                            {Number(winner.prize_type) === PRIZE_TYPE_PRESET &&
                              count++}
                          </TableCell>
                          <TableCell>
                            {hasLuckyDrawEditAccessRight &&
                            (winner.status === null ||
                              winner.status === undefined) ? (
                              <div style={{ display: "flex" }}>
                                <SetWinnerSelect
                                  luckyDrawUuid={luckyDrawUuid}
                                  currentWinnerList={[]}
                                  value={winner}
                                  handleChange={(value, enc) =>
                                    handleAddWinnerIntoPrize(index, value, enc)
                                  }
                                  excludeList={excludeList}
                                  disabled={!isEditable}
                                />
                                {isEditable && !winner.status && (
                                  <IconButton
                                    style={{ padding: 4, marginLeft: 8 }}
                                    onClick={() => handleDelete(index)}
                                  >
                                    <DustBinSVG />
                                  </IconButton>
                                )}
                              </div>
                            ) : winner ? (
                              winner.serialNumber
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </React.Fragment>
      ) : fakeArray.length === 0 ? (
        <Box>
          <Table
            className={clsx(classes.table, {
              [classes.prizeTable]: false,
            })}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>{getLang(lang, "label.NUM")}</TableCell>
                <TableCell>{getLang(lang, "label.SERIAL_NUMBER")}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={6} className={classes.emptyContainer}>
            <ErrorSVG />
            <Typography variant="body2" style={{ paddingLeft: "4px" }}>
              {getLang(lang, "paragraph.NO_PRESET_WINNER_ADDED")}
            </Typography>
          </Box>
        </Box>
      ) : null}
      {isEditable && hasLuckyDrawEditAccessRight ? (
        <Button
          variant="contained"
          color="primary"
          disabled={0 >= availableBalance && !isUnlimited}
          onClick={handleAddWinner}
          style={{ margin: "8px 16px" }}
        >
          {getLang(lang, "label.ADD_WINNER")}
        </Button>
      ) : null}
    </Box>
  );
}
