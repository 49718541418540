import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Formik, setNestedObjectValues } from "formik";
import languages from "lib/constants/languages";
import * as Yup from "yup";
import { getLang } from "app/feature/constants";
import mapValues from "lodash/mapValues";
import GeneralDialog from "components/dialog/generalDialog";
import AlertSvgIcon from "assets/svg/alert.svg";
import DeleteSvgIcon from "assets/svg/delete.svg";
import { Remove as RemoveIcon, Add as AddIcon } from "@material-ui/icons";
import AdditionalInfoIcon from "assets/img/additionalinfoIcon.png";
import CustomTab from "components/tab";
import AdditionalInfoAccordion from "modules/serial-number/components/accordion/additionalInfoAccordion";
import { ROOT } from "modules/serial-number/config/serial-number.route";
import { useHistory } from "react-router";
import AdtInfoImportProgress from "modules/serial-number/components/progress/adtInfoImportProgress";

const useStyle = makeStyles((theme) => ({
  panel: {
    "& .MuiAccordionSummary-root.Mui-focused": {
      backgroundColor: "#3A4D54 !important",
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
  button: {
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
    borderRadius: 4,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    padding: 0,
    height: 38,
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: 0,
    },
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
}));

export default function AddSnAdtInfoComponent({
  lang,
  validate,
  languageTabIndex,
  setLanguageTabIndex,
  handleSubmit,
  isUpdating,
  pendingProcess,
  handleAddLang,
  handleRemoveLang,
  anchorEl,
  handleCloseAddLang,
  handleAddLangClick,
}) {
  const classes = useStyle();
  const history = useHistory();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <>
      {!!pendingProcess && (
        <Box pb={1.5}>
          <AdtInfoImportProgress />
        </Box>
      )}
      <Paper
        className={classes.panel}
        elevation={3}
        style={{ padding: "24px 16px" }}
      >
        <Formik
          initialValues={{
            selection: 1,
            additionalInfo: [
              {
                title: null,
                format: 1,
                ranges: [
                  {
                    from: "",
                    to: "",
                    value: { EN: "" },
                  },
                ],
              },
            ],
            selectedDeleteIndex: null,
            showSaveConfirm: false,
            usedLang: [languages.find((lang) => lang.code === "EN")],
            selectedTitleIds: [],
            invalidRangeError: {},
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            additionalInfo: Yup.array().of(
              Yup.lazy((parent) => {
                return Yup.object({
                  ranges: Yup.array().of(
                    Yup.object().shape({
                      from: Yup.mixed().required(
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                      ),
                      to: Yup.mixed(),
                      value: Yup.lazy((obj) => {
                        switch (parent.format) {
                          case 1:
                            return Yup.object(
                              mapValues(obj, (value, key) => {
                                if (key === "EN")
                                  return Yup.string().required(
                                    getLang(
                                      lang,
                                      "message.error.FORM_VALIDATE_REQUIRED"
                                    )
                                  );
                                else return Yup.string();
                              })
                            ).required(
                              getLang(
                                lang,
                                "message.error.FORM_VALIDATE_REQUIRED"
                              )
                            );
                          case 2:
                            return Yup.string().required(
                              getLang(
                                lang,
                                "message.error.FORM_VALIDATE_REQUIRED"
                              )
                            );
                          case 3:
                            return Yup.number().required(
                              getLang(
                                lang,
                                "message.error.FORM_VALIDATE_REQUIRED"
                              )
                            );
                          case 4:
                            return Yup.string()
                              .matches(
                                /^((https?):\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})(\/[^\s]*)?(\?[a-zA-Z0-9=&#+%-]*)?$/i,
                                getLang(lang, "message.error.INVALID_URL")
                              )
                              .required(
                                getLang(
                                  lang,
                                  "message.error.FORM_VALIDATE_REQUIRED"
                                )
                              );
                          default:
                            return Yup.mixed().required(
                              getLang(
                                lang,
                                "message.error.FORM_VALIDATE_REQUIRED"
                              )
                            );
                        }
                      }),
                    })
                  ),
                });
              })
            ),
          })}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} id="additional_form">
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Box className={classes.button} p={1} mr={1}>
                      <img
                        src={AdditionalInfoIcon}
                        className={classes.icon}
                        alt="info"
                      />
                    </Box>
                    <Typography variant="h6">
                      <b>
                        {getLang(lang, "label.ADDITIONAL_INFORMATION_BETA")}
                      </b>
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => {
                      let temp = [...formik.values.additionalInfo];
                      temp.push({
                        title: null,
                        format: 1,
                        ranges: [
                          {
                            from: "",
                            to: "",
                            value: { EN: "" },
                          },
                        ],
                      });

                      formik.setFieldValue("additionalInfo", temp);
                    }}
                  >
                    <AddIcon style={{ fontSize: 20, marginRight: 8 }} />
                    {getLang(lang, "label.ADDITIONAL_INFORMATION")}
                  </Button>
                </Box>
                <Box mt={3}>
                  <Typography vairnat="body1">
                    <b>
                      {getLang(lang, "label.ADD_ADDITIONAL_INFORMATION_BY")}:
                    </b>
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formik.values.selection}
                      onChange={({ target: { value } }) => {
                        if (formik.values.selection !== parseInt(value)) {
                          formik.setFieldValue("additionalInfo", [
                            {
                              title: null,
                              format: 1,
                              ranges: [
                                {
                                  from: "",
                                  to: "",
                                  value: { EN: "" },
                                },
                              ],
                            },
                          ]);
                          formik.setFieldValue("selection", parseInt(value));
                          formik.setFieldValue("selectedTitleIds", []);
                          formik.setFieldValue("invalidRangeError", {});
                        }
                      }}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={
                          <Typography
                            variant="body1"
                            style={{ marginRight: 4 }}
                          >
                            {getLang(lang, "label.SERIAL_NUMBER")}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label={
                          <Typography
                            variant="body1"
                            style={{ marginRight: 4 }}
                          >
                            {getLang(lang, "label.SEQUENCE_NUMBER")}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                {!!formik.values.additionalInfo.length && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Tabs
                      value={languageTabIndex}
                      onChange={(event, index) => {
                        setLanguageTabIndex(index);
                      }}
                      aria-label="scrollable auto tabs example"
                      textColor="primary"
                      variant="scrollable"
                      indicatorColor="primary"
                      classes={{
                        indicator: classes.indicator,
                        scrollButtons: classes.scroll,
                      }}
                      style={{ flex: 1 }}
                    >
                      {formik.values.usedLang.map((item, index) => {
                        return (
                          <CustomTab
                            key={index}
                            {...a11yProps(index, item.label)}
                          />
                        );
                      })}
                    </Tabs>
                    <Box display="flex" alignItems="center" ml={2}>
                      <Box>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleRemoveLang(formik);
                          }}
                          disabled={
                            formik.values.usedLang.length <= 0 ||
                            formik.values.usedLang[languageTabIndex]?.code ===
                              "EN" ||
                            isUpdating
                          }
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                      <Box ml={0.5}>
                        <IconButton
                          size="small"
                          onClick={handleAddLangClick}
                          disabled={
                            languages.length - formik.values.usedLang.length ===
                              0 || isUpdating
                          }
                        >
                          <AddIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseAddLang}
                        >
                          {languages
                            .filter(
                              (language) =>
                                !formik.values.usedLang.some(
                                  (used) => used.code === language.code
                                )
                            )
                            .map((language, index) => (
                              <MenuItem
                                onClick={() => {
                                  handleAddLang(language, formik);
                                  handleCloseAddLang();
                                }}
                                key={index}
                              >
                                {language.label}
                              </MenuItem>
                            ))}
                        </Menu>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Divider />
                <Box maxHeight={800} style={{ overflowY: "auto" }} mt={1}>
                  {formik.values.additionalInfo.length ? (
                    formik.values.additionalInfo.map((info, i) => (
                      <AdditionalInfoAccordion
                        key={i}
                        info={info}
                        adtInfoIdx={i}
                        invalidRangeError={formik.values.invalidRangeError}
                        setInvalidRangeError={(val) =>
                          formik.setFieldValue("invalidRangeError", val)
                        }
                        isUpdating={isUpdating}
                        formik={formik}
                        currentLang={
                          formik.values.usedLang[languageTabIndex]?.code ?? "EN"
                        }
                      />
                    ))
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      mb={4}
                      mt={2}
                      mx={2}
                    >
                      <Box mb={1}>
                        <Typography variant="body1" align="center">
                          {getLang(lang, "paragraph.NO_ADDITIONAL_INFO_YET")}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let temp = [...formik.values.additionalInfo];
                          temp.push({
                            title: null,
                            format: 1,
                            ranges: [
                              {
                                from: "",
                                to: "",
                                value: { EN: "" },
                              },
                            ],
                          });

                          formik.setFieldValue("additionalInfo", temp);
                        }}
                        style={{ color: "white" }}
                      >
                        <AddIcon
                          style={{
                            fontSize: 20,
                            marginRight: 8,
                          }}
                        />
                        {getLang(lang, "label.ADDITIONAL_INFORMATION")}
                      </Button>
                    </Box>
                  )}
                  <Box display="flex" alignItems="center" mt={2}>
                    <Divider style={{ flex: 1, marginRight: 16 }} />
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        let temp = [...formik.values.additionalInfo];
                        temp.push({
                          title: null,
                          format: 1,
                          ranges: [
                            {
                              from: "",
                              to: "",
                              value: { EN: "" },
                            },
                          ],
                        });

                        formik.setFieldValue("additionalInfo", temp);
                      }}
                    >
                      <AddIcon style={{ fontSize: 20, marginRight: 8 }} />
                      {getLang(lang, "label.ADDITIONAL_INFORMATION")}
                    </Button>
                    <Divider style={{ flex: 1, marginLeft: 16 }} />
                  </Box>
                </Box>
              </>
              {!!formik.values.additionalInfo.length && (
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={() => {
                      history.push(`${ROOT}?tab=adt-info`);
                    }}
                    style={{ marginRight: "8px" }}
                    disabled={isUpdating}
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    type="button"
                    onClick={async () => {
                      const errors = await formik.validateForm();
                      formik.setTouched(setNestedObjectValues(errors, true));

                      let valid = validate(formik);

                      if (valid && Object.keys(errors).length === 0) {
                        formik.setFieldValue("showSaveConfirm", true);
                        formik.setFieldValue("invalidRangeError", {});
                      }
                    }}
                    disabled={
                      !!Object.keys(formik.values.invalidRangeError).length ||
                      isUpdating ||
                      !!pendingProcess
                    }
                    startIcon={
                      isUpdating ? (
                        <CircularProgress style={{ width: 10, height: 10 }} />
                      ) : null
                    }
                  >
                    {getLang(lang, "label.SAVE")}
                  </Button>
                </Box>
              )}

              <GeneralDialog
                isOpen={formik.values.selectedDeleteIndex != null}
                handleClose={() => {
                  formik.setFieldValue("selectedDeleteIndex", null);
                }}
                handleProceed={() => {
                  let temp = [...formik.values.additionalInfo];

                  let tempInfo = temp[formik.values.selectedDeleteIndex]?.title;
                  temp.splice(formik.values.selectedDeleteIndex, 1);
                  if (!temp.length) {
                    temp.push({
                      title: null,
                      format: 1,
                      ranges: [
                        {
                          from: "",
                          to: "",
                          value: { EN: "" },
                        },
                      ],
                    });
                  }
                  formik.setFieldValue("additionalInfo", temp);
                  formik.setFieldValue("selectedDeleteIndex", null);

                  if (tempInfo) {
                    let ids = [...formik.values.selectedTitleIds];
                    if (ids.includes(tempInfo.id)) {
                      ids = ids.filter((i) => i !== tempInfo.id);
                    }
                    formik.setFieldValue("selectedTitleIds", ids);
                  }
                }}
                title={`${getLang(lang, "label.DELETE")} ${
                  formik.values.additionalInfo[
                    formik.values.selectedDeleteIndex ?? 0
                  ]?.title
                    ? Object.values(
                        formik.values.additionalInfo[
                          formik.values.selectedDeleteIndex ?? 0
                        ]?.title
                      )[0] ?? ""
                    : ""
                }`}
                description={getLang(
                  lang,
                  "paragraph.ARE_YOU_SURE_WANT_DELETE"
                )}
                type="danger"
                proceedLabel={getLang(lang, "label.DELETE")}
                icon={<img src={DeleteSvgIcon} alt="" />}
              />
              <GeneralDialog
                isOpen={!!formik.values.showSaveConfirm}
                handleClose={() => {
                  formik.setFieldValue("showSaveConfirm", false);
                }}
                handleProceed={() => {
                  formik.setFieldValue("showSaveConfirm", false);
                  formik.handleSubmit();
                }}
                title={getLang(lang, "label.SAVE_ADDITIONAL_INFO")}
                description={getLang(
                  lang,
                  "paragraph.SAVE_ADDITIONAL_INFO_QUESTION"
                )}
                type="success"
                proceedLabel={getLang(lang, "label.SAVE")}
                icon={<img src={AlertSvgIcon} alt="" />}
              />
            </form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
