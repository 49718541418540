import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  RadioGroup,
  InputAdornment,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import InputHelper from "lib/InputHelper";

const useStyle = makeStyles((theme) => ({
  inputContainer: {
    "&:not(:last-child)": {
      marginBottom: "20px",
    },
    padding: "20px",
  },
  inputLabel: {
    fontSize: "16px",
    color: "#101828",
  },
  inputSubLabel: {
    fontSize: "14px",
    color: "#98A2B3",
  },
  inputSecondarySubLabel: {
    fontSize: "14px",
    color: "#101828",
  },
  inputEndormentLabel: {
    fontSize: "14px",
    color: "#101828",
  },
}));

export default function StockModuleSettingCardComponent({
  attribute,
  isEditable,
  updateFormikValue,
  attributeValue,
  formik,
  isSubmitting
}) {
  const classes = useStyle();

  return (
    <Paper className={classes.inputContainer} elevation={2}>
      <Typography className={classes.inputLabel}>{attribute.label}</Typography>
      {attribute.subLabel && (
        <Typography className={classes.inputSubLabel}>
          {attribute.subLabel}
        </Typography>
      )}
      {attribute.secondarySubLabel && (
        <Typography className={classes.inputSecondarySubLabel}>
          {attribute.secondarySubLabel}
        </Typography>
      )}
      {attribute.type === "number" && (
        <InputTextField
          value={attributeValue}
          type={attribute.type}
          disabled={!isEditable || isSubmitting}
          onPaste={(e) =>
            InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)
          }
          onKeyDown={(e) =>
            InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)
          }
          onChange={(e) =>
            updateFormikValue(
              attribute.fieldName,
              attribute.valuesType === "number"
                ? parseInt(e.target.value)
                : e.target.value
            )
          }
          InputProps={{
            endAdornment: attribute.endAdornment && (
              <InputAdornment position="end">
                <Box px={1} borderLeft="1px solid rgba(0, 0, 0, 0.1)">
                  <Typography className={classes.inputEndormentLabel}>
                    {attribute.endAdornment}
                  </Typography>
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}
      {attribute.type === "radio" && (
        <RadioGroup
          name={attribute.fieldName}
          defaultValue={attributeValue}
          value={attributeValue}
          onChange={(e) =>
            updateFormikValue(
              attribute.fieldName,
              attribute.valuesType === "number"
                ? parseInt(e.target.value)
                : e.target.value
            )
          }
        >
          {attribute.values.map((value, index) => (
            <Box key={index}>
                  <FormControlLabel
                    disabled={!isEditable || isSubmitting}
                    label={value.label}
                    value={value.value}
                    control={<Radio />}
                  />
                  {value.enableCustomField && (
                    <Box paddingLeft={4}>
                      <InputTextField
                        disabled={
                          !isEditable ||
                          attributeValue !== value.value ||
                          isSubmitting
                        }
                        value={formik.values[value.customFieldFieldName]}
                        type={value.customFieldType}
                        onPaste={(e) =>
                          InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(
                            e
                          )
                        }
                        onKeyDown={(e) =>
                          InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(
                            e
                          )
                        }
                        onChange={(e) =>
                          updateFormikValue(
                            value.customFieldFieldName,
                            e.target.value
                          )
                        }
                        InputProps={{
                          endAdornment: value.customFieldEndAdornment && (
                            <InputAdornment position="end">
                              <Box
                                px={1}
                                borderLeft="1px solid rgba(0, 0, 0, 0.1)"
                              >
                                <Typography
                                  className={classes.inputEndormentLabel}
                                >
                                  {value.customFieldEndAdornment}
                                </Typography>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          min: 1,
                        }}
                      />
                    </Box>
                  )}
            </Box>
          ))}
        </RadioGroup>
      )}
    </Paper>
  );
}
