import axios from "axios";

export default function submitToFinance({
  master_list_uuids,
  is_select_all,
  role_id,
}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/distributor/admin/api/v1/master-list/submit-finance-approval`, {
        master_list_uuids,
        is_select_all,
        role_id,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
