import React from "react";
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  TextFields as TextFieldsIcon,
  DateRangeOutlined as DateIcon,
  Filter1 as NumberIcon,
  Link as LinkIcon,
} from "@material-ui/icons";
import CopyIcon from "assets/img/copyIcon.png";
import clsx from "clsx";
import InfoRangeListTable from "../../table/infoRangeListTable";
import AdditionalFieldTitleWithLangSelect from "../../select/additionalFieldTitleWithLangSelect";

const useStyle = makeStyles((theme) => ({
  panel: {
    "& .MuiAccordionSummary-root.Mui-focused": {
      backgroundColor: "#3A4D54 !important",
    },
  },
  squareButton: {
    height: 45,
    width: 45,
    minWidth: "fit-content",
    padding: 0,
    backgroundColor: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  accordionHeader: {
    backgroundColor: "#3A4D54",
    borderRadius: 4,
    padding: "0px 16px",
  },
  greyText: {
    color: "#98A2B3",
  },
  icon: {
    height: 20,
    width: 20,
  },
  button: {
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
    borderRadius: 4,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  selectedButton: {
    border: "2px solid #6AAF68",
  },
  headerInputRow: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
    width: "100%",
    height: 42,
  },
}));

export default function AdditionalInfoAccordionComponent({
  lang,
  onBlur,
  invalidRangeError,
  updateRow,
  isUpdating,
  formik,
  info,
  adtInfoIdx,
  currentLang,
  setInvalidRangeError,
}) {
  const classes = useStyle();

  const updateValue = (formik, val, name, rangeIdx, infoIdx) => {
    let temp = [...formik.values.additionalInfo];
    let ranges = temp[infoIdx].ranges;
    ranges[rangeIdx] = {
      ...ranges[rangeIdx],
      [name]: val,
    };
    temp[infoIdx] = {
      ...temp[infoIdx],
      ranges,
    };
    formik.setFieldValue("additionalInfo", temp);
  };

  return (
    <Accordion defaultExpanded={true} style={{ marginBottom: 16 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls={`panel${adtInfoIdx + 1}a-content`}
        id={`panel${adtInfoIdx + 1}a-header`}
        className={classes.accordionHeader}
      >
        <Box
          my={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          <Box
            display="flex"
            alignItems="center"
            className={classes.headerInputRow}
          >
            <AdditionalFieldTitleWithLangSelect
              value={info.title}
              handleChange={(field) => {
                let temp = [...formik.values.additionalInfo];
                temp[adtInfoIdx] = {
                  ...temp[adtInfoIdx],
                  title: field,
                  format: field.format,
                  ranges: [
                    {
                      from: "",
                      to: "",
                      value: field.format === 1 ? { EN: "" } : "",
                    },
                  ],
                };
                formik.setFieldValue("additionalInfo", temp);

                let invalidRange = { ...invalidRangeError };
                delete invalidRange[adtInfoIdx];
                setInvalidRangeError(invalidRange);

                let ids = [...formik.values.selectedTitleIds];
                if (!ids.includes(field.id)) {
                  ids.push(field.id);
                }

                if (info.title && info.title.id !== field.id) {
                  let idx = ids.indexOf(info.title.id);
                  if (idx > -1) {
                    ids.splice(idx, 1);
                  }
                }
                formik.setFieldValue("selectedTitleIds", ids);
              }}
              placeholder={getLang(lang, "label.TITLE")}
              style={{ height: 38, flex: 1 }}
              userLang={currentLang}
              selectedIds={formik.values.selectedTitleIds}
            />
          </Box>

          <Box display="flex" alignItems="center" ml={2}>
            <Button
              variant="outlined"
              className={classes.squareButton}
              color="primary"
              disableElevation
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                let temp = [...formik.values.additionalInfo];
                temp.push({
                  ...temp[adtInfoIdx],
                  uuid: "",
                  title: null,
                  ranges: temp[adtInfoIdx].ranges.map((r) => ({
                    ...r,
                    uuid: "",
                  })),
                });
                formik.setFieldValue("additionalInfo", temp);
              }}
              disabled={isUpdating}
            >
              <img src={CopyIcon} alt="copy" className={classes.icon} />
            </Button>
            <Button
              variant="outlined"
              className={classes.squareButton}
              disableElevation
              color="primary"
              type="button"
              style={{ marginLeft: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                formik.setFieldValue("selectedDeleteIndex", adtInfoIdx);
              }}
              disabled={isUpdating}
            >
              <DeleteIcon size={18} style={{ color: "#F04438" }} />
            </Button>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box p={2} display="flex" flexDirection="column">
          {info.title && (
            <>
              <Box>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.FORMAT")}</b>
                </Typography>

                <Box display="flex" alignItems="center">
                  {info.title.format === 1 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <TextFieldsIcon />
                      <Box ml={1}>
                        <Typography>{getLang(lang, "label.TEXT")}</Typography>
                      </Box>
                    </Button>
                  )}
                  {info.title.format === 2 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <DateIcon />
                      <Box ml={1}>
                        <Typography>{getLang(lang, "label.DATE")}</Typography>
                      </Box>
                    </Button>
                  )}
                  {info.title.format === 3 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <NumberIcon />
                      <Box ml={1}>
                        <Typography>{getLang(lang, "label.NUMBER")}</Typography>
                      </Box>
                    </Button>
                  )}
                  {info.title.format === 4 && (
                    <Button
                      className={clsx(classes.button, classes.selectedButton)}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <LinkIcon />
                      <Box ml={1}>
                        <Typography>
                          {getLang(lang, "label.URL_LINK")}
                        </Typography>
                      </Box>
                    </Button>
                  )}
                </Box>
              </Box>
              <Box my={2}>
                <Divider />
              </Box>
            </>
          )}

          <Box>
            <Box mb={1}>
              <Typography variant="body1">
                <b>{getLang(lang, "label.RANGE")}</b>
              </Typography>
            </Box>
            <InfoRangeListTable
              disabled={isUpdating}
              rowIndex={adtInfoIdx}
              handleUpdate={(val, name, index) => {
                let temp = [...formik.values.additionalInfo];
                let ranges = temp[adtInfoIdx].ranges;
                ranges[index] = {
                  ...ranges[index],
                  [name]: val,
                };
                temp[adtInfoIdx] = {
                  ...temp[adtInfoIdx],
                  ranges,
                };
                formik.setFieldValue("additionalInfo", temp);
              }}
              format={info.format}
              formik={formik}
              rows={info.ranges}
              type={formik.values.selection}
              updateRow={(action, indexToUpdate) => {
                updateRow(action, indexToUpdate, adtInfoIdx, formik);
              }}
              onBlur={(name, rangeIdx, value, range) => {
                onBlur(
                  formik.values.selection,
                  name,
                  rangeIdx,
                  adtInfoIdx,
                  value,
                  range,
                  info.ranges
                );
              }}
              invalidRangeError={invalidRangeError}
              onCustomRangeChange={(value, name, index) => {
                updateValue(formik, value, name, index, adtInfoIdx);
              }}
              currentLang={currentLang}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
