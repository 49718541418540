import { createSlice } from "@reduxjs/toolkit";
import { PROFILE } from "../../constants";
import fallbackLogo from "assets/img/img-default.png";
import { updateProfile } from "../action";
import moment from 'moment';
import 'moment/min/locales.min';

const initialState = {
  id: 0,
  companyID: 0,
  fbid: null,
  googleID: null,
  username: "",
  firstName: "",
  lastName: "",
  role: "",
  roleId: null,
  email: "",
  contactNumber: "",
  phoneCountry: "",
  phoneCode: "",
  profileImg: "",
  accessRights: [],
  timezone: "",
  language: "",
  company_name: "",
  joinedDate: "",
  uuid: "",
  state: "",
  nodeName: "",
  remarks: "",
  registrationType: 1,
  roles: [],
};


const profileSlice = createSlice({
  name: PROFILE,
  initialState,
  reducers: {
    setProfile(state, action) {
      // email: "useremailtest25292@gmail.com";
      // email_verified_at: null;
      // first_name: "Faiz";
      // last_name: "Alkautsar";
      // locale: "en";
      // newsletter: 1;
      // phone_country: "Malaysia";
      // phone_number: "6018273917";
      // state: "active";
      // themes: null;
      // timezone: "Asia/Kuala_Lumpur";
      const { payload } = action;
      /**
       * !missing:
       * - id
       * - companyID
       * - fbid
       * - googleID
       * - username,
       * - role
       * - profileImg
       * - timeZone_Adjustment
       * - timeZone_Description
       */
      state.id = payload.auth0 || state.id;
      state.uuid = payload.id || state.uuid
      state.companyID = payload.companyID || state.companyID;
      state.fbid = payload.fbid || state.fbid;
      state.googleID = payload.googleID || state.googleID;
      state.username = payload.username || state.username;
      state.firstName = payload.first_name || state.firstName;
      state.lastName = payload.last_name || state.lastName;
      state.role = payload.role_name || state.role;
      state.roleId = payload.role_id || state.roleId;
      state.email = payload.email || state.email;
      state.contactNumber = payload.phone_number || state.contactNumber;
      state.phoneCode = payload.phone_code || state.phoneCode;
      state.phoneCountry = payload.phone_country || state.phoneCountry;
      state.companyName = payload.company_name || state.companyName;
      if (payload.image_profile_url)
        state.profileImg = payload.image_profile_url.url || state.profileImg;
      state.language = payload.locale || state.language;
      state.timezone = payload.timezone || state.timezone;
      state.role = payload.role || state.role;
      state.roles = payload.roles || state.roles;
      if (state.language === 'ZH') {
        moment.locale('zh-cn')
      } else if (state.language === 'TL') {
        moment.locale('tl-ph')
      }
      else {
        moment.locale(state.language)
      }
      state.joinedDate = payload.joined_date || state.joinedDate;
      state.state = payload.state || state.state
      state.nodeName = payload.node_name || state.nodeName
      state.remarks = payload.remarks || state.remarks
      state.registrationType = payload.registration_type || state.registrationType;
      state.isHqAdmin = payload.isHqAdmin || state.isHqAdmin;
    },
    setAccessRights(state, action) {
      state.accessRights = action.payload;
    },
    setUserProfilePicture(state, action) {
      state.profileImg = action.payload || fallbackLogo;
    }
  },
  extraReducers: {
    [updateProfile.fulfilled]: (state, action) => {
      const {
        contactNumber,
        email,
        firstName,
        lastName,
        timezone,
        locale,
        phoneCode,
      } = action.meta.arg;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.contactNumber = contactNumber;
      state.phoneCode = phoneCode;
      state.timezone = timezone;
      state.language = locale;
    }
  }
});


export const {
  setProfile,
  setAccessRights,
  setUserProfilePicture
} = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
