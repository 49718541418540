import React, { useEffect } from "react";
import {
  fetchBranchDropdown, getCustomField,
  hqSelectParentBranchListV2,
  selectParentBranchListV2
} from "modules/branch/redux";
import { resetBranchDropdown } from "modules/branch/redux";
import BranchDialogComponent from "./branchDialog.component";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";

function BranchDialogContainer({
  handleClose,
  handleSubmit,
  parentBranchList,
  isEditMode = false,
  branch,
  isBranchOnly,
  ownedCompany,
  companyName,
  isHqAdmin,
  editBranchList,
  isOpen,
  companyShortName,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const timezones = useSelector((state) => state.constant.timezones);
  const customFields = useSelector((state) => state.branch.customField ?? []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBranchDropdown());
    dispatch(getCustomField());
    return () => {
      dispatch(resetBranchDropdown());
    };
  }, [dispatch]);

  return (
    <BranchDialogComponent
      handleClose={handleClose}
      parentBranchList={parentBranchList}
      handleSubmit={handleSubmit}
      isEditMode={isEditMode}
      branch={branch}
      isBranchOnly={isBranchOnly}
      lang={lang}
      timezones={timezones}
      ownedCompany={ownedCompany}
      companyName={companyName}
      isHqAdmin={isHqAdmin}
      editBranchList={editBranchList}
      isOpen={isOpen}
      companyShortName={companyShortName}
      customFields={customFields}
    />
  );
}

BranchDialogContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  parentBranchList: selectParentBranchListV2(state),
  isBranchOnly: state.profile.nodeName.every(
    (item) => item.remarks === "branch"
  ),
  companyShortName: state.account.shortName,
  ownedCompany: state.profile.nodeName,
  companyName: state.profile.companyName,
  isHqAdmin: state.profile.isHqAdmin,
  editBranchList: hqSelectParentBranchListV2(state),
});

export default connect(mapStateToProps, null)(BranchDialogContainer);
