import axios from "axios";

export default function validateSoRequest(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/master-list/shipping-order/validate", {
        master_list_uuids: parameters.master_list_uuids,
        is_select_all: parameters.is_select_all,
        role_id: parameters.role_id,
        branch_uuids: parameters.branch_uuids,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
