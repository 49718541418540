import signIn from './signIn.api'
import signUp from './signUp.api'
import checkSession from './checkSession.api'
import signOut from './signOut.api'
import verifyEmail from './verifyEmail.api'
import resendVerificationEmail from './resendVerificationEmail.api'
import validateResetPasswordToken from './validateResetPasswordToken.api'
import resetPassword from './resetPassword.api'
import acceptNewAgreement from './acceptNewAgreement.api'
import checkAcceptNewAgreement from './checkAcceptNewAgreement.api'

const authApi = {
    signIn,
    signUp,
    checkSession,
    signOut,
    verifyEmail,
    resendVerificationEmail,
    validateResetPasswordToken,
    resetPassword,
    checkAcceptNewAgreement,
    acceptNewAgreement
};

export default authApi;