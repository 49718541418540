import React from "react";
import {
  makeStyles,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  MenuItem,
  Typography,
  MenuList,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 120,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddCategory: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
  list: {
    padding: 0,
  },
  listItem: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
}));

export default function ExportExcelSelectPanelComponent({
  open,
  anchorRef,
  handleClose,
  dropdownItem,
  isOpenList,
  handleSelection
}) {
  const classes = useStyle();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      category={undefined}
      transition
      disablePortal
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-end" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                {dropdownItem.map((item, index) => (
                  <MenuList
                    autoFocusItem={isOpenList}
                    id="menu-list-grow"
                    key={index}
                    onClick={() => handleSelection(item.value)}
                    className={classes.list}
                  >
                    <MenuItem className={classes.listItem}>
                      <Typography variant="inherit" noWrap>
                        {item.name}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                ))}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
