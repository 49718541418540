import { createSlice } from "@reduxjs/toolkit";
import { DISBURSEMENT } from "../../constants";
import {
  getCampaignTngDisbursementList,
  getCreditAlertInfo,
  getCreditBalanceReloadLog,
  getCreditOverview,
} from "../action";

const initialState = {
  campaignDisbursementLogs: {
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  creditReloadLog: {
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  alertInfo: {
    recipients: [],
    alertThreshold: 0,
    transactionThreshold: 0,
    hasAlert: false,
    isError: false,
  },
  overview: {
    data: {
      reloadRecords: [],
      availableCreditBalance: 0,
      totalCreditUsage: 0,
      creditUsageBalance: 0,
      creditUsagePercent: 0,
      overviewBalance: 0,
      transactionLimit: 0,
      creditBalances: {
          lifetime: {
            credit: 0,
            transaction: 0,
          },
          current: {
            credit: 0,
            transaction: 0,
          },
          unallocated: {
            credit: 0,
            transaction: 0,
          },
          allocated: {
            credit: 0,
            transaction: 0,
          },
          unclaimed_allocated: {
            credit: 0,
            transaction: 0,
          },
          claimed_allocated: {
            credit: 0,
            transaction: 0,
          }
        },
    },
    isError: false,
  },
};

const disbursementSlice = createSlice({
  name: DISBURSEMENT,
  initialState,
  reducers: {
    resetCampaignDisbursementLog(state) {
      state.campaignDisbursementLogs = initialState.campaignDisbursementLogs;
    },
    resetCreditReloadLog(state) {
      state.creditReloadLog = initialState.creditReloadLog;
    },
    resetCreditAlert(state) {
      state.alertInfo = initialState.alertInfo;
    },
    resetCreditOverview(state) {
      state.overview = initialState.overview;
    },
  },
  extraReducers: {
    [getCampaignTngDisbursementList.pending]: (state) => {
      state.campaignDisbursementLogs = initialState.campaignDisbursementLogs;
    },
    [getCampaignTngDisbursementList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.campaignDisbursementLogs.list = payload.data ? payload.data : [];
      state.campaignDisbursementLogs.totalRecords = payload.records_total ? payload.records_total : 0;
      state.campaignDisbursementLogs.totalFiltered = payload.records_filtered ? payload.records_filtered : 0;
    },
    [getCampaignTngDisbursementList.rejected]: (state) => {
      state.campaignDisbursementLogs.isError = true;
    },
    [getCreditBalanceReloadLog.pending]: (state) => {
      state.creditReloadLog = initialState.campaignDisbursementLogs;
    },
    [getCreditBalanceReloadLog.fulfilled]: (state, action) => {
      const { payload } = action;
      state.creditReloadLog.list = payload.data;
      state.creditReloadLog.totalRecords = payload.records_total;
      state.creditReloadLog.totalFiltered = payload.records_filtered;
    },
    [getCreditBalanceReloadLog.rejected]: (state) => {
      state.creditReloadLog.isError = true;
    },
    [getCreditAlertInfo.pending]: (state) => {
      state.alertInfo = initialState.alertInfo;
    },
    [getCreditAlertInfo.fulfilled]: (state, action) => {
      const { payload } = action;
      state.alertInfo.recipients = payload.recipients;
      state.alertInfo.alertThreshold = parseFloat(
        payload.alert_threshold
      ).toFixed(2);
      state.alertInfo.transactionThreshold = payload.transaction_threshold
      state.alertInfo.hasAlert = payload.has_alert;
    },
    [getCreditAlertInfo.rejected]: (state) => {
      state.alertInfo.isError = true;
    },
    [getCreditOverview.pending]: (state) => {
      state.overview = initialState.overview;
    },
    [getCreditOverview.fulfilled]: (state, action) => {
      const { payload } = action;
      state.overview.data.reloadRecords = payload.data;
      state.overview.data.availableCreditBalance = parseFloat(
        payload.available_credt_balance
      ).toFixed(2);
      state.overview.data.totalCreditUsage = parseFloat(
        payload.total_credit_usage
      ).toFixed(2);
      state.overview.data.creditUsageBalance = parseFloat(
        payload.credit_usage_balance
      ).toFixed(2);
      state.overview.data.creditUsagePercent = parseFloat(
        payload.credit_usage_percent
      ).toFixed(2);
      state.overview.data.overviewBalance = parseFloat(
        payload.overview_balance
      ).toFixed(2);
      state.overview.data.transactionLimit = payload.transaction_limit;

      state.overview.data.creditBalances.lifetime = payload.credit_balances.lifetime;
      state.overview.data.creditBalances.current = payload.credit_balances.current;
      state.overview.data.creditBalances.unallocated = payload.credit_balances.unallocated;
      state.overview.data.creditBalances.allocated = payload.credit_balances.allocated;
      state.overview.data.creditBalances.unclaimed_allocated = payload.credit_balances.unclaimed_allocated;
      state.overview.data.creditBalances.claimed_allocated = payload.credit_balances.claimed_allocated;
    },
    [getCreditOverview.rejected]: (state) => {
      state.overview.isError = true;
    },
  },
});

export const {
  resetCampaignDisbursementLog,
  resetCreditReloadLog,
  resetCreditAlert,
  resetCreditOverview,
} = disbursementSlice.actions;
export const disbursementReducer = disbursementSlice.reducer;
