import React, { useState, useEffect } from "react";
import ListingComponent from "./listing.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  fetchInstantLuckyDrawListing,
  changeInstantLuckyDrawStatus,
  updateIldPageSetting,
} from "modules/instant-lucky-draw/redux";
import ConfirmationDialog from "components/dialog/confirmation";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  INSTANT_LUCKY_DRAW_ADD,
  INSTANT_LUCKY_DRAW_EDIT,
  INSTANT_LUCKY_DRAW_ACTIVATE_OR_DEACTIVATE,
} from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";
import { getAllProducts } from "../../../product-mgmt/redux/action/products.action";

export default function ListingContainer() {
  const hasInstantLuckyDrawAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_ADD)
  );
  const hasInstantLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT)
  );
  const hasInstantLuckyDrawActivateOrDeactivateAccessRight = useSelector(
    (state) =>
      state.profile.accessRights.includes(
        INSTANT_LUCKY_DRAW_ACTIVATE_OR_DEACTIVATE
      )
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState({ search: "", product: [] });
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);

  const dispatch = useDispatch();
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchInstantLuckyDrawListing.typePrefix)
  );
  const data = useSelector(
    (state) => state.instantLuckyDraw.instantLuckyDrawListTable.data
  );
  const totalRecords = useSelector(
    (state) => state.instantLuckyDraw.instantLuckyDrawListTable.totalRecords
  );
  const filteredTotalRecords = useSelector(
    (state) =>
      state.instantLuckyDraw.instantLuckyDrawListTable.filteredTotalRecords
  );
  const isStatusUpdating = useSelector((state) =>
    selectLoading(state, changeInstantLuckyDrawStatus.typePrefix)
  );
  const productDropdown = useSelector((state) => state.products.allProducts);
  const isFetchingProduct = useSelector((state) =>
    selectLoading(state, getAllProducts.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchInstantLuckyDrawListing({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search.searchString,
        product: search.product.map((item) => item.id),
      })
    );
  }, [search, rowsPerPage, page, dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const handleFetchInstantLuckyDrawList = () => {
    dispatch(
      fetchInstantLuckyDrawListing({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search.searchString,
        product: search.product.map((item) => item.id),
      })
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);

    dispatch(
      updateIldPageSetting({
        start: rowsPerPage * newPage,
      })
    );
  };

  const handleRowPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    dispatch(
      updateIldPageSetting({
        start: 0,
        length: event.target.value,
      })
    );
  };

  const handleSearch = (value) => {
    setPage(0);
    setSearch(value);

    dispatch(
      updateIldPageSetting({
        start: 0,
        search: value.searchString ?? "",
        product: value.product.map((item) => item.id),
      })
    );
  };

  const handleStatusChange = ({ currentStatus, id }) => {
    if (hasInstantLuckyDrawActivateOrDeactivateAccessRight) {
      setChangeStatusTargetId(id);
      if (currentStatus) {
        setIsDeactivateDialogOpen(true);
      } else {
        setIsActivateDialogOpen(true);
      }
    }
  };

  const handleStatusSubmit = (status) => {
    if (hasInstantLuckyDrawActivateOrDeactivateAccessRight) {
      dispatch(
        changeInstantLuckyDrawStatus({
          id: changeStatusTargetId,
          status: status ? "active" : "inactive",
        })
      )
        .then(unwrapResult)
        .then(() => handleFetchInstantLuckyDrawList())
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  };

  return (
    <>
      <ListingComponent
        hasInstantLuckyDrawAddAccessRight={hasInstantLuckyDrawAddAccessRight}
        hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
        hasInstantLuckyDrawActivateOrDeactivateAccessRight={
          hasInstantLuckyDrawActivateOrDeactivateAccessRight
        }
        isFetching={isFetching}
        page={page}
        rowsPerPage={rowsPerPage}
        search={search}
        data={data}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        handleSearch={handleSearch}
        totalRecords={search ? filteredTotalRecords : totalRecords}
        handleStatusChange={handleStatusChange}
        handleFetchInstantLuckyDrawList={handleFetchInstantLuckyDrawList}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang, "paragraph.DEACTIVATE_ILD_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        description={getLang(lang, "paragraph.ACTIVATE_ILD_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
    </>
  );
}
