import React from "react";
import CurrencySelectComponent from "./currencySelect.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function CurrencySelectContainer({
  textFieldProps,
  formProps,
  type,
  currencyDropdownData,
  inputStyle,
  placeholder,
  paperClass,
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <CurrencySelectComponent
      textFieldProps={textFieldProps}
      formProps={formProps}
      currencyDropdownData={currencyDropdownData}
      type={type}
      lang={lang}
      inputStyle={inputStyle}
      placeholder={placeholder}
      paperClass={paperClass}
    />
  );
}

CurrencySelectContainer.propTypes = {
  textFieldProps: PropTypes.object,
  formProps: PropTypes.object,
  type: PropTypes.string,
};

export default CurrencySelectContainer;
