import axios from "axios";

export default function editBranchGroup(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/account/api/v1/branch-group/edit`, {
        branch_group_uuid: parameters.uuid,
        name: parameters.name,
        role_uuid: parameters.role_uuid,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
