import React from "react";

import {
  Box,
  Divider,
  Typography,
  Grid,
  Card,
  Button,
  makeStyles,
} from "@material-ui/core";
// import EditIcon from "@material-ui/icons/Edit";
import moment from "moment/moment";
import "moment/min/locales.min";

import { getLang } from "app/feature/constants";

import ReimbursementForm from "../../form/reimbursementForm";
import NoClaimSvg from "assets/svg/noClaim.svg";
import DOMPurify from "dompurify";
import { Skeleton } from "@material-ui/lab";

const style = (theme) => ({
  footer: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
});

const useStyles = makeStyles(style);

export default function ReimbursementDetailPanelComponent({
  lang,
  hasReimbursementEditAccessRight,
  hasReimbursementCreateAccessRight,
  hasReimbursementApproveAccessRight,
  currentEmployeeId,
  claimItem,
  formatAmount,
  reimbursementItem,
  language,
  handleMediaClick,
  handleTableReload,
  viewEditType,
  handleEditClick,
  handleCancelEdit,
  formMedia,
  isFetching,

  //  APPROVE / REJECT  //
  onRejectClick,
  onApproveClick,
}) {
  const classes = useStyles();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <>
      <Box
        style={{ overflowY: "auto" }}
        display="flex"
        height="100%"
        width="50rem"
        flexDirection="column"
        flexGrow={1}
      >
        {isFetching ? (
          <Box p={2}>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </Box>
        ) : (
          <>
            {reimbursementItem.uuid ? (
              <>
                <Box px={3} pt={3}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold", paddingBottom: "1rem" }}
                    >
                      {getLang(lang, "label.DETAILS")}
                    </Typography>
                    {/* {viewEditType === 'view'
                  && reimbursementItem.status === 1
                  && hasReimbursementEditAccessRight
                  && currentEmployeeId === reimbursementItem.node.requestEmployee.uuid && (
                    <IconButton color="primary" onClick={handleEditClick}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )} */}
                  </Box>

                  <Box>
                    <Grid container>
                      <Grid item xs={4}>
                        {getLang(lang, "label.STATUS")}
                      </Grid>
                      <Grid item xs={8}>
                        {reimbursementItem.status === 1 && (
                          <Box
                            fontWeight="fontWeightBold"
                            bgcolor="warning.main"
                            color="#FFFFFF"
                            p={1}
                            py={0.5}
                            borderRadius={4}
                            height="min-content"
                            width="min-content"
                          >
                            {getLang(lang, "label.PENDING")}
                          </Box>
                        )}
                        {reimbursementItem.status === 2 && (
                          <Box
                            fontWeight="fontWeightBold"
                            bgcolor="secondary.main"
                            color="#FFFFFF"
                            p={1}
                            py={0.5}
                            borderRadius={4}
                            height="min-content"
                            width="min-content"
                          >
                            {getLang(lang, "label.APPROVED")}
                          </Box>
                        )}
                        {reimbursementItem.status === 3 && (
                          <Box
                            fontWeight="fontWeightBold"
                            bgcolor="error.main"
                            color="#FFFFFF"
                            p={1}
                            py={0.5}
                            borderRadius={4}
                            height="min-content"
                            width="min-content"
                          >
                            {getLang(lang, "label.REJECTED")}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {viewEditType === "edit" && hasReimbursementEditAccessRight ? (
                  <>
                    <ReimbursementForm
                      lang={lang}
                      language={language}
                      claimItem={claimItem}
                      handleTableReload={handleTableReload}
                      submitType={"edit"}
                      formMedia={formMedia}
                      //  DISPLAY IMAGE DIALOG  //
                      handleMediaClick={handleMediaClick}
                    >
                      {(isSubmittingForm) => (
                        <>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={2}
                          >
                            <Button
                              variant="outline"
                              style={{
                                color: "#FD646F",
                                border: "1px solid",
                                borderColor: "#FD646F",
                              }}
                              disableElevation
                              type="button"
                              disabled={isSubmittingForm}
                              onClick={handleCancelEdit}
                            >
                              {getLang(lang, "label.CANCEL")}
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              disableElevation
                              type={"submit"}
                              disabled={isSubmittingForm}
                            >
                              {getLang(lang, "label.UPDATE")}
                            </Button>
                          </Box>
                          <Divider />
                        </>
                      )}
                    </ReimbursementForm>
                  </>
                ) : (
                  <>
                    <Box px={3}>
                      {reimbursementItem.receiptProof &&
                        (reimbursementItem.receiptProof.videoFile ||
                          reimbursementItem.receiptProof.imageFile) && (
                          <Box mt={2}>
                            <Grid container mt={2}>
                              <Grid item xs={4}>
                                {getLang(lang, "label.UPLOADED_FILE")}
                              </Grid>
                              <Grid item xs={8}>
                                <Box
                                  py={1}
                                  mb={1}
                                  display="flex"
                                  flexWrap="no-wrap"
                                  style={{ overflowX: "auto" }}
                                >
                                  {reimbursementItem.receiptProof.videoFile
                                    .length > 0 &&
                                    reimbursementItem.receiptProof.videoFile.map(
                                      (file) => (
                                        <Box mr={1}>
                                          <Card>
                                            <Box height="70px" width="70px">
                                              <video
                                                width="70"
                                                height="70"
                                                preload="metadata"
                                                muted
                                                onClick={() =>
                                                  handleMediaClick(
                                                    file,
                                                    "video"
                                                  )
                                                }
                                              >
                                                <source src={file} />
                                              </video>
                                            </Box>
                                          </Card>
                                        </Box>
                                      )
                                    )}
                                  {reimbursementItem.receiptProof.imageFile
                                    .length > 0 &&
                                    reimbursementItem.receiptProof.imageFile.map(
                                      (file) => (
                                        <Box mr={1}>
                                          <Card>
                                            <Box height="70px" width="70px">
                                              <img
                                                height="70px"
                                                width="70px"
                                                style={{ objectFit: "contain" }}
                                                src={file}
                                                alt="img"
                                                onClick={() =>
                                                  handleMediaClick(
                                                    file,
                                                    "image"
                                                  )
                                                }
                                              />
                                            </Box>
                                          </Card>
                                        </Box>
                                      )
                                    )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        )}

                      <Box mt={2}>
                        <Grid container mt={2}>
                          <Grid item xs={4}>
                            {getLang(lang, "label.SHIPPING_FEE")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box fontWeight="fontWeightBold">
                              {`${reimbursementItem.currencyCode}  ${reimbursementItem.amount}`}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mt={2}>
                        <Grid container mt={2}>
                          <Grid item xs={4}>
                            {getLang(lang, "label.REMARK")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box fontWeight="fontWeightBold">
                              {reimbursementItem.remark}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                )}

                <Box px={3} pb={3}>
                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.REQUEST_BY")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {`${reimbursementItem.node.requestEmployee.firstName} 
                        ${
                          reimbursementItem.node.requestEmployee.lastName
                            ? reimbursementItem.node.requestEmployee.lastName
                            : ""
                        }`}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.REQUEST_FROM")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {reimbursementItem.node.name}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.REFERENCE_CODE")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {reimbursementItem.referenceCode}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.SUBMISSION_DATE")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {moment(reimbursementItem.createdAt).format("lll")}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Divider />

                {reimbursementItem.status !== 1 && (
                  <>
                    <Box px={3} pb={3}>
                      {reimbursementItem.status === 3 && (
                        <Box mt={2}>
                          <Grid container mt={2}>
                            <Grid item xs={4}>
                              {getLang(
                                lang,
                                "label.REIMBURSEMENT_REJECTED_REASON"
                              )}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {reimbursementItem.rejectedReason}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}

                      <Box mt={2}>
                        <Grid container mt={2}>
                          <Grid item xs={4}>
                            {reimbursementItem.status === 2
                              ? getLang(lang, "label.APPROVE_BY")
                              : getLang(lang, "label.REJECT_BY")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box fontWeight="fontWeightBold">
                              {`${
                                reimbursementItem.node.requestEmployee.firstName
                              } 
                            ${
                              reimbursementItem.node.requestEmployee.lastName
                                ? reimbursementItem.node.requestEmployee
                                    .lastName
                                : ""
                            }`}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mt={2}>
                        <Grid container mt={2}>
                          <Grid item xs={4}>
                            {reimbursementItem.status === 2
                              ? getLang(lang, "label.APPROVED_AT")
                              : getLang(lang, "label.REJECTED_DATE")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box fontWeight="fontWeightBold">
                              {reimbursementItem.respondedAt}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    <Divider />
                  </>
                )}

                {reimbursementItem.status === 3 &&
                !claimItem.pendingReimbursementExist ? (
                  <>
                    <Box pt={3} px={3}>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {getLang(
                          lang,
                          "label.WARRANTY_REIMBURSEMENT_RESUBMISSION_FORM"
                        )}
                      </Typography>
                    </Box>

                    <ReimbursementForm
                      lang={lang}
                      claimItem={claimItem}
                      language={language}
                      handleTableReload={handleTableReload}
                      //  DISPLAY IMAGE DIALOG  //
                      handleMediaClick={handleMediaClick}
                    >
                      {(isSubmittingForm) => (
                        <>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            px={2}
                            py={1}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              disableElevation
                              type={"submit"}
                              disabled={isSubmittingForm}
                            >
                              {getLang(lang, "label.RESUBMIT")}
                            </Button>
                          </Box>
                        </>
                      )}
                    </ReimbursementForm>
                  </>
                ) : (
                  <>
                    <Box p={3}>
                      <Box>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {getLang(lang, "label.WARRANTY_CLAIM_DETAIL")}
                        </Typography>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.CONSUMER_EMAIL")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {reimbursementItem.warrantyClaim.consumer.email ?? '-'}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.CONSUMER_NAME")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {
                                  reimbursementItem.warrantyClaim.consumer
                                    .fullName ?? '-'
                                }
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.CLAIM_ADDRESS")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {claimItem.claimAddress ?? '-'}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.CONSUMER_REMARKS")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {reimbursementItem.warrantyClaim.remark
                                  ? generateLink(
                                      reimbursementItem.warrantyClaim.remark
                                    )
                                  : "-"}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container mt={2}>
                            <Grid item xs={4}>
                              {getLang(lang, "label.UPLOADED_FILE")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box
                                py={1}
                                mb={1}
                                display="flex"
                                flexWrap="no-wrap"
                                style={{ overflowX: "auto" }}
                              >
                                {claimItem.medias &&
                                  claimItem.medias.videoFile.length > 0 &&
                                  claimItem.medias.videoFile.map((file) => (
                                    <Box mr={1}>
                                      <Card>
                                        <Box height="70px" width="70px">
                                          <video
                                            width="70"
                                            height="70"
                                            preload="metadata"
                                            muted
                                            onClick={() =>
                                              handleMediaClick(file, "video")
                                            }
                                          >
                                            <source src={file} />
                                          </video>
                                        </Box>
                                      </Card>
                                    </Box>
                                  ))}
                                {claimItem.medias &&
                                  claimItem.medias.imageFile.length > 0 &&
                                  claimItem.medias.imageFile.map((file) => (
                                    <Box mr={1}>
                                      <Card>
                                        <Box height="70px" width="70px">
                                          <img
                                            height="70px"
                                            width="70px"
                                            style={{ objectFit: "contain" }}
                                            src={file}
                                            alt="img"
                                            onClick={() =>
                                              handleMediaClick(file, "image")
                                            }
                                          />
                                        </Box>
                                      </Card>
                                    </Box>
                                  ))}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.APPROVED_AT")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {reimbursementItem.warrantyClaim.approvedAt}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(
                                lang,
                                "label.WARRANTY_CLAIM_REFERENCE_CODE"
                              )}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {reimbursementItem.warrantyClaim.referenceCode}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>

                    <Divider />

                    <Box p={3}>
                      <Box>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {getLang(lang, "label.PRODUCT_DETAILS")}
                        </Typography>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.PRODUCT_NAME")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="flex-start"
                              >
                                <Typography variant="body1">
                                  <b>{reimbursementItem.product.name}</b>
                                </Typography>
                                {!!reimbursementItem.product.attributes &&
                                  !!reimbursementItem.product.attributes
                                    .length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {reimbursementItem.product.attributes.join(
                                        ", "
                                      )}
                                    </Typography>
                                  )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.SERIAL_NUMBER")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {reimbursementItem.product.serialNumber}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}

                {hasReimbursementApproveAccessRight &&
                  reimbursementItem.status === 1 &&
                  viewEditType === "view" && (
                    <>
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        px={3}
                        py={2}
                        className={classes.footer}
                      >
                        <Button
                          variant="outline"
                          disableElevation
                          type="button"
                          style={{
                            color: "#FD646F",
                            border: "1px solid",
                            borderColor: "#FD646F",
                            marginRight: "1rem",
                          }}
                          onClick={onRejectClick}
                        >
                          {getLang(lang, "label.REJECT")}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          type="button"
                          onClick={onApproveClick}
                        >
                          {getLang(lang, "label.APPROVE")}
                        </Button>
                      </Box>
                    </>
                  )}
              </>
            ) : (
              <Box p={5}>
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={NoClaimSvg} alt="" />
                  <Typography>
                    <Box
                      mt={1}
                      fontSize="h5.fontSize"
                      fontWeight="fontWeightBold"
                      textAlign="center"
                      pb={1}
                    >
                      {getLang(lang, "label.NO_REIMBURSEMENT_YET")}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
            {!reimbursementItem.uuid && hasReimbursementCreateAccessRight && (
              <>
                <Divider />

                <Box pt={3} px={3}>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {getLang(lang, "label.WARRANTY_REIMBURSEMENT_FORM")}
                  </Typography>
                </Box>

                <ReimbursementForm
                  lang={lang}
                  language={language}
                  claimItem={claimItem}
                  handleTableReload={handleTableReload}
                  //  DISPLAY IMAGE DIALOG  //
                  handleMediaClick={handleMediaClick}
                >
                  {(isSubmittingForm) => (
                    <>
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        px={3}
                        py={2}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          type={"submit"}
                          disabled={isSubmittingForm}
                        >
                          {getLang(lang, "label.CREATE")}
                        </Button>
                      </Box>
                    </>
                  )}
                </ReimbursementForm>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
