import axios from "axios";

const createBranch = async ({
  name,
  short_name,
  description,
  line1,
  line2,
  city,
  country,
  postal_code,
  state,
  contact,
  contact_code,
  email,
  parent_id,
  timezone,
  branch_group_uuid,
  profile_image_url,
  social_media_urls,
  is_show_contact,
  is_show_email,
  landing_page_language,
  custom_field,
}) => {
  const response = await axios.post("/account/api/v1/node", {
    name,
    short_name,
    description,
    line1,
    line2,
    city,
    country,
    postal_code,
    state,
    contact,
    contact_code,
    email,
    parent_id,
    timezone,
    branch_group_uuid,
    profile_image_url,
    social_media_urls,
    is_show_contact,
    is_show_email,
    landing_page_language,
    custom_field,
  });

  return response;
};

export default createBranch;
