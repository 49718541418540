import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  RemoveCircleOutlineOutlined as RemoveCircleIcon,
  AddCircleOutlineOutlined as AddCircleIcon,
  CheckCircle as CheckIcon,
  ErrorRounded as ErrorRoundedIcon,
} from "@material-ui/icons";
import { ErrorMessage, Field } from "formik";
import InputTextField from "components/input/inputTextField";
import DatePickerScrollDialog from "../../dialog/datePickerScrollDialog";

const useStyle = makeStyles((theme) => ({
  squareButton: {
    height: 45,
    width: 45,
    minWidth: "fit-content",
    padding: 0,
    backgroundColor: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  table: {
    "& .MuiTableRow-root:hover": {
      backgroundColor: "white",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "8px 12px",
    },
  },
  valueContainer: {
    padding: "7px",
    maxHeight: 80,
    overflowY: "auto !important",
  },
}));

function RenderFormikField({
  name,
  onChange,
  value,
  disabled,
  type,
  placeholder,
  onBlur,
  index,
  range,
  hasError,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <InputTextField
              {...field}
              value={value}
              hiddenLabel={true}
              variant="filled"
              fullWidth
              type={type === 2 ? "number" : "text"}
              size="small"
              disabled={disabled}
              onKeyDown={(e) => {
                if (type === 2) {
                  const invalidChars = ["-", "+", "e", "."];
                  if (invalidChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              placeholder={placeholder}
              InputStyle={{
                backgroundColor: "white",
                border: hasError ? "1px solid #FD646F" : "1px solid #D0D5DD",
                borderRadius: "4px",
              }}
              inputProps={{
                min: 0,
                onBlur: () => {
                  onBlur(name, index, value, range);
                },
              }}
              error={meta.error && meta.touched ? true : false}
              onChange={({ target: { value } }) => {
                if (type === 2) {
                  const regex = /^[0-9]+$/;
                  if (value && !value.match(regex)) {
                    return;
                  }
                }

                onChange(value);
              }}
            />
          );
        }}
      </Field>
    </>
  );
}

export default function InfoRangeListTableComponent({
  rows,
  handleUpdate,
  lang,
  format,
  formik,
  isSameRange,
  type, // 1 - serial, 2 - sequence
  updateRow,
  onCustomRangeChange,
  deleteRange,
  onBlur,
  invalidRangeError,
  rowIndex,
  disabled,
  currentLang,
}) {
  const classes = useStyle();

  return (
    <>
      <TableContainer>
        <Table
          aria-label="sn table"
          size="small"
          stickyHeader
          className={classes.table}
        >
          <TableHead style={{ height: "45px", background: "white" }}>
            <TableRow>
              <TableCell width="21%">
                <Typography variant="body2">
                  {getLang(lang, "label.FROM")}
                </Typography>
              </TableCell>
              <TableCell width="21%">
                <Typography variant="body2">
                  {getLang(lang, "label.TO")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {getLang(
                    lang,
                    format === 3
                      ? "label.NUMBER"
                      : format === 2
                      ? "label.DATE"
                      : format === 4
                      ? "label.URL_LINK"
                      : "label.TEXT"
                  )}
                </Typography>
              </TableCell>
              <TableCell width={132} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((range, index) => {
              return (
                <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                  <TableCell width="21%">
                    <RenderFormikField
                      name={`additionalInfo.${rowIndex}.ranges.${index}.from`}
                      onChange={(value) =>
                        onCustomRangeChange(value, "from", index)
                      }
                      isSameRange={isSameRange}
                      type={type}
                      placeholder={getLang(lang, "label.FROM")}
                      value={range.from}
                      onBlur={onBlur}
                      index={index}
                      range={range}
                      hasError={
                        (Object.keys(invalidRangeError).length &&
                          invalidRangeError.hasOwnProperty(rowIndex) &&
                          invalidRangeError[rowIndex]?.includes(index) &&
                          !!range.from) ||
                        (formik.errors.additionalInfo?.[rowIndex]?.ranges?.[
                          index
                        ]?.from &&
                          formik.touched.additionalInfo?.[rowIndex]?.ranges?.[
                            index
                          ]?.from)
                      }
                      formik={formik}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell width="21%">
                    <RenderFormikField
                      name={`additionalInfo.[${rowIndex}].ranges.[${index}].to`}
                      onChange={(value) =>
                        onCustomRangeChange(value, "to", index)
                      }
                      isSameRange={isSameRange}
                      type={type}
                      placeholder={getLang(lang, "label.TO")}
                      value={range.to}
                      onBlur={onBlur}
                      index={index}
                      range={range}
                      hasError={
                        Object.keys(invalidRangeError).length &&
                        invalidRangeError.hasOwnProperty(rowIndex) &&
                        invalidRangeError[rowIndex]?.includes(index) &&
                        !!range.to
                      }
                      formik={formik}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell>
                    {format === 1 ? (
                      <Box
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              "display",
                              "flex",
                              "important"
                            );

                            el.style.setProperty(
                              "align-items",
                              "center",
                              "important"
                            );
                          }
                        }}
                      >
                        <Box
                          flex={1}
                          mr={1}
                          p={0.75}
                          borderRadius={4}
                          border="1px solid #D0D5DD"
                        >
                          <Box alignItems="center" display="flex">
                            <Field
                              name={`additionalInfo.[${rowIndex}].ranges.[${index}].value.[${currentLang}]`}
                            >
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    multiline={true}
                                    rowsMax={3}
                                    onChange={(e) => {
                                      let temp = null;
                                      if (range.value) {
                                        temp = {
                                          ...range.value,
                                          [currentLang]: e.target.value,
                                        };
                                      } else {
                                        temp = {};
                                        formik.values.usedLang.forEach((l) => {
                                          temp[l.code] = "";
                                        });
                                        temp[currentLang] = e.target.value;
                                      }

                                      handleUpdate(temp, `value`, index);
                                    }}
                                    value={range.value[currentLang] ?? ""}
                                    placeholder={getLang(
                                      lang,
                                      "placeholder.PLEASE_ENTER_DESCRIPTION"
                                    )}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        padding: 0,
                                        background: "white",
                                        border: "none",
                                      },
                                    }}
                                    inputProps={{
                                      maxLength: 1000,
                                      style: {
                                        padding: "7px",
                                        maxHeight: 70,
                                        overflow: "auto !important",
                                        minHeight: 19,
                                      },
                                    }}
                                    style={{ width: "100%" }}
                                    disabled={disabled}
                                  />
                                );
                              }}
                            </Field>
                            {formik.errors.additionalInfo?.[rowIndex]?.ranges?.[
                              index
                            ]?.value?.[currentLang] &&
                              formik.touched.additionalInfo?.[rowIndex]
                                ?.ranges?.[index]?.value?.[currentLang] && (
                                <ErrorRoundedIcon
                                  style={{
                                    color: "#f44336",
                                    marginLeft: 8,
                                  }}
                                />
                              )}
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="caption"
                              style={{ color: "#D0D5DD" }}
                            >
                              {range.value?.length ?? 0}/1000
                            </Typography>
                          </Box>
                        </Box>
                        <CheckIcon
                          style={{
                            color: range.value?.[currentLang]?.length
                              ? "#32D583"
                              : "#D0D5DD",
                          }}
                        />
                      </Box>
                    ) : format === 2 ? (
                      <Box display="flex" alignItems="center">
                        <DatePickerScrollDialog
                          label=""
                          handleChange={(value) => {
                            handleUpdate(
                              value.format("YYYY-MM-DD"),
                              `value`,
                              index
                            );
                          }}
                          value={range.value}
                          displayFormat="DD/MM/YYYY"
                          error={
                            formik.errors.additionalInfo?.[rowIndex]?.ranges?.[
                              index
                            ]?.value &&
                            formik.touched.additionalInfo?.[rowIndex]?.ranges?.[
                              index
                            ]?.value
                          }
                          disabled={disabled}
                        />
                        <CheckIcon
                          style={{
                            color: range.value ? "#32D583" : "#D0D5DD",
                            marginLeft: 8,
                          }}
                        />
                      </Box>
                    ) : format === 4 ? (
                      <Box display="flex" alignItems="center">
                        <Box
                          flex={1}
                          mr={1}
                          p={0.75}
                          borderRadius={4}
                          border="1px solid #D0D5DD"
                        >
                          <Box alignItems="center" display="flex">
                            <Field
                              name={`additionalInfo.[${rowIndex}].ranges.[${index}].value`}
                            >
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    onChange={(e) => {
                                      handleUpdate(
                                        e.target.value,
                                        `value`,
                                        index
                                      );
                                    }}
                                    type="text"
                                    value={range.value ?? ""}
                                    placeholder={getLang(
                                      lang,
                                      "placeholder.ENTER_URL_HERE"
                                    )}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        padding: 0,
                                        background: "white",
                                        border: "none",
                                      },
                                    }}
                                    inputProps={{
                                      style: {
                                        padding: "7px",
                                        maxHeight: 70,
                                        overflow: "auto !important",
                                        minHeight: 19,
                                      },
                                    }}
                                    style={{ width: "100%" }}
                                    disabled={disabled}
                                  />
                                );
                              }}
                            </Field>
                            {formik.errors.additionalInfo?.[rowIndex]?.ranges?.[
                              index
                            ]?.value &&
                              formik.touched.additionalInfo?.[rowIndex]
                                ?.ranges?.[index]?.value && (
                                <ErrorRoundedIcon
                                  style={{
                                    color: "#f44336",
                                    marginLeft: 8,
                                  }}
                                />
                              )}
                          </Box>
                          <FormHelperText
                            error={
                              formik.errors.additionalInfo?.[rowIndex]
                                ?.ranges?.[index]?.value &&
                              formik.touched.additionalInfo?.[rowIndex]
                                ?.ranges?.[index]?.value
                            }
                            style={{ paddingLeft: 8 }}
                          >
                            {ErrorMessage({
                              name: `additionalInfo.[${rowIndex}].ranges.[${index}].value`,
                            })}
                          </FormHelperText>
                        </Box>

                        <CheckIcon
                          style={{
                            color: range.value?.length ? "#32D583" : "#D0D5DD",
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        // display="flex"
                        // alignItems="center"
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              "display",
                              "flex",
                              "important"
                            );

                            el.style.setProperty(
                              "align-items",
                              "center",
                              "important"
                            );
                          }
                        }}
                      >
                        <Box
                          flex={1}
                          mr={1}
                          p={0.75}
                          borderRadius={4}
                          border="1px solid #D0D5DD"
                        >
                          <Box alignItems="center" display="flex">
                            <Field
                              name={`additionalInfo.[${rowIndex}].ranges.[${index}].value`}
                            >
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    onChange={(e) => {
                                      handleUpdate(
                                        e.target.value,
                                        `value`,
                                        index
                                      );
                                    }}
                                    type="number"
                                    value={range.value ?? ""}
                                    placeholder={getLang(
                                      lang,
                                      "placeholder.PLEASE_ENTER_NUMBER_DESCRIPTION"
                                    )}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        padding: 0,
                                        background: "white",
                                        border: "none",
                                      },
                                    }}
                                    inputProps={{
                                      style: {
                                        padding: "7px",
                                        maxHeight: 70,
                                        overflow: "auto !important",
                                        minHeight: 19,
                                      },
                                    }}
                                    style={{ width: "100%" }}
                                    disabled={disabled}
                                  />
                                );
                              }}
                            </Field>
                            {formik.errors.additionalInfo?.[rowIndex]?.ranges?.[
                              index
                            ]?.value &&
                              formik.touched.additionalInfo?.[rowIndex]
                                ?.ranges?.[index]?.value && (
                                <ErrorRoundedIcon
                                  style={{
                                    color: "#f44336",
                                    marginLeft: 8,
                                  }}
                                />
                              )}
                          </Box>
                        </Box>
                        <CheckIcon
                          style={{
                            color: range.value?.length ? "#32D583" : "#D0D5DD",
                          }}
                        />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell width={132}>
                    <Box display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        onClick={() => {
                          deleteRange(index);
                        }}
                        disabled={disabled}
                      >
                        <Box className={classes.squareButton}>
                          <RemoveCircleIcon style={{ color: "#F97066" }} />
                        </Box>
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => updateRow("add", index)}
                        disabled={disabled}
                      >
                        <Box className={classes.squareButton}>
                          <AddCircleIcon style={{ color: "#6AAF68" }} />
                        </Box>
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!rows.length && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={3}
          >
            <Typography variant="body2" color="textSecondary">
              {getLang(lang, "message.info.NO_RECORD_EXIST")}
            </Typography>
          </Box>
        )}
      </TableContainer>
      {!!Object.keys(invalidRangeError).length &&
        !!invalidRangeError.hasOwnProperty(rowIndex) && (
          <Box m={1}>
            <span
              style={{
                color: "#f44336",
                fontSize: "0.75rem",
              }}
            >
              {getLang(lang, "label.INVALID_RANGE")}
            </span>
          </Box>
        )}
    </>
  );
}
