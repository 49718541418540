import axios from "axios";

export default function createSpecialRequest(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/master-list/special-request/create", {
        role: parameters.role,
        branch_uuid: parameters.branch_uuid,
        order_type: parameters.order_type,
        order_type_sub: parameters.order_type_sub,
        reference_shipping_order_id: parameters.reference_shipping_order_id,
        sku_list: parameters.sku_list,
        quantity: parameters.quantity,
        reference_no: parameters.reference_no,
        remark: parameters.remark,
        uploaded_files: parameters.uploaded_files,
        is_po: parameters.is_po,
        currency: parameters.currency,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
