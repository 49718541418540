import React, { useEffect, useState } from "react";
import TransferItemsListPanelComponent from "./transferItemsListPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getTransferItemBoxList,
  getTransferItemProductList,
} from "modules/stockflow/redux";
import TransferProductSnListDialog from "../../dialog/transferProductSnListDialog";
import TransferBoxDetailDialog from "../../dialog/transferBoxDetailDialog";

export default function TransferItemsListPanelContainer({
  totalBox,
  totalPackage,
  id,
  status,
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);

  // box
  const isBoxFetching = useSelector((state) =>
    selectLoading(state, getTransferItemBoxList.typePrefix)
  );
  const boxTotalFiltered = useSelector(
    (state) => state.stockflowTransfer.transferBoxList.totalFiltered
  );
  const boxList = useSelector(
    (state) => state.stockflowTransfer.transferBoxList.list
  );
  const isBoxError = useSelector(
    (state) => state.stockflowTransfer.transferBoxList.isError
  );
  const boxHasNextPage = useSelector(
    (state) => state.stockflowTransfer.transferBoxList.hasNextPage
  );

  // product
  const isProductFetching = useSelector((state) =>
    selectLoading(state, getTransferItemProductList.typePrefix)
  );
  const prdTotalFiltered = useSelector(
    (state) => state.stockflowTransfer.transferProductList.totalFiltered
  );
  const prdList = useSelector(
    (state) => state.stockflowTransfer.transferProductList.list
  );
  const isPrdError = useSelector(
    (state) => state.stockflowTransfer.transferProductList.isError
  );
  const prdHasNextPage = useSelector(
    (state) => state.stockflowTransfer.transferProductList.hasNextPage
  );

  const [filter, setFilter] = useState({
    prdPage: 0,
    boxPage: 0,
    rowsPerPage: 25,
  });
  const [tab, setTab] = useState(0);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);

  const hasNextPage = tab === 0 ? boxHasNextPage : prdHasNextPage;

  useEffect(() => {
    if (id) {
      let isFetching = tab === 0 ? isBoxFetching : isProductFetching;
      if (hasNextPage && !isFetching) {
        fetchData(filter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, tab, id]);

  const fetchData = (filterInfo) => {
    if (tab === 0) {
      dispatch(
        getTransferItemBoxList({
          id,
          length: filterInfo.rowsPerPage,
          start: filterInfo.rowsPerPage * filterInfo.boxPage,
        })
      );
    } else {
      dispatch(
        getTransferItemProductList({
          id,
          length: filterInfo.rowsPerPage,
          start: filterInfo.rowsPerPage * filterInfo.prdPage,
        })
      );
    }
  };

  const changeTab = (val) => {
    if (val !== tab) {
      setTab(val);
      // const newFilter = {
      //   ...filter,
      //   page: 0,
      // };
      // setFilter(newFilter);
    }
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter };
      if (tab === 0) {
        newFilter.boxPage += 1;
      } else {
        newFilter.prdPage += 1;
      }
      setFilter(newFilter);
    }
  };

  const handleReload = () => {
    const newFilter = {
      ...filter,
      prdPage: 0,
      boxPage: 0,
    };
    setFilter(newFilter);
  };

  return (
    <>
      <TransferItemsListPanelComponent
        totalBox={totalBox}
        totalPackage={totalPackage}
        lang={lang}
        tab={tab}
        changeTab={changeTab}
        isFetching={tab === 0 ? isBoxFetching : isProductFetching}
        list={tab === 0 ? boxList : prdList}
        totalFiltered={tab === 0 ? boxTotalFiltered : prdTotalFiltered}
        isError={tab === 0 ? isBoxError : isPrdError}
        handleFetchMoreItems={handleFetchMoreItems}
        hasNextPage={hasNextPage}
        handleReload={handleReload}
        viewProduct={(prd) => setSelectedProduct(prd)}
        viewBox={(box) => setSelectedBox(box)}
      />
      <TransferProductSnListDialog
        isOpen={!!selectedProduct}
        handleClose={() => setSelectedProduct(null)}
        id={id}
        productInfo={
          selectedProduct
            ? {
                ...selectedProduct,
                status: status,
              }
            : null
        }
      />
      <TransferBoxDetailDialog
        isOpen={!!selectedBox}
        handleClose={() => setSelectedBox(null)}
        id={id}
        boxInfo={
          selectedBox
            ? {
                ...selectedBox,
                type: "",
              }
            : null
        }
      />
    </>
  );
}
