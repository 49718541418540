import axios from "axios";

export default function getSoSelectedMLSummary(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/distributor/admin/api/v1/master-list/shipping-order/selection-summary",
        {
          master_list_uuids: parameters.master_list_uuids,
          is_select_all: parameters.is_select_all,
          length: parameters.length,
          start: parameters.start,
          branch_uuid: parameters.branch_uuid,
          role_id: parameters.role_id,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
