import axios from "axios";

export default function getProductListDropdown({ branch_uuid }) {
  return new Promise((resolve, reject) => {
    axios
      .post("/distributor/admin/api/v1/master-list/product/list", {
        branch_uuid,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
