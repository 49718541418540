import { createAsyncThunk } from "@reduxjs/toolkit";
import consumerApi from "app/api/consumer";
import { CONSUMER } from "../../constants";

export const fetchConsumerListing = createAsyncThunk(
    `${CONSUMER}/fetchConsumerListing`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getAllConsumer({
            length: payload.length,
            start: payload.start,
            column: payload.column,
            order: payload.order,
            search: payload.search,
            searchType: payload.searchType
        })
            .then(response => response.data)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const fetchConsumerProfile = createAsyncThunk(
    `${CONSUMER}/fetchConsumerProfile`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getConsumerProfile(payload)
            .then(response => response.data)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const fetchConsumerScanHistoryListing = createAsyncThunk(
    `${CONSUMER}/fetchConsumerScanHistoryListing`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getConsumerScanListing({
            id: payload.id,
            length: payload.length,
            start: payload.start,
            column: payload.column,
            order: payload.order,
        })
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const fetchAllScanTrack = createAsyncThunk(
    `${CONSUMER}/fetchAllScanTrack`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.fetchAllScanTrack(payload)
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const getConsumerGiftRedemptionListing = createAsyncThunk(
    `${CONSUMER}/getConsumerGiftRedemptionListing`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getConsumerGiftRedemptionListing({
            id: payload.id,
            length: payload.length,
            start: payload.start,
            column: payload.column,
            order: payload.order,
            date: payload.date,
            gift: payload.gift,
            status: payload.status
        })
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const getConsumerLoyaltyPointHistory = createAsyncThunk(
    `${CONSUMER}/getConsumerLoyaltyPointHistory`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getConsumerLoyaltyPointHistory({
            id: payload.id,
            length: payload.length,
            start: payload.start,
            column: payload.column,
            order: payload.order,
        })
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const getGiftDropdown = createAsyncThunk(
    `${CONSUMER}/getGiftDropdown`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getGiftDropdown(payload)
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const getConsumerCampaignListing = createAsyncThunk(
    `${CONSUMER}/getConsumerCampaignListing`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getConsumerCampaignListing({
            id: payload.id,
            length: payload.length,
            start: payload.start,
            column: payload.column,
            order: payload.order,
            date: payload.date,
            type: payload.type,
            products: payload.products,
            patterns: payload.patterns
        })
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const getCampaignProductDropdown = createAsyncThunk(
    `${CONSUMER}/getCampaignProductDropdown`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.getCampaignProductDropdown(payload)
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)

export const updateConsumerLoyaltyPoint = createAsyncThunk(
    `${CONSUMER}/updateConsumerLoyaltyPoint`,
    async (payload, { rejectWithValue }) => {
        return consumerApi.updateConsumerLoyaltyPoint(payload)
            .then(response => response)
            .catch(error => rejectWithValue(error.response.data));
    }
)
