// COMPANY
export const COMPANY_VIEW = "company view";
export const COMPANY_EDIT = "company edit";
export const COMPANY_INFO_VIEW = "company info view";
export const COMPANY_INFO_EDIT = "company info edit";
export const COMPANY_PRODUCT_VIEW = "company product view";
export const COMPANY_PRODUCT_EDIT = "company product edit";
export const COMPANY_PRODUCT_EDIT_PROFILE = "company product edit profile";
export const COMPANY_CURRENCY_VIEW = "company currency view";
export const COMPANY_CURRENCY_EDIT = "company currency edit";
export const COMPANY_STOCK_VIEW = "company stock view";
export const COMPANY_STOCK_EDIT = "company stock edit";
export const COMPANY_STOCK_EDIT_TRANSFER = "company stock edit transfer";
export const COMPANY_SMTP_SETTINGS_EDIT = "company smtp edit";
// EMPLOYEE
export const EMPLOYEE_VIEW = "employee view";
export const EMPLOYEE_ADD = "employee add";
export const EMPLOYEE_EDIT = "employee edit";
export const EMPLOYEE_ACTIVATE_OR_DEACTIVATE = "employee edit";
export const EMPLOYEE_INVITATION_CANCEL = "employee delete";
// BRANCH
export const BRANCH_VIEW = "branch view";
export const BRANCH_ADD = "branch add";
export const BRANCH_EDIT = "branch edit";
export const BRANCH_ACTIVATE_OR_DEACTIVATE = "branch edit";
// GROUP
export const BRANCH_GROUP_VIEW = "branch group view";
export const BRANCH_GROUP_ADD = "branch group add";
export const BRANCH_GROUP_EDIT = "branch group edit";
// ROLE
export const ROLE_VIEW = "role view";
export const ROLE_ADD = "role add";
export const ROLE_EDIT = "role edit";
export const ROLE_DELETE = "role delete";
// ORGANISATION STRUCTURE
export const ORGANISATION_STRUCTURE_VIEW = "organisation structure view";
// SERIAL NUMBER
export const SERIAL_NUMBER_VIEW = "serial number view";
export const SERIAL_NUMBER_EDIT = "serial number edit";
export const SERIAL_NUMBER_GENERATE = "serial number generate";
export const SERIAL_NUMBER_UNASSIGN = "serial number unassign";
export const SERIAL_NUMBER_BATCH_VERIFICATION_EDIT =
  "serial number batch verification edit";
export const SERIAL_NUMBER_BATCH_AUTHENTICATOR_EDIT =
  "serial number batch app authenticator control";
// SERIAL NUMBER PATTERN
export const SERIAL_NUMBER_PATTERN_VIEW = "serial number view";
export const SERIAL_NUMBER_PATTERN_ADD = "serial number generate";
// PRODUCT
export const PRODUCT_VIEW = "product view";
export const PRODUCT_ADD = "product add";
export const PRODUCT_EDIT = "product edit";
export const PRODUCT_ASSIGN = "product assign";
export const PRODUCT_PRODUCT_LIST_VIEW = "product product list view";
export const PRODUCT_PRODUCT_LIST_ADD = "product product list add";
export const PRODUCT_PRODUCT_LIST_EDIT = "product product list edit";
export const PRODUCT_PRODUCT_LIST_DELETE = "product product list delete";
export const PRODUCT_PRODUCT_LIST_ASSIGN = "product product list assign";
export const PRODUCT_PRODUCT_LIST_EDIT_TEMPLATE =
  "product product list edit template";
export const PRODUCT_CATEGORY_VIEW = "product category view";
export const PRODUCT_CATEGORY_ADD = "product category add";
export const PRODUCT_CATEGORY_EDIT = "product category edit";
export const PRODUCT_CATEGORY_DELETE = "product category delete";
export const PRODUCT_BRAND_MODEL_VIEW = "product brand model view";
export const PRODUCT_BRAND_MODEL_ADD = "product brand model add";
export const PRODUCT_BRAND_MODEL_EDIT = "product brand model edit";
export const PRODUCT_BRAND_MODEL_DELETE = "product brand model delete";
export const PRODUCT_ATTRIBUTE_VIEW = "product attribute view";
export const PRODUCT_ATTRIBUTE_ADD = "product attribute add";
export const PRODUCT_ATTRIBUTE_EDIT = "product attribute edit";
export const PRODUCT_ATTRIBUTE_DELETE = "product attribute delete";
export const PRODUCT_TEMPLATE_VIEW = "product template view";
export const PRODUCT_TEMPLATE_ADD = "product template add";
export const PRODUCT_TEMPLATE_EDIT = "product template edit";
export const PRODUCT_TEMPLATE_DELETE = "product template delete";
// STOCKFLOW
export const STOCKFLOW_VIEW = "stockflow view";
export const STOCKFLOW_ADD = "stockflow add";
export const STOCKFLOW_EDIT = "stockflow edit";
export const STOCKFLOW_DEALER_VIEW = "stockflow dealer view";
export const STOCKFLOW_DEALER_ADD = "stockflow dealer add";
export const STOCKFLOW_DEALER_EDIT = "stockflow dealer edit";
export const STOCKFLOW_INVENTORY_VIEW = "stockflow inventory view";
export const STOCKFLOW_ORDER_VIEW = "stockflow order view";
export const STOCKFLOW_STOCKOUT_VIEW = "stockflow stockout view";
export const STOCKFLOW_TRANSFER_VIEW = "stockflow transfer view";
export const STOCKFLOW_RECEIVE_TRANSFER_VIEW =
  "stockflow receive transfer view";
export const STOCKFLOW_REQUEST_TRANSFER_VIEW =
  "stockflow request transfer view";
export const STOCKFLOW_REQUEST_TRANSFER_EDIT =
  "stockflow request transfer edit";
export const STOCKFLOW_RECALL_VIEW = "stockflow recall view";
export const STOCKFLOW_CHECKOUT_VIEW = "stockflow checkout view";
export const STOCKFLOW_STOCKTAKE_VIEW = "stockflow stock take view";

export const STOCKFLOW_STOCKTAKE_EDIT = "stockflow stock take edit";
export const STOCKFLOW_DEALER_INCENTIVE_PROGRAM_VIEW =
  "stockflow dealer incentive program view";
export const STOCKFLOW_DEALER_INCENTIVE_PROGRAM_ADD =
  "stockflow dealer incentive program add";
export const STOCKFLOW_DEALER_INCENTIVE_PROGRAM_EDIT =
  "stockflow dealer incentive program edit";
export const STOCKFLOW_REWARD_CENTER_VIEW = "stockflow reward center view";
export const STOCKFLOW_REWARD_CENTER_ADD = "stockflow reward center add";
export const STOCKFLOW_REWARD_CENTER_EDIT = "stockflow reward center edit";
export const STOCKFLOW_DELIVERY_INFO_EDIT = "stockflow delivery info edit";
export const STOCKFLOW_DAILY_STOCKOUT_SUMMARY_VIEW =
  "stockflow daily stockout summary view";
export const STOCKFLOW_DAILY_PRODUCT_STOCKOUT_SUMMARY_VIEW =
  "stockflow daily stockout product summary view";
// CREDIT
export const MARKETPLACE_VIEW = "marketplace view";
export const QR_CODE_CREDIT_PURCHASE = "marketplace serial_number";
export const SMS_CREDIT_PURCHASE = "marketplace sms";
// CONSUMER
export const CONSUMER_VIEW = "consumer view";
// COUNTERFEIT REPORT
export const COUNTERFEIT_REPORT_VIEW = "counterfeit report view";
export const COUNTERFEIT_REPORT_EDIT = "counterfeit report edit";
// SURVEY
export const SURVEY_VIEW = "campaign survey view";
export const SURVEY_ADD = "campaign survey add";
export const SURVEY_EDIT = "campaign survey edit";
export const SURVEY_ACTIVATE_OR_DEACTIVATE = "campaign survey edit";
// LUCKY DRAW
export const LUCKY_DRAW_VIEW = "campaign lucky draw view";
export const LUCKY_DRAW_ADD = "campaign lucky draw add";
export const LUCKY_DRAW_EDIT = "campaign lucky draw edit";
export const LUCKY_DRAW_ACTIVATE_OR_DEACTIVATE = "campaign lucky draw edit";
// LUCKY DRAW V2
export const LUCKY_DRAW_V2_VIEW = "campaignv2 lucky draw view";
export const LUCKY_DRAW_V2_ADD_EDIT = "campaignv2 lucky draw add or edit";
export const LUCKY_DRAW_V2_DELETE = "campaignv2 lucky draw delete";
// INSTANT LUCKY DRAW
export const INSTANT_LUCKY_DRAW_VIEW = "campaign instant lucky draw view";
export const INSTANT_LUCKY_DRAW_ADD = "campaign instant lucky draw add";
export const INSTANT_LUCKY_DRAW_EDIT = "campaign instant lucky draw edit";
export const INSTANT_LUCKY_DRAW_PRIZE_DELETE =
  "campaign instant lucky draw delete prize";
export const INSTANT_LUCKY_DRAW_ACTIVATE_OR_DEACTIVATE =
  "campaign instant lucky draw edit";
// LOYALTY PROGRAM
export const LOYALTY_PROGRAM_VIEW = "campaign loyalty program view";
export const LOYALTY_PROGRAM_ADD = "campaign loyalty program add";
export const LOYALTY_PROGRAM_EDIT = "campaign loyalty program edit";
export const LOYALTY_PROGRAM_ACTIVATE_OR_DEACTIVATE =
  "campaign loyalty program edit";
// BONANZA
export const BONANZA_VIEW = "campaign loyalty program bonanza view";
export const BONANZA_ADD = "campaign loyalty program bonanza add";
export const BONANZA_EDIT = "campaign loyalty program bonanza edit";
export const BONANZA_ACTIVATE_OR_DEACTIVATE =
  "campaign loyalty program bonanza edit";
// WARANTY
export const WARRANTY_VIEW = "campaign warranty view";
export const WARRANTY_ADD = "campaign warranty add";
export const WARRANTY_EDIT = "campaign warranty edit";
export const WARRANTY_LIST_VIEW = "campaign warranty list view";
export const WARRANTY_LIST_ADD = "campaign warranty list add";
export const WARRANTY_LIST_EDIT = "campaign warranty list edit";
export const WARRANTEE_LIST_VIEW = "campaign warrantee list view";
export const WARRANTEE_LIST_ADD = "campaign warrantee list add";
export const WARRANTEE_LIST_EDIT = "campaign warrantee list edit";
export const CLAIM_LIST_VIEW = "campaign claim list view";
export const CLAIM_LIST_EDIT = "campaign claim list edit";
export const ITEM_REPLACEMENT_LIST_VIEW = "campaign item replacement list view";
export const ITEM_REPLACEMENT_LIST_EDIT = "campaign item replacement list edit";
export const REIMBURSEMENT_LIST_VIEW = "campaign reimbursement list view";
export const REIMBURSEMENT_LIST_EDIT = "campaign reimbursement list edit";
export const REIMBURSEMENT_LIST_ADD = "campaign reimbursement list add";
export const REIMBURSEMENT_LIST_APPROVE = "campaign reimbursement list approve";
export const ITEM_REPLACEMENT_HANDOFF =
  "campaign item replacement list handoff";
export const WARRANTY_PRE_REGISTER_EDIT = "campaign warranty pre register";
export const WARRANTY_VALIDATE_DO_EDIT = "campaign warranty validate do number";
// GIFT
export const GIFT_VIEW = "redemption center gift view";
export const GIFT_ADD = "redemption center gift add";
export const GIFT_EDIT = "redemption center gift edit";
export const GIFT_ACTIVATE_OR_DEACTIVATE = "redemption center gift edit";
// GIFT REDEMPTION
export const GIFT_REDEMPTION_VIEW = "redemption center view";
export const GIFT_REDEMPTION_EDIT = "redemption center edit";
// POINT CUT
export const POINT_CUT_VIEW = "redemption center point cut view";
export const POINT_CUT_ADD = "redemption center point cut add";
export const POINT_CUT_EDIT = "redemption center point cut edit";
export const POINT_CUT_ACTIVATE_OR_DEACTIVATE =
  "redemption center point cut edit";
// REPORTING
export const REPORTING_VIEW = "reporting view";
// STOCK SUMMARY
export const STOCK_SUMMARY_VIEW = "stock summary view";
// ADDITIONAL INFORMATION
export const ADDITIONAL_INFO_VIEW = "additional info view";
export const ADDITIONAL_INFO_ADD = "additional info add";
export const ADDITIONAL_INFO_EDIT = "additional info edit";
// STOCK MOILE SETTING
export const NOTIFICATION_CUSTOM_RECEIVE_EMAIL =
  "notification custom receive email";
export const DKSH_INTEGRATION_SETTING_VIEW = "dksh integration setting view";
export const DKSH_INTEGRATION_SETTING_EDIT = "dksh integration setting edit";
export const DKSH_INVOICE_VIEW = "dksh invoice view";
export const DKSH_PRDUCT_LIST_VIEW = "dksh product list view";
export const DKSH_PRDUCT_IMPORT = "dksh product import";
export const PRODUCT_LANDING_TEMPLATE_ADD_EDIT =
  "product landing template add edit";
export const PRODUCT_LANDING_TEMPLATE_DELETE =
  "product landing template delete";
export const PRODUCT_LANDING_TEMPLATE_DE = "product landing delete";

// PRIFE PURCHASE ORDER
export const PRIFE_PURCHASE_ORDER_VIEW = "prife purchase order view";
export const PRIFE_PURCHASE_ORDER_ADD = "prife purchase order add";
export const PRIFE_PURCHASE_ORDER_EDIT = "prife purchase order edit";
export const PRIFE_PURCHASE_ORDER_NOTIFICATION_SETTINGS_EDIT =
  "prife purchase order notification settings edit";

// MESSAGE BLASTING
export const MESSAGE_BLASTING_VIEW = "message blasting view";
export const MESSAGE_BLASTING_ADD = "message blasting add";
export const MESSAGE_BLASTING_EDIT = "message blasting edit";
export const MESSAGE_BLASTING_DELETE = "message blasting delete";

// PRIFE
export const PRIFE_CHECKOUT_VIEW = "prife checkout view";
export const PRIFE_CHECKOUT_EDIT = "prife checkout edit";
export const PRIFE_CLAIM_VIEW = "prife claim view";
export const PRIFE_CLAIM_EDIT = "prife claim edit";
export const PRIFE_INTEGRATION_SETTING_VIEW = "prife integration setting view";
export const PRIFE_INTEGRATION_SETTING_EDIT = "prife integration setting edit";
export const PRIFE_MASTER_LIST_VIEW = "prife master list view";
export const PRIFE_MASTER_LIST_HQ_PIC = "prife master list hq pic";
export const PRIFE_MASTER_LIST_ADD_PURCHASE_ORDER =
  "prife master list add purchase order";
export const PRIFE_MASTER_LIST_HQ_APPROVAL = "prife master list hq approval";
export const PRIFE_MASTER_LIST_FINANCE_APPROVAL =
  "prife master list finance approval";
export const PRIFE_SHIPPING_ORDER_VIEW = "prife shipping order view";
export const PRIFE_SHIPPING_ORDER_EDIT = "prife shipping order edit";
export const PRIFE_DISPATCH_INSTRUCTION_VIEW =
  "prife dispatch instruction view";
export const PRIFE_DISPATCH_INSTRUCTION_APPROVE =
  "prife dispatch instruction approve";
export const PRIFE_RECEIVE_TRANSFER_VIEW = "prife receive transfer view";
export const PRIFE_REPLACEMENT_VIEW = "prife replacement view";
export const PRIFE_REPLACEMENT_EDIT = "prife replacement edit";

export const WHATSAPP_MESSAGE_TEMPLATE_VIEW = "whatsapp message template view";
export const WHATSAPP_MESSAGE_TEMPLATE_ADD = "whatsapp message template add";
export const WHATSAPP_MESSAGE_TEMPLATE_EDIT = "whatsapp message template edit";
export const WHATSAPP_MESSAGE_TEMPLATE_DELETE =
  "whatsapp message template delete";
