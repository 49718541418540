import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  ButtonBase
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AntSwitch from "components/switch";
import { EditImg } from "../../../utils/static";
import { TablePaginationActions } from "components/tablePagination";
import { HelpRounded, ArrowUpward } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { NODE_STATUS_ACTIVE } from "modules/account/constants";
import { StyledTableCell, StyledTableRow } from "components/table";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import BranchIcon from "../../../../../components/icon/branchIcon";

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },

}));

function SkeletonTableRow({ numberOfColumn = 7 }) {
  return (
    <StyledTableRow>
      {
        Array(numberOfColumn).fill(0).map(
          (array, index) => (
            <StyledTableCell index={index} key={index}>
              <Skeleton variant="text" animation="wave" />
            </StyledTableCell>
          )
        )
      }
    </StyledTableRow>
  );
}

export default function BranchTableComponent({
  hasBranchAddAccessRight,
  hasBranchEditAccessRight,
  hasBranchActivateOrDeactivateAccessRight,
  isLoading,
  branchList,
  page,
  search,
  totalRecords,
  rowsPerPage,
  handlePageChange,
  handleRowPerPageChange,
  openCreateModal,
  handleEditClick,
  handleStatusChange,
  handleReload,
  isLoadingBranchError,
  paginationOptions,
  lang
}) {
  const classes = useStyle();

  let numberOfColumn = 7
  if (!hasBranchEditAccessRight) {
    numberOfColumn = numberOfColumn - 1
  }

  return (
    <>
      {branchList.length > 0 || isLoading ? (
        <>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.BRANCH_NAME")}</StyledTableCell>
                  <StyledTableCell style={{ display: "flex" }}>
                    {getLang(lang, "label.OWNED_BY")}
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.CONTACT")}</StyledTableCell>
                  <StyledTableCell style={{ display: "flex" }}>
                    {getLang(lang, "label.EMAIL")}
                    {/* <Button className={classes.button}>
                      <ArrowUpward style={{ fontSize: "small" }} />
                    </Button> */}
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                  {hasBranchEditAccessRight ? (
                    <StickyRightTableCell>
                      <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                    </StickyRightTableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  branchList.map((row, index) => {
                    index++;
                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StickyLeftTableCell>
                          <StyledTableCell component="th" scope="row" style={{ borderBottom: "0px" }}>
                            {index}
                          </StyledTableCell>
                        </StickyLeftTableCell>
                        <StyledTableCell>
                          <Box display="flex" alignItems="center">
                            <Box>
                              <BranchIcon url={row.profile_image_url}/>
                            </Box>
                            <Box p={1}>
                              {row.name}
                            </Box>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>{row.parent_name}</StyledTableCell>
                        <StyledTableCell>{row.contact_code}{row.contact}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell>
                          <AntSwitch
                            disabled={!hasBranchActivateOrDeactivateAccessRight}
                            checked={row.status === NODE_STATUS_ACTIVE}
                            onChange={() =>
                              handleStatusChange(row.id, row.status)
                            }
                          />
                        </StyledTableCell>
                        {hasBranchEditAccessRight ? (
                          <StickyRightTableCell>
                            <StyledTableCell>
                              <Box
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <ButtonBase
                                  className={classes.iconBtn}
                                  onClick={() =>
                                    handleEditClick({
                                      id: row.id,
                                      parentBranch: row.parent_id,
                                      parentName: row.parent_name,
                                      branchName: row.name,
                                      shortName: row.short_name,
                                      description: row.description,
                                      addressLine1: row.line1,
                                      addressLine2: row.line2,
                                      city: row.city,
                                      country: row.country,
                                      postalCode: row.postal_code,
                                      state: row.state,
                                      contact: row.contact,
                                      contactCode: row.contact_code,
                                      email: row.email,
                                      timezone: row.timezone,
                                      branchGroup: row.branch_group,
                                      profileImageUrl: row.profile_image_url,
                                      socialMediaUrls: row.social_media_urls,
                                      isShowContact: row.is_show_contact === 1,
                                      isShowEmail: row.is_show_email === 1,
                                      whiteLabel: row.white_label,
                                      landingPageLanguage: row.landing_page_language,
                                      customField: row.custom_field,
                                    })
                                  }
                                >
                                  <EditImg />
                                </ButtonBase>
                              </Box>
                            </StyledTableCell>
                          </StickyRightTableCell>
                        ) : null}
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <>
                    <SkeletonTableRow numberOfColumn={numberOfColumn} />
                    <SkeletonTableRow numberOfColumn={numberOfColumn} />
                    <SkeletonTableRow numberOfColumn={numberOfColumn} />
                    <SkeletonTableRow numberOfColumn={numberOfColumn} />
                    <SkeletonTableRow numberOfColumn={numberOfColumn} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowPerPageChange}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </>
      ) : (
        !search && !isLoadingBranchError && (
          <Box>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                  <StyledTableCell style={{ display: "flex" }}>
                    {getLang(lang, "label.OWNED_BY")}
                    <Button className={classes.button}>
                      <HelpRounded style={{ fontSize: "medium" }} />
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.CONTACT")}</StyledTableCell>
                  <StyledTableCell style={{ display: "flex" }}>
                    {getLang(lang, "label.EMAIL")}
                    <Button className={classes.button}>
                      <ArrowUpward style={{ fontSize: "small" }} />
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                  {hasBranchEditAccessRight ? (
                    <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                  ) : null}
                </TableRow>
              </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
              <Typography variant="body1">
                {getLang(lang, "paragraph.NO_BRANCH")}
              </Typography>
              {hasBranchAddAccessRight ? (
                <Box mt={2}>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={openCreateModal}
                  >
                    {getLang(lang, "label.ADD_BRANCH")}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Box>

        )
      )}

      {!isLoading && isLoadingBranchError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isLoading && branchList.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_BRANCH_EXIST")}</Alert>
      )}
    </>
  );
}
