import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainPageComponent from "./dashboard.page";
import { SIGN_UP_STEPS } from "modules/private/config/route";
import { isCompanyApproved } from "modules/private/redux/selector";
import { useHistory } from "react-router-dom";
import { datetimeValidation } from "modules/dashboard/utils/helper";
import moment from "moment";
import { updateUrlQueryParam } from "lib/helper";
import { dateType } from "modules/dashboard/constants";
import { resetDashboard } from "modules/stockflow/redux";
import { checkMaintenanceAlert } from "modules/admin/redux";
import ConfirmationDialog from 'components/dialog/confirmation';
import { getApiLang, getLang } from "app/feature/constants";
import authApi from "app/api/authentication";
import { addAlert } from "modules/notification";
import { Link } from "@material-ui/core";

export const DashboardContext = createContext();

function MainPageContainer() {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.profile.companyID);
  const query = new URLSearchParams(window.location.search);
  const period = query.get("period")
    ? query.get("period")
    : dateType.LAST_14_DAYS;
  const date_start = query.get("start")
    ? datetimeValidation(query.get("start"), query.get("end"))[0]
    : new Date(Date.now() - 13 * 24 * 60 * 60 * 1000);
  const date_end = query.get("end")
    ? datetimeValidation(query.get("start"), query.get("end"))[1]
    : new Date();

  const isDev = query.get("dev") ? parseInt(query.get("dev")) : null;

  const [currentFilter, setCurrentFilter] = useState({
    period: period,
    dateRange: [date_start, date_end],
    isDev: isDev === 1 ? 1 : null,
  });
  const [activeIndex, setActiveIndex] = useState(
    period === dateType.LAST_14_DAYS
      ? 0
      : period === dateType.TODAY
      ? 1
      : period === dateType.THIS_MONTH
      ? 2
      : period === dateType.LAST_MONTH
      ? 3
      : period === dateType.CUSTOM
      ? 4
      : 0
  );
  const [popupIsShow, setPopUpIsShow] = useState({
    serialNumberFirstScan: false,
    uniqueConsumer: false,
    newConsumer: false,
  });

  const isCompanyApprove = useSelector((state) => isCompanyApproved(state));
  const showMaintenanceAlert = useSelector(
    (state) => state.session.showMaintenanceAlert
  );
  const lang = useSelector((state) => state.constant.languages);
  const history = useHistory();

  const showPopup = (name) => {
    setPopUpIsShow({
      ...popupIsShow,
      [name]: true,
    });
  };

  const closePopup = (name) => {
    setPopUpIsShow({
      ...popupIsShow,
      [name]: false,
    });
  };

  const PRIVACY_POLICY_URL = process.env.REACT_APP_KOOD_ASIA_PRIVACY_POLICY_URL;
  const USER_AGREEMENT_URL = process.env.REACT_APP_KOOD_ASIA_USER_AGREEMENT_URL;
  const CODE_OF_CONDUCT_URL = process.env.REACT_APP_KOOD_ASIA_CODE_OF_CONDUCT_URL;

  const userUuid = useSelector((state) => state.profile.id);
  const [isAcceptNewAgreementOpen, setIsAcceptNewAgreementOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userUuid) {
      authApi.checkAcceptNewAgreement(userUuid).then((response) => {
        if (parseInt(response.data.is_accept_new_agreement) !== 1) {
          setIsAcceptNewAgreementOpen(true);
        }
      }).catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUuid]);

  useEffect(() => {
    dispatch(checkMaintenanceAlert());
    return () => {
      dispatch(resetDashboard());
    };
  }, [dispatch]);

  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (!initialRenderRef.current) {
      const query = new URLSearchParams(window.location.search);
      let start,
        end = null;
      let period = dateType.LAST_14_DAYS;

      switch (activeIndex) {
        case 0:
          start = moment().subtract(14, "days").toDate();
          end = moment().toDate();
          period = dateType.LAST_14_DAYS;
          break;
        case 1:
          start = moment().toDate();
          end = moment().toDate();
          period = dateType.TODAY;
          break;
        case 2:
          start = moment().startOf("month").toDate();
          end = moment().endOf("month").toDate();
          period = dateType.THIS_MONTH;
          break;
        case 3:
          start = moment().subtract(1, "month").startOf("month").toDate();
          end = moment().subtract(1, "month").endOf("month").toDate();
          period = dateType.LAST_MONTH;
          break;
        case 4:
          start = query.get("start")
            ? moment(query.get("start")).toDate()
            : moment().subtract(14, "days").toDate();
          end = query.get("end")
            ? moment(query.get("end")).toDate()
            : moment().toDate();
          period = dateType.CUSTOM;
          break;
        default:
          start = moment().subtract(14, "days").toDate();
          end = moment().toDate();
          period = dateType.LAST_14_DAYS;
          break;
      }

      setCurrentFilter({
        ...currentFilter,
        period,
        dateRange: [start, end],
      });

      updateUrlQueryParam({
        period: period,
        start: moment(start || currentFilter.dateRange[0]).format("YYYY-MM-DD"),
        end: moment(end || currentFilter.dateRange[1]).format("YYYY-MM-DD"),
      });
    }
    initialRenderRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    if (!isCompanyApprove) {
      history.push(SIGN_UP_STEPS);
    }
  }, [isCompanyApprove, history]);

  const updateFilterDate = (start, end) => {
    let startFrom = moment(start || currentFilter.dateRange[0]);
    let endAt = moment(end || currentFilter.dateRange[1]);

    updateUrlQueryParam({
      start: startFrom.format("YYYY-MM-DD"),
      end: endAt.format("YYYY-MM-DD"),
    });

    if (activeIndex === 4) {
      setCurrentFilter({
        ...currentFilter,
        dateRange: [startFrom.toDate(), endAt.toDate()],
      });
    } else {
      setActiveIndex(4);
    }
  };

  const handleAgree = ({ userUuid }) => {
    setIsLoading(true);
    authApi.acceptNewAgreement(userUuid).then(() => {
      setIsAcceptNewAgreementOpen(false);
    }).catch((error) => {
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, error.code),
        })
      );
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        currentFilter,
        activeIndex,
        showPopup,
        closePopup,
        popupIsShow,
      }}
    >
      <MainPageComponent
        showMaintenanceAlert={showMaintenanceAlert}
        currentFilter={currentFilter}
        updateFilterDate={updateFilterDate}
        lang={lang}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        companyId={companyId}
      />
      <ConfirmationDialog
        isLoading={isLoading}
        isOpen={isAcceptNewAgreementOpen}
        handleClose={() => setIsAcceptNewAgreementOpen(false)}
        handleProceed={() => handleAgree({ userUuid })}
        description={
          <>
            {getLang(lang, "paragraph.NEW_AGREEMENT_NOTICE")}
            <Link href={PRIVACY_POLICY_URL} target="_blank" style={{ color: 'blue' }}>&nbsp;
              {getLang(lang, "label.PRIVACY_POLICY")}
            </Link>,&nbsp;
            <Link href={USER_AGREEMENT_URL} target="_blank" style={{ color: 'blue' }}>
              {getLang(lang, "label.TERMS")}
            </Link>&nbsp;
            {getLang(lang, "paragraph.AND")}&nbsp;
            <Link href={CODE_OF_CONDUCT_URL} target="_blank" style={{ color: 'blue' }}>
              {getLang(lang, "label.CODE_OF_CONDUCT")}
            </Link>
          </>
        }
        title={getLang(lang, "label.NEW_AGREEMENT_NOTICE_TITLE") + " 1st March 2025"}
        proceedLabel={getLang(lang, "label.ACKNOWLEDGE_AND_PROCEED")}
        rejectOption={false}
        textAlignLeft={true}
        descriptionLineBreak={true}
        maxWidth="sm"
      />
    </DashboardContext.Provider>
  );
}

export default MainPageContainer;
