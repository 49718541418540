import React, {useState} from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  Button,
  MenuItem,
  MenuList
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import InputTextField from "../../../../../components/input/inputTextField";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 435
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddRole: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  }
}));

export default function RolePanelComponent({
  hasRoleAddAccessRight,
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  handleAddRole,
  roleDropdown,
  isFetching,
  lang
}) {
  const classes = useStyle();

  const [search, setSearch] = useState("");

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <PerfectScrollbar>
                <InputTextField
                  variant="filled"
                  size="small"
                  fullWidth
                  onChange={(event) => setSearch(event.target.value)}
                  InputProps={{
                    style: {
                      backgroundColor: "#fff",
                      paddingRight: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      padding: "8px"
                    }
                  }}
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                />
                <Divider />
                <Box height="15vh" overflow="auto">
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" height={"20%"} />
                      <Skeleton variant="text" animation="wave" height={"20%"} />
                      <Skeleton variant="text" animation="wave" height={"20%"} />
                      <Skeleton variant="text" animation="wave" height={"20%"} />
                      <Skeleton variant="text" animation="wave" height={"20%"} />
                    </>
                  ) : (
                    roleDropdown.filter(e => e.name.toLowerCase().includes(search.toLowerCase())).map((field, index) => {
                      return (
                        <Box key={index}>
                          <MenuList
                            autoFocusItem={isOpenList}
                            id="menu-list-grow"
                            onClick={() => handleListKeyDown(field)}
                          >
                            <MenuItem>
                              <Typography variant="inherit" noWrap>
                                {field.name}
                              </Typography>
                            </MenuItem>
                          </MenuList>
                        </Box>
                      );
                    })
                  )}
                </Box>
                {

                  hasRoleAddAccessRight
                    ?
                    <>
                      <Divider />
                      <Box className={classes.createLink}>
                        <Button
                          color="primary"
                          className={classes.buttonAddRole}
                          onClick={handleAddRole}
                        >
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ fontWeight: "bold", paddingRight: "4px" }}
                          >
                            {getLang(lang, "label.ADD_NEW_ROLE")}
                          </Typography>
                          <ArrowForwardIcon fontSize="small" />
                        </Button>
                      </Box>
                    </>
                    : null
                }
              </PerfectScrollbar>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
