import { createSlice } from "@reduxjs/toolkit";
import {
  getCheckoutBoxList,
  getCheckoutDeliveryInfo,
  getCheckoutDetail,
  getCheckoutProductDetail, getCheckoutProductList,
  getCompletedCheckoutListing,
  getPendingCheckoutListing
} from "./integration.action";

const initialState = {
  checkout: {
    productList: {
      list: [],
      totalFiltered: 0,
      totalRecords: 0,
      isError: false,
      hasNextPage: true,
    },
    boxList: {
      list: [],
      totalFiltered: 0,
      totalRecords: 0,
      isError: false,
      hasNextPage: true,
    },
    productSnList: {
      list: [],
      totalFiltered: 0,
      totalRecords: 0,
      isError: false,
      hasNextPage: true,
    },
  },
  deliveryInfo: {
    list: [],
    isLoading: false,
    isError: false,
  },
};

const integrationSlice = createSlice({
  name: 'Integration',
  initialState,
  reducers: {
    clear: (state) => {
      state.checkout = initialState.checkout;
    },
    clearDetail: (state) => {
      state.checkout.detail = null;
    },
    resetProductSnList(state) {
      state.productSnList = initialState.productSnList;
    },
    resetCheckoutByDropdown(state) {
      state.checkoutInitiatorDropdown = initialState.checkoutInitiatorDropdown;
    },
    updateCheckoutInfo(state, action) {
      state.checkout.detail = {
        ...state.checkout.detail,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [getPendingCheckoutListing.pending]: (state) => {
      state.checkout.isFetchingListError = false;
    },
    [getPendingCheckoutListing.fulfilled]: (state, action) => {
      state.checkout.list = action.payload;
    },
    [getPendingCheckoutListing.rejected]: (state) => {
      state.checkout.isFetchingListError = true;
    },
    [getCompletedCheckoutListing.pending]: (state) => {
      state.checkout.isFetchingCompletedListError = false;
    },
    [getCompletedCheckoutListing.fulfilled]: (state, action) => {
      state.checkout.completedList = action.payload;
    },
    [getCompletedCheckoutListing.rejected]: (state) => {
      state.checkout.isFetchingCompletedListError = true;
    },
    [getCheckoutDetail.fulfilled]: (state, action) => {
      state.checkout.detail = action.payload;
    },
    [getCheckoutBoxList.pending]: (state) => {
      state.checkout.boxList.isError = false;
    },
    [getCheckoutBoxList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.checkout.boxList.hasNextPage) {
        state.checkout.boxList.list = [...state.checkout.boxList.list, ...payload.data];
      }
      state.checkout.boxList.totalRecords = payload.recordsTotal;
      state.checkout.boxList.totalFiltered = payload.recordsFiltered;
      state.checkout.boxList.hasNextPage =
        state.checkout.boxList.list.length < state.checkout.boxList.totalFiltered;
    },
    [getCheckoutBoxList.rejected]: (state) => {
      state.checkout.boxList.isError = true;
    },
    [getCheckoutProductList.pending]: (state) => {
      state.checkout.productList.isError = false;
    },
    [getCheckoutProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.checkout.productList.hasNextPage) {
        state.checkout.productList.list = [...state.checkout.productList.list, ...payload.data];
      }
      state.checkout.productList.totalRecords = payload.recordsTotal;
      state.checkout.productList.totalFiltered = payload.recordsFiltered;
      state.checkout.productList.hasNextPage =
        state.checkout.productList.list.length < state.checkout.productList.totalFiltered;
    },
    [getCheckoutProductList.rejected]: (state) => {
      state.checkout.productList.isError = true;
    },
    [getCheckoutProductDetail.pending]: (state) => {
      state.checkout.productSnList.isError = false;
    },
    [getCheckoutProductDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.checkout.productSnList.hasNextPage) {
        state.checkout.productSnList.list = [
          ...state.checkout.productSnList.list,
          ...payload.data,
        ];
      }
      state.checkout.productSnList.totalRecords = payload.recordsTotal;
      state.checkout.productSnList.totalFiltered = payload.recordsFiltered;
      state.checkout.productSnList.hasNextPage =
        state.checkout.productSnList.list.length < payload.recordsFiltered;
    },
    [getCheckoutProductDetail.rejected]: (state) => {
      state.checkout.productSnList.isError = true;
    },
    [getCheckoutDeliveryInfo.pending]: (state) => {
      state.deliveryInfo.isLoading = true;
      state.deliveryInfo.list = [];
      state.deliveryInfo.isError = false;
    },
    [getCheckoutDeliveryInfo.fulfilled]: (state, action) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.list = action.payload;
    },
    [getCheckoutDeliveryInfo.rejected]: (state) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.isError = true;
    },
  },
});

export const { clear, clearDetail, resetProductSnList, resetCheckoutByDropdown, updateCheckoutInfo } = integrationSlice.actions;
export const integrationReducer = integrationSlice.reducer;
