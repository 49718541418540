import React from "react";
import {
  Typography,
  Box,
  MenuItem,
  Popover,
  ClickAwayListener,
  Paper,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import RefreshTable from "components/table/refreshTable";

export default function RoleSelectPanelComponent({
  value,
  anchorEl,
  open,
  handleClose,
  isFetching,
  roles,
  handleSelectRole,
  isError,
  handleReload,
}) {
  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      elevation={2}
      PaperProps={{
        style: { maxWidth: "63vw", width: 400 },
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            {isFetching ? (
              <Box p={1.5}>
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </Box>
            ) : (
              <div>
                <Box maxHeight={isError ? "auto" : "25vh"} overflow="auto">
                  {isError ? (
                    <RefreshTable handleReload={handleReload} />
                  ) : (
                    <>
                      <MenuItem
                        style={{ padding: "8px 8px" }}
                        onClick={() => handleSelectRole(null)}
                      >
                        <Typography variant="inherit" noWrap>
                          -
                        </Typography>
                      </MenuItem>
                      {roles.map((role, index) => {
                        return (
                          <MenuItem
                            key={index}
                            style={{
                              padding: "8px 8px",
                              backgroundColor:
                                value?.uuid === role.uuid ? "#f5f5f5" : "",
                            }}
                            onClick={() => handleSelectRole(role)}
                          >
                            <Typography variant="inherit" noWrap>
                              {role.name}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </>
                  )}
                </Box>
              </div>
            )}
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
}
