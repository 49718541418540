import React from "react";
import { useSelector } from "react-redux";
import SetWinnerTableComponent from "./setWinnerTable.component";
import { LUCKY_DRAW_V2_ADD_EDIT } from "lib/constants/accessRights";
import { PRIZE_TYPE_PRESET } from "modules/lucky-draw-v2/constants";
export default function SetWinnerTableContainer({
  dialog,
  luckyDrawUuid,
  handleAddWinner,
  handleDelete,
  quantity,
  winners,
  handleChange,
  balance,
  index,
  lang,
  prizeList,
  prize,
  setPrizeList,
  excludeList,
  setExcludeList,
  isEditable,
  isUnlimited,
}) {
  const hasLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );
  const addWinner = async () => {
    handleAddWinner(index);
  };

  const handleAddWinnerIntoPrize = async (winnerIndex, serialNumber, enc) => {
    const info = {
      index: index,
      winnerIndex: winnerIndex,
      serialNumber: serialNumber,
      prize_type: PRIZE_TYPE_PRESET,
      enc: enc,
    };

    const updatedPrizeList = [...prizeList];
    const prize = updatedPrizeList[index];
    const updatedWinners = [...prize.winners];

    let count = 0;
    let actualWinnerIndex = 0;
    for (let i = 0; i < updatedWinners.length; i++) {
      if (
        updatedWinners[i].prize_type === PRIZE_TYPE_PRESET &&
        count === winnerIndex
      ) {
        actualWinnerIndex = i;
        break;
      }
      if (updatedWinners[i].prize_type === PRIZE_TYPE_PRESET) {
        count++;
      }
    }

    updatedWinners[actualWinnerIndex] = {
      ...updatedWinners[actualWinnerIndex],
      ...info,
    };
    updatedPrizeList[index] = { ...prize, winners: updatedWinners };

    let encs = updatedPrizeList.reduce((acc, prize) => {
      let winner = prize.winners;
      winner.forEach((element) => {
        if (element.enc) {
          acc.push({ enc: element.enc });
        }
      });

      return acc;
    }, []);

    setPrizeList(updatedPrizeList);
    setExcludeList(encs);
  };

  const deleteWinner = async (winnerIndex) => {
    const prize = prizeList[index];
    let fullWinnerList = [...prize.winners];

    let count = 0;
    let actualWinnerIndex = 0;
    for (let i = 0; i < fullWinnerList.length; i++) {
      if (
        fullWinnerList[i].prize_type === PRIZE_TYPE_PRESET &&
        count === winnerIndex
      ) {
        actualWinnerIndex = i;
        break;
      }
      if (fullWinnerList[i].prize_type === PRIZE_TYPE_PRESET) {
        count++;
      }
    }

    handleDelete(index, actualWinnerIndex);
  };

  return (
    <SetWinnerTableComponent
      luckyDrawUuid={luckyDrawUuid}
      hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
      dialog={dialog}
      quantity={quantity}
      winners={winners.map((winner) => {
        return {
          ...winner,
          serialNumber: winner.serialNumber,
        };
      })}
      handleChange={handleChange}
      balance={balance}
      handleAddWinner={addWinner}
      handleDelete={deleteWinner}
      handleAddWinnerIntoPrize={handleAddWinnerIntoPrize}
      lang={lang}
      prizeList={prizeList}
      prize={prize}
      excludeList={excludeList}
      isEditable={isEditable}
      isUnlimited={isUnlimited}
    />
  );
}
