import getMasterList from "./getMasterList.api";
import getOrderDetail from "./getOrderDetail.api";
import createSpecialRequest from "./createSpecialRequest.api";
import getShippingOrderListDropdown from "./getShippingOrderListDropdown.api";
import getProductListDropdown from "./getProductListDropdown.api";
import getBranchListDropdown from "./getBranchListDropdown.api";
import approvalOrder from "./approvalOrder.api";
import submitFinanceApproval from "./submitToFinance.api";
import getSoSelectedMLSummary from "./getSoSelectedMLSummary.api";
import getSoSelectedProductSummary from "./getSoSelectedProductSummary.api";
import validateSoRequest from "./validateSoRequest.api";
import createShippingOrder from "./createShippingOrder.api";
import updateSpecialRequest from "./updateSpecialRequest.api";
import getShippingOrderList from "./getShippingOrderList.api";
import getShippingOrderDetail from "./getShippingOrderDetail.api";
import updateSoReceiver from "./updateSoReceiver.api";
import getSoAttributeList from "./getSoAttributeList.api";
import getSoSenderList from "./getSoSenderList.api";
import addItemToProductOrder from "./addItemToProductOrder.api";
import getShippingOrderProductList from "./getShippingOrderProductList.api";
import getShippingOrderDispatchList from "./getShippingOrderDispatchList.api";
import generateDi from "./generateDi.api";
import updateDispatchInstruction from "./updateDispatchInstruction.api";
import getShippingOrderMasterList from "./getShippingOrderMasterList.api";
import { getCheckoutBoxList } from "./checkout/getCheckoutBoxList.api";
import { getCheckoutDeliveryInfo } from "./checkout/getCheckoutDeliveryInfo.api";
import getCheckoutDetail from "./checkout/getCheckoutDetail.api";
import { getCheckoutProductDetail } from "./checkout/getCheckoutProductDetail.api";
import { getCheckoutProductList } from "./checkout/getCheckoutProductList.api";
import getCompletedCheckoutListing from "./checkout/getCompletedCheckoutListing.api";
import getPendingCheckoutListing from "./checkout/getPendingCheckoutListing.api";
import { updateCheckoutDeliveryInfo } from "./checkout/updateCheckoutDeliveryInfo.api";
import getWarrantyClaimDetail from "./warranty-claim/getWarrantyClaim.api";
import handoffWarrantyClaim from "./warranty-claim/handoffWarrantyClaim.api";
import processWarrantyClaim from "./warranty-claim/processWarrantyClaim.api";
import getWarrantyClaimList from "./warranty-claim/getWarrantyClaimList.api";
import getKnockOffList from "./getKnockOffList.api";
import getDispatchInstructionList from "./getDispatchInstructionList.api";
import updateClaimDetail from "./warranty-claim/updateClaimDetail.api";
import getDispatchInstructionDetail from "./getDispatchInstructionDetail.api";
import getReceiveTransferDetail from "./getReceiveTransferDetail.api";
import getReceiveTransferListing from "./getReceiveTransferListing.api";
import exportDeliveryNote from "./exportDeliveryNote.api";
import exportDispatchInstruction from "./exportDispatchInstruction.api";
import finalizeShippingOrder from "./finalizeShippingOrder.api";
import getUnprocessMLRoleNotification from "./getUnprocessMLRoleNotification.api";

const customFeatureApi = {
  getMasterList,
  getOrderDetail,
  createSpecialRequest,
  getShippingOrderListDropdown,
  getProductListDropdown,
  getBranchListDropdown,
  approvalOrder,
  submitFinanceApproval,
  getSoSelectedMLSummary,
  getSoSelectedProductSummary,
  validateSoRequest,
  createShippingOrder,
  updateSpecialRequest,
  getShippingOrderList,
  getShippingOrderDetail,
  updateSoReceiver,
  getSoAttributeList,
  getSoSenderList,
  addItemToProductOrder,
  getShippingOrderProductList,
  getShippingOrderDispatchList,
  generateDi,
  updateDispatchInstruction,
  getShippingOrderMasterList,
  getKnockOffList,
  getDispatchInstructionList,
  getDispatchInstructionDetail,
  getReceiveTransferDetail,
  getReceiveTransferListing,
  exportDeliveryNote,
  exportDispatchInstruction,
  finalizeShippingOrder,

  // Checkout
  getCheckoutBoxList,
  getCheckoutDeliveryInfo,
  getCheckoutDetail,
  getCheckoutProductDetail,
  getCheckoutProductList,
  getCompletedCheckoutListing,
  getPendingCheckoutListing,
  updateCheckoutDeliveryInfo,

  // Warranty Claim
  getWarrantyClaimList,
  getWarrantyClaimDetail,
  handoffWarrantyClaim,
  processWarrantyClaim,

  getUnprocessMLRoleNotification,
  updateClaimDetail,
};

export default customFeatureApi;
