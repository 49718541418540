import React, { useEffect, useState } from "react";
import RoleDialogComponent from "./roleDialog.component";
import {
  closeRoleDialog,
  createRole,
  fetchAccessRightList,
  updateRole
} from "modules/role-access-right/redux";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { ROLE_ADD, ROLE_EDIT } from "lib/constants/accessRights";
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop";

export default function RoleDialogContainer({ handleReload }) {
  const dispatch = useDispatch();
  const currentUserRoleId = useSelector(state => state.profile.roleId);
  const hasRoleAddAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_ADD));
  const hasRoleEditAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_EDIT));
  const inheritAccessRights = useSelector(state => state.profile.accessRights);
  const roleAccessRightList = useSelector(
    (state) => state.roleAccessRight.accessRightList.data
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchAccessRightList.typePrefix)
  );
  const isOpen = useSelector(
    (state) => state.roleAccessRight.roleSetting.isOpen
  );
  const type = useSelector((state) => state.roleAccessRight.roleSetting.type);
  const roleInfo = useSelector((state) => state.roleAccessRight.roleInfo);
  const lang = useSelector(state => state.constant.languages);
  const [accessRole, setAccessRole] = useState(roleInfo.accessRightIds)
  
  useEffect(() => {
    setAccessRole(roleInfo.accessRightIds)
  }, [roleInfo]);

  const handleSubmit = async (values) => {
    const updateInfo = {
      id: roleInfo.id,
      name: values.roleName,
      access_rights: accessRole,
    };
    if (type === "create") {
      if (hasRoleAddAccessRight) {
        dispatch(showBackdrop());
        await dispatch(createRole(updateInfo))
          .then(unwrapResult)
          .then(() => {
            handleReload();
            dispatch(closeRoleDialog());
          })
          .finally(() => {
            dispatch(hideBackdrop());
          });
      }
    } else {
      if (hasRoleEditAccessRight) {
        dispatch(showBackdrop());
        await dispatch(updateRole(updateInfo))
          .then(unwrapResult)
          .then(() => {
            handleReload();
            dispatch(closeRoleDialog());
          })
          .finally(() => {
            dispatch(hideBackdrop());
          });
      }
    }
    setAccessRole([])
  };

  useEffect(() => {
    if (isOpen) dispatch(fetchAccessRightList());
  }, [dispatch, isOpen]);

  const setAccess = (id) => {
    if (id.isGroupCheck) {
      id.infos.forEach((info) => {
        if (!accessRole.includes(info)) {
          setAccessRole(accessRole => [...accessRole, info]);
        }
      })
    }
    else if (accessRole.includes(id)) {
      setAccessRole(currentAccessRole => currentAccessRole.filter((value) => value !== id))
    }
    else {
      setAccessRole([...accessRole, id]);
    }
  };


  const handleSelectAll = () => {
    let allRole = [];
    roleAccessRightList.map((groups) =>
      groups.subgroup.map((subgroups) =>
        subgroups.details.forEach((detail) => {
          allRole.push(detail.id)
        })
      )
    )
    setAccessRole([])
    setAccessRole(allRole)
  }

  const handleDeselectAll = () => {
    setAccessRole([])
  }
  const handleClose = () => {
    setAccessRole([])
    dispatch(closeRoleDialog())
  }

  return (
    <RoleDialogComponent
      currentUserRoleId={currentUserRoleId}
      hasRoleAddAccessRight={hasRoleAddAccessRight}
      hasRoleEditAccessRight={hasRoleEditAccessRight}
      inheritAccessRights={inheritAccessRights}
      isOpen={isOpen}
      type={type}
      roleInfo={roleInfo}
      handleClose={handleClose}
      roleAccessRightList={roleAccessRightList}
      isFetching={isFetching}
      lang={lang}
      setAccess={setAccess}
      handleSubmit={handleSubmit}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      accessRole={accessRole}
    />
  );
}
