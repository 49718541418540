import updateReadFlag from "./readFlagUpdate.api";
import { getUnprocessClaimNotification } from "../notification/getUnprocessClaimNotification.api";
import { getUnprocessMasterListNotification } from "./getUnprocessMasterListNotification.api";
import { getUnprocessShippingOrderNotification } from "./getUnprocessShippingOrderNotification.api";

const notificationApi = {
  getUnprocessClaimNotification,
  getUnprocessMasterListNotification,
  getUnprocessShippingOrderNotification,
  updateReadFlag,
};

export default notificationApi;
