import React from "react";
import StockoutListTableComponent from "./stockoutListTable.component";
import {
  STOCKFLOW_DAILY_STOCKOUT_SUMMARY_VIEW,
  STOCKFLOW_DAILY_PRODUCT_STOCKOUT_SUMMARY_VIEW,
} from "lib/constants/accessRights";
import { useSelector } from "react-redux";

export default function StockoutListTableContainer({
  hasStockflowStockoutViewAccessRight,
  hasStockflowDealerViewAccessRight,
  type,
  rows,
  isFetching,
  selectedIds,
  isSelectAll,
  isError,
  handleReload,
  page,
  rowsPerPage,
  language,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  handleSelectStockout,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  isDealerPage = false,
  displayReceiveOnly = false,
  handleViewDetail,
  hasCustomFields = false,
}) {
  const params = new URLSearchParams(window.location.search);
  const status = params.get("status");
  const hasStockflowDailyStockoutSummaryAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DAILY_STOCKOUT_SUMMARY_VIEW)
  );
  const hasStockflowDailyProductStockoutSummaryAccessRight = useSelector(
    (state) =>
      state.profile.accessRights.includes(
        STOCKFLOW_DAILY_PRODUCT_STOCKOUT_SUMMARY_VIEW
      )
  );
  return (
    <StockoutListTableComponent
      hasStockflowStockoutViewAccessRight={hasStockflowStockoutViewAccessRight}
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      type={type}
      rows={rows}
      isFetching={isFetching}
      isSelectAll={isSelectAll}
      language={language}
      selectedIds={selectedIds}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      handleSelectStockout={handleSelectStockout}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      isDealerPage={isDealerPage}
      displayReceiveOnly={displayReceiveOnly}
      handleViewDetail={handleViewDetail}
      hasCustomFields={hasCustomFields}
      hasStockflowDailyStockoutSummaryAccessRight={hasStockflowDailyStockoutSummaryAccessRight}
      hasStockflowDailyProductStockoutSummaryAccessRight={hasStockflowDailyProductStockoutSummaryAccessRight}
      status={status}
    />
  );
}
