import React, { useRef } from "react";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";
import {
  DragIndicator as DragIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  content: {
    border: "1px solid #D0D5DD",
    backgroundColor: "#ffffff",
  },
  squareButton: {
    border: "1px solid #E4E7EC",
    background: "#ffffff",
    height: 32,
    width: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
}));

export default function DraggableAdditionalInfoCardComponent({
  index,
  moveCard,
  lang,
  infoList,
  isSubmitting,
  info,
  changeVisibility,
  userLang,
}) {
  const classes = useStyles();
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (!isSubmitting) {
        moveCard(dragIndex, hoverIndex, infoList);
      }

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { index, type: "card" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <Box mb={1.5} width="100%" key={index} display="flex">
        <Box
          className={classes.content}
          display="flex"
          alignItems="flex-start"
          flex={1}
          style={{ opacity: info.is_visible ? 1 : 0.5 }}
        >
          <Box mt={1.5} ml={1}>
            <IconButton size="small">
              <DragIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Box>
          <Box my={1.5} mx={1}>
            <Box>
              <Typography variant="body2">
                <b>
                  {info.title
                    ? info.title[userLang] ??
                      info.title["EN"] ??
                      Object.values(info.title)[0]
                    : "-"}
                </b>
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                style={{ color: "#98A2B3", fontSize: 12 }}
                display="inline"
              >
                {info.format === 2
                  ? getLang(lang, "label.DATE")
                  : info.format === 3
                  ? getLang(lang, "label.NUMBER")
                  : info.format === 4
                  ? getLang(lang, "label.URL_LINK")
                  : getLang(lang, "label.TEXT")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml={1}>
          <Box className={classes.squareButton} mt={0.5}>
            <IconButton size="small" onClick={() => changeVisibility(info)}>
              {info.is_visible ? (
                <VisibilityIcon style={{ fontSize: 16 }} />
              ) : (
                <VisibilityOffIcon style={{ fontSize: 16 }} />
              )}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
