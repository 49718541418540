import {
  fetchAccessRightList,
  fetchRoleList,
  createRole,
  fetchRoleDetail,
  updateRole,
  deleteRole
} from "./roleAccessRight.action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

const pageSetting = {
  length: 10,
  start: 10 * 0,
  search: ""
};

const fetchAccessRightListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case fetchAccessRightList.pending.type:
        dispatch(
          setLoading({ id: fetchAccessRightList.typePrefix, state: true })
        );
        break;

      case fetchAccessRightList.fulfilled.type:
        dispatch(
          setLoading({ id: fetchAccessRightList.typePrefix, state: false })
        );
        break;

      case fetchAccessRightList.rejected.type:
        dispatch(
          setLoading({ id: fetchAccessRightList.typePrefix, state: false })
        );
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
        );
        break;

      default:
        break;
    }
  };

const fetchRoleListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case fetchRoleList.pending.type:
        dispatch(setLoading({ id: fetchRoleList.typePrefix, state: true }));
        break;

      case fetchRoleList.fulfilled.type:
        dispatch(setLoading({ id: fetchRoleList.typePrefix, state: false }));
        break;

      case fetchRoleList.rejected.type:
        dispatch(setLoading({ id: fetchRoleList.typePrefix, state: false }));
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
        );
        break;

      default:
        break;
    }
  };

const fetchRoleDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case fetchRoleDetail.pending.type:
        dispatch(setLoading({ id: fetchRoleDetail.typePrefix, state: true }));
        break;

      case fetchRoleDetail.fulfilled.type:
        dispatch(setLoading({ id: fetchRoleDetail.typePrefix, state: false }));
        break;

      case fetchRoleDetail.rejected.type:
        dispatch(setLoading({ id: fetchRoleDetail.typePrefix, state: false }));
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, payload.code)})
        );
        break;

      default:
        break;
    }
  };

const createRoleMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case createRole.pending.type:
        dispatch(setLoading({ id: createRole.typePrefix, state: true }));
        break;

      case createRole.fulfilled.type:
        dispatch(setLoading({ id: createRole.typePrefix, state: false }));
        dispatch(
          addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CREATE_ROLE")})
        );
        break;

      case createRole.rejected.type:
        dispatch(setLoading({ id: createRole.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code)}));
        break;

      default:
        break;
    }
  };

const updateRoleMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case updateRole.pending.type:
        dispatch(setLoading({ id: updateRole.typePrefix, state: true }));
        break;

      case updateRole.fulfilled.type:
        dispatch(setLoading({ id: updateRole.typePrefix, state: false }));
        dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_UPDATE_ROLE")}));
        break;

      case updateRole.rejected.type:
        dispatch(setLoading({ id: updateRole.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
        break;

      default:
        break;
    }
  };

const deleteRoleMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
      case deleteRole.pending.type:
        dispatch(setLoading({ id: deleteRole.typePrefix, state: true }));
        break;

      case deleteRole.fulfilled.type:
        dispatch(fetchRoleList(pageSetting));
        dispatch(setLoading({ id: deleteRole.typePrefix, state: false }));
        dispatch(
          addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_DELETE_ROLE") })
        );
        break;

      case deleteRole.rejected.type:
        dispatch(setLoading({ id: deleteRole.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
        break;

      default:
        break;
    }
  };

export const roleAccessRightMiddleware = [
  fetchAccessRightListMiddleware,
  fetchRoleListMiddleware,
  createRoleMiddleware,
  fetchRoleDetailMiddleware,
  updateRoleMiddleware,
  deleteRoleMiddleware
];
