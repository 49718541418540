import axios from "axios";

export default function getShippingOrderDetail(uuid) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/distributor/admin/api/v1/shipping-order/${uuid}/detail`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
