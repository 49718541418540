import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  REIMBURSEMENT_LIST_EDIT,
  REIMBURSEMENT_LIST_ADD,
  REIMBURSEMENT_LIST_APPROVE,
} from "lib/constants/accessRights";

import ReimbursementDetailPanelComponent from "./reimbursementDetailPanel.component";
import { showBackdrop, hideBackdrop } from "modules/backdrop";
import DisplayImageDialog from "modules/warrantee/components/dialog/displayImageDialog";
import RejectReimbursementDialog from "modules/warrantee/components/dialog/rejectReimbursementDialog";

import {
  clearConsumerClaimDetail,
  fetchWarrantyReimbursementDetail,
  processWarrantyReimbursement,
  setWarrantyReimbursementEditType,
  setWarrantyReimbursementForm,
} from "modules/warranty/redux";
import { allowedFileType } from "lib/constants/aws_s3";
import { selectLoading } from "modules/notification";
import { getLang } from "app/feature/constants";
import GeneralDialog from "components/dialog/generalDialog";

export default function ReimbursementDetailPanelContainer({
  claimItem,
  reimbursementItem,
  handleTableReload,
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const hasReimbursementEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(REIMBURSEMENT_LIST_EDIT)
  );
  const hasReimbursementCreateAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(REIMBURSEMENT_LIST_ADD)
  );
  const hasReimbursementApproveAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(REIMBURSEMENT_LIST_APPROVE)
  );
  const viewEditType = useSelector((state) => state.warranty.reimbursementType);
  const currentEmployeeId = useSelector((state) => state.profile.uuid);

  const isFetching = useSelector((state) =>
    selectLoading(state, fetchWarrantyReimbursementDetail.typePrefix)
  );

  const [displayMedia, setDisplayMedia] = useState(null);
  const [formMedia, setFormMedia] = useState([]);

  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setWarrantyReimbursementEditType("view"));
    };
    // eslint-disable-next-line
  }, []);

  const handleRejectClick = async (value) => {
    await dispatch(showBackdrop());

    await dispatch(
      processWarrantyReimbursement({
        id: reimbursementItem.uuid,
        status: "rejected",
        reason:
          value.selected === "others" ? value.otherReason : value.selected,
      })
    );

    await dispatch(hideBackdrop());
    setIsRejectDialogOpen(false);
    dispatch(clearConsumerClaimDetail());
    handleTableReload();
  };

  const handleApproveClick = async () => {
    await dispatch(
      processWarrantyReimbursement({
        id: reimbursementItem.uuid,
        status: "approved",
      })
    );

    setIsApproveDialogOpen(false);
    dispatch(clearConsumerClaimDetail());
    handleTableReload();
  };

  function fetchAndCreateFiles(imageFile, videoFile) {
    try {
      const fetchAndCreateFile = (url) => {
        let fileName = url.split("warranty_reimbursement_media/")[1];
        fileName = fileName.slice(fileName.indexOf("_") + 1);

        const extension = fileName.split(".").pop();

        let fileType = null;
        allowedFileType.forEach((type) => {
          if (type.includes(extension)) {
            fileType = type;
          }
        });

        return {
          file: {
            name: fileName,
            type: fileType,
          },
          src: url,
        };
      };
      const resultsArray1 = imageFile.map(fetchAndCreateFile);
      const resultsArray2 = videoFile.map(fetchAndCreateFile);

      return [...resultsArray1, ...resultsArray2];
    } catch (error) {
      return null;
    }
  }

  const handleEditClick = async () => {
    const medias = fetchAndCreateFiles(
      reimbursementItem.receiptProof.imageFile,
      reimbursementItem.receiptProof.videoFile
    );
    setFormMedia(medias);

    dispatch(
      setWarrantyReimbursementForm({
        reimbursementUuid: reimbursementItem.uuid,
        currencyCode: {
          alpha3: reimbursementItem.currencyCode,
          exp: 2,
        },
        receiptProof: medias,
        amount: reimbursementItem.amount,
        remark: reimbursementItem.remark,
      })
    );
    dispatch(setWarrantyReimbursementEditType("edit"));
  };

  return (
    <>
      <ReimbursementDetailPanelComponent
        lang={lang}
        language={language}
        hasReimbursementEditAccessRight={hasReimbursementEditAccessRight}
        hasReimbursementCreateAccessRight={hasReimbursementCreateAccessRight}
        hasReimbursementApproveAccessRight={hasReimbursementApproveAccessRight}
        currentEmployeeId={currentEmployeeId}
        claimItem={claimItem}
        reimbursementItem={reimbursementItem}
        handleMediaClick={(src, type) => setDisplayMedia({ src, type })}
        handleTableReload={handleTableReload}
        viewEditType={viewEditType}
        handleEditClick={handleEditClick}
        handleCancelEdit={() =>
          dispatch(setWarrantyReimbursementEditType("view"))
        }
        formMedia={formMedia}
        //  APPROVE / REJECT  //
        onRejectClick={() => setIsRejectDialogOpen(true)}
        onApproveClick={() => setIsApproveDialogOpen(true)}
        isFetching={isFetching}
      />
      <GeneralDialog
        isOpen={isApproveDialogOpen}
        handleClose={() => setIsApproveDialogOpen(false)}
        handleProceed={handleApproveClick}
        title={getLang(lang, "paragraph.APPROVE_REIMBURSEMENT_DIALOG_TITLE")}
        description={getLang(
          lang,
          "paragraph.APPROVE_REIMBURSEMENT_DIALOG_CONTENT"
        )}
        type="success"
      />

      <RejectReimbursementDialog
        lang={lang}
        isOpen={isRejectDialogOpen}
        handleSubmit={handleRejectClick}
        handleClose={() => setIsRejectDialogOpen(false)}
      />

      <DisplayImageDialog
        isOpen={!!displayMedia}
        handleClose={() => {
          setDisplayMedia(null);
        }}
        src={!!displayMedia ? displayMedia.src : null}
        type={!!displayMedia ? displayMedia.type : null}
      />
    </>
  );
}
