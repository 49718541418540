import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import {
  changeBulkSelectedAvailableStockout,
  changeBulkSelectedStockout,
  changeSelectedStockout,
  getAcceptedStockoutList,
  getCancelledStockoutList,
  getNewStockoutList,
  getRejectedStockoutList,
  resetStockoutListing,
} from "modules/stockflow/redux";
import moment from "moment";
import StockoutListingComponent from "./stockoutListing.component";
import { useHistory } from "react-router-dom";
import {
  STOCKFLOW_STOCKOUT_VIEW,
  STOCKFLOW_DEALER_VIEW,
} from "lib/constants/accessRights";

function StockoutListingContainer({
  type
}) {
  const params = new URLSearchParams(window.location.search);
  const date = params.get("date");
  const history = useHistory()
  const dispatch = useDispatch()
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  
  const hasStockflowStockoutViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_STOCKOUT_VIEW)
  );
  const hasStockflowDealerViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW)
  );
  const isNewStockoutFetching = useSelector((state) =>
    selectLoading(state, getNewStockoutList.typePrefix)
  );
  const isAcceptedStockoutFetching = useSelector((state) =>
    selectLoading(state, getAcceptedStockoutList.typePrefix)
  );
  const isRejectedStockoutFetching = useSelector((state) =>
    selectLoading(state, getRejectedStockoutList.typePrefix)
  );
  const isCancelledStockoutFetching = useSelector((state) =>
    selectLoading(state, getCancelledStockoutList.typePrefix)
  );

  const totalFiltered = useSelector(
    (state) => state.stockflowStockout[`${type}StockoutList`].totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowStockout[`${type}StockoutList`].totalRecords
  );
  const stockoutList = useSelector(
    (state) => state.stockflowStockout[`${type}StockoutList`].list
  );
  const hasCustomFields = useSelector(
    (state) => state.stockflowStockout[`${type}StockoutList`].hasCustomFields
  );

  const selectedIds = useSelector(
    (state) => state.stockflowStockout.selectedIds
  );
  const isSelectAll = useSelector(
    (state) => state.stockflowStockout.isSelectAll
  );
  const isError = useSelector(
    (state) => state.stockflowStockout.isLoadingStockoutListError
  );
  const isSearchResult = stockoutList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  // const [isExporting, setIsExporting] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    nodeIds: [],
    branch: [],
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    stockoutFrom: [],
    stockoutTo: [],
    respondDate: null,
    customFields: [],
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetStockoutListing());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("stockout_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("stockout_filter");

    if(date){
      temp = {
        ...temp,
        fromDate: moment(date).format("YYYY-MM-DD"),
        toDate: moment(date).format("YYYY-MM-DD"),
      };
    }
    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(resetStockoutListing());
      handleDataLoad();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, type]);

  useEffect(() => {
    dispatch(resetStockoutListing());
  }, [dispatch, type]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let fields = tableFilter.customFields.reduce((list, c) => {
      if (c.info) {
        list.push({
          uuid: c.info.uuid,
          search: c.search,
        });
      }
      return list;
    }, []);

    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      filter: {
        search: tableFilter.search,
        nodes: tableFilter.nodeIds,
        from_date: tableFilter.fromDate,
        to_date: tableFilter.toDate,
        stockout_from: tableFilter.stockoutFrom,
        stockout_to: tableFilter.stockoutTo,
        respond_date: tableFilter.respondDate,
      },
      custom_fields: fields,
    };
    switch (type) {
      case "new":
        dispatch(getNewStockoutList(payload));
        break;
      case "accepted":
        dispatch(getAcceptedStockoutList(payload));
        break;
      case "rejected":
        dispatch(getRejectedStockoutList(payload));
        break;
      case "cancelled":
        dispatch(getCancelledStockoutList(payload));
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    let nodeIds =
      filter.branch.length > 0 ? filter.branch.map((br) => br.node_id) : [];

    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      fromDate: filter.dateRange?.from
        ? moment(filter.dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: filter.dateRange?.to
        ? moment(filter.dateRange.to).format("YYYY-MM-DD")
        : "",
      nodeIds: nodeIds,
      branch: filter.branch,
      respondDate: filter.respondDate,
      stockoutFrom: filter.stockoutFrom,
      stockoutTo: filter.stockoutTo,
      customFields: filter.customFields,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      nodeIds: [],
      branch: [],
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      stockoutFrom: [],
      stockoutTo: [],
      respondDate: null,
      customFields: [],
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const handleSelectStockout = (index, isSelected) => {
    dispatch(changeSelectedStockout({ type, index, isSelected }));
  };

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableStockout({ type, value: true }));
  };

  const handleSelectAll = () => {
    dispatch(changeBulkSelectedStockout({ type, value: true }));
  };

  const handleDeselectAll = () => {
    dispatch(changeBulkSelectedStockout({ type, value: false }));
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      nodeIds: [],
      branch: [],
      respondDate: "",
      stockoutFrom: [],
      stockoutTo: [],
      customFields: [],
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("stockout_filter", JSON.stringify(tableFilter));
    }
    history.push(url);
  };

  return (
    <>
      <StockoutListingComponent
        hasStockflowStockoutViewAccessRight={
          hasStockflowStockoutViewAccessRight
        }
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        type={type}
        stockouts={stockoutList}
        isFetching={
          type === "cancelled"
            ? isCancelledStockoutFetching
            : type === "accepted"
            ? isAcceptedStockoutFetching
            : type === "rejected"
            ? isRejectedStockoutFetching
            : isNewStockoutFetching
        }
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        selectedCount={
          !isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length
        }
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        language={language}
        clearSearch={clearSearch}
        handleReload={handleDataLoad}
        isSearchResult={isSearchResult}
        handleSelectStockout={handleSelectStockout}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        paginationOptions={paginationOptions}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
        hasCustomFields={hasCustomFields}
      />
    </>
  );
}

export default StockoutListingContainer;
