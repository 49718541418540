/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from "modules/notification";
import LoadingComponent from "components/loading";
import {
  BRANCH_GROUP_EDIT,
  BRANCH_GROUP_VIEW,
} from "lib/constants/accessRights";
import GroupDetailsComponent from "./groupDetails.component";
import {
  deleteBranchGroup,
  editBranchGroup,
  getGroupSummary,
  resetGroup,
  updateGroupBranches,
  updateGroupDetail,
  updateGroupRules,
} from "modules/group/redux";
import GroupBranchEditDialog from "modules/group/components/dialog/groupBranchEditDialog";
import { ROOT } from "modules/group/config/group.route";
import GroupRuleEditDialog from "modules/group/components/dialog/groupRuleEditDialog";
import ConfirmationDialog from "modules/group/components/dialog/confirmationDialog";
import { getLang } from "app/feature/constants";

function GroupDetailsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const id = match.params.groupId;
  const hasGroupViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(BRANCH_GROUP_VIEW)
  );
  const hasGroupEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(BRANCH_GROUP_EDIT)
  );
  const group = useSelector((state) => state.branchGroup.group);
  const isError = useSelector((state) => state.branchGroup.isLoadingGroupError);
  const isFetching = useSelector((state) =>
    selectLoading(state, getGroupSummary.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [openBranchEditDialog, setOpenBranchEditDialog] = useState(false);
  const [openRuleEditDialog, setOpenRuleEditDialog] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const isUpdatingGoupDetail = useSelector((state) =>
    selectLoading(state, editBranchGroup.typePrefix)
  );
  const isUpdatingBranch = useSelector((state) =>
    selectLoading(state, updateGroupBranches.typePrefix)
  );
  const isUpdatingRules = useSelector((state) =>
    selectLoading(state, updateGroupRules.typePrefix)
  );

  useEffect(() => {
    if (!hasGroupViewAccessRight) {
      history.push("/admin/dashboard");
    }

    return () => {
      dispatch(resetGroup());
    };
  }, [dispatch, history, hasGroupViewAccessRight]);

  useEffect(() => {
    if (id) {
      getData();
    } else {
      history.push(ROOT);
    }
  }, [history, id]);

  if (!hasGroupViewAccessRight) return <LoadingComponent />;

  const getData = () => {
    dispatch(getGroupSummary(id));
  };

  const submitBranchUpdate = (selected) => {
    let branches = selected.map((b) => b.id);
    dispatch(
      updateGroupBranches({ branch_group_uuid: id, selected: branches })
    ).then(() => {
      setOpenBranchEditDialog(false);
      getData();
    });
  };

  const submitRuleUpdate = (
    rules,
    customRules,
    deletedCustomRuleFrom,
    deletedCustomRuleTo
  ) => {
    dispatch(
      updateGroupRules({
        branch_group_uuid: id,
        rules: rules,
        custom_rules: customRules,
        deleted_custom_rules_from: deletedCustomRuleFrom,
        deleted_custom_rules_to: deletedCustomRuleTo,
      })
    ).then(() => {
      setOpenRuleEditDialog(false);
      getData();
    });
  };

  const handleDeleteGroup = () => {
    dispatch(deleteBranchGroup(id)).then(() => {
      history.replace(ROOT);
    });
  };

  const updateGroupInfo = (values) => {
    dispatch(
      editBranchGroup({
        uuid: id,
        name: values.name,
        role_uuid: values.role ? values.role.uuid : null,
      })
    ).then(() => {
      dispatch(updateGroupDetail(values));
      setIsEditing(false);
    });
  };

  return (
    <>
      <GroupDetailsComponent
        hasGroupEditAccessRight={hasGroupEditAccessRight}
        group={group}
        isFetching={isFetching}
        isError={isError}
        handleReload={getData}
        lang={lang}
        editBranch={() => setOpenBranchEditDialog(true)}
        editRules={() => setOpenRuleEditDialog(true)}
        handleDeleteGroup={handleDeleteGroup}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        updateGroupInfo={updateGroupInfo}
        isUpdatingGoupDetail={isUpdatingGoupDetail}
      />
      <GroupBranchEditDialog
        isOpen={openBranchEditDialog}
        handleClose={() => setOpenBranchEditDialog(false)}
        submitBranchUpdate={submitBranchUpdate}
        id={id}
        isSubmitting={isUpdatingBranch}
      />
      <GroupRuleEditDialog
        isOpen={openRuleEditDialog}
        handleClose={() => setOpenRuleEditDialog(false)}
        submitRuleUpdate={submitRuleUpdate}
        id={id}
        isSubmitting={isUpdatingRules}
        group={group}
      />
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleProceed={() => handleDeleteGroup()}
        title={getLang(lang, "label.DELETE_GROUP")}
        description={getLang(lang, "paragraph.DELETE_GROUP_QUESTION")}
        type="danger"
      />
    </>
  );
}

export default GroupDetailsContainer;
