import React from "react";
import StockModuleSettingCardComponent from "./stockModuleSettingCard.component";

export default function StockModuleSettingCardContainer({
  attribute,
  isEditable,
  formik,
  updateFormikValue,
  attributeValue,
  isSubmitting,
}) {

  return (
    <StockModuleSettingCardComponent
      attribute={attribute}
      isEditable={isEditable}
      formik={formik}
      updateFormikValue={updateFormikValue}
      attributeValue={attributeValue}
      isSubmitting={isSubmitting}
    />
  );
}
