import {addAlert, setLoading} from "../../notification";
import {getApiLang} from "../../../app/feature/constants";
import {
  getCheckoutBoxList,
  getCheckoutDeliveryInfo,
  getCheckoutDetail,
  getCheckoutProductDetail,
  getCheckoutProductList,
  getCompletedCheckoutListing,
  getPendingCheckoutListing, getWarrantyClaimDetail, getWarrantyClaimList, handoffWarrantyClaim, processWarrantyClaim,
  updateCheckoutDeliveryInfo,
  updateCheckoutDetail
} from "./integration.action";

const getPendingCheckoutListingMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case getPendingCheckoutListing.pending.type:
      dispatch(
        setLoading({ id: getPendingCheckoutListing.typePrefix, state: true })
      );
      break;

    case getPendingCheckoutListing.fulfilled.type:
      dispatch(
        setLoading({ id: getPendingCheckoutListing.typePrefix, state: false })
      );
      break;

    case getPendingCheckoutListing.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getPendingCheckoutListing.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const getCompletedCheckoutListingMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case getCompletedCheckoutListing.pending.type:
      dispatch(
        setLoading({ id: getCompletedCheckoutListing.typePrefix, state: true })
      );
      break;

    case getCompletedCheckoutListing.fulfilled.type:
      dispatch(
        setLoading({ id: getCompletedCheckoutListing.typePrefix, state: false })
      );
      break;

    case getCompletedCheckoutListing.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getCompletedCheckoutListing.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
}

const getCheckoutDetailMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case getCheckoutDetail.pending.type:
      dispatch(
        setLoading({ id: getCheckoutDetail.typePrefix, state: true })
      );
      break;

    case getCheckoutDetail.fulfilled.type:
      dispatch(
        setLoading({ id: getCheckoutDetail.typePrefix, state: false })
      );
      break;

    case getCheckoutDetail.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getCheckoutDetail.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};
const getCheckoutProductDetailMiddleWare =
  ({ dispatch, getState }) =>
    (next) =>
      async (action) => {
        next(action);

        const { type } = action;

        switch (type) {
          case getCheckoutProductDetail.pending.type:
            dispatch(
              setLoading({
                id: getCheckoutProductDetail.typePrefix,
                state: true,
              })
            );
            break;
          case getCheckoutProductDetail.fulfilled.type:
            dispatch(
              setLoading({
                id: getCheckoutProductDetail.typePrefix,
                state: false,
              })
            );
            break;
          case getCheckoutProductDetail.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: getCheckoutProductDetail.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(
                  lang,
                  "error.ERROR_GET_CHECKOUT_PRODUCT_SN_LIST"
                ),
              })
            );
            break;
          default:
            break;
        }
      };

const getCheckoutProductListMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      async (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case getCheckoutProductList.pending.type:
            dispatch(
              setLoading({ id: getCheckoutProductList.typePrefix, state: true })
            );
            break;
          case getCheckoutProductList.fulfilled.type:
            dispatch(
              setLoading({
                id: getCheckoutProductList.typePrefix,
                state: false,
              })
            );
            break;
          case getCheckoutProductList.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: getCheckoutProductList.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(
                  lang,
                  payload.code === "error.ERROR_ACCESS_DENIED"
                    ? payload.code
                    : "error.ERROR_GET_CHECKOUT_PRODUCTS"
                ),
              })
            );
            break;
          default:
            break;
        }
      };

const getCheckoutBoxListMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      async (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case getCheckoutBoxList.pending.type:
            dispatch(
              setLoading({ id: getCheckoutBoxList.typePrefix, state: true })
            );
            break;
          case getCheckoutBoxList.fulfilled.type:
            dispatch(
              setLoading({
                id: getCheckoutBoxList.typePrefix,
                state: false,
              })
            );
            break;
          case getCheckoutBoxList.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: getCheckoutBoxList.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(
                  lang,
                  payload.code === "error.ERROR_ACCESS_DENIED"
                    ? payload.code
                    : "error.ERROR_GET_CHECKOUT_BOX_ITEMS"
                ),
              })
            );
            break;
          default:
            break;
        }
      };

const updateCheckoutDeliveryInfoMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case updateCheckoutDeliveryInfo.pending.type:
            dispatch(
              setLoading({ id: updateCheckoutDeliveryInfo.typePrefix, state: true })
            );
            break;
          case updateCheckoutDeliveryInfo.fulfilled.type:
            dispatch(
              setLoading({
                id: updateCheckoutDeliveryInfo.typePrefix,
                state: false,
              })
            );
            break;
          case updateCheckoutDeliveryInfo.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: updateCheckoutDeliveryInfo.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, payload.code),
              })
            );
            break;
          default:
            break;
        }
      };

const getCheckoutDeliveryInfoMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case getCheckoutDeliveryInfo.pending.type:
            dispatch(
              setLoading({ id: getCheckoutDeliveryInfo.typePrefix, state: true })
            );
            break;
          case getCheckoutDeliveryInfo.fulfilled.type:
            dispatch(
              setLoading({
                id: getCheckoutDeliveryInfo.typePrefix,
                state: false,
              })
            );
            break;
          case getCheckoutDeliveryInfo.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: getCheckoutDeliveryInfo.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, payload.code),
              })
            );
            break;
          default:
            break;
        }
      };

const updateCheckoutDetailMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case updateCheckoutDetail.pending.type:
            dispatch(
              setLoading({ id: updateCheckoutDetail.typePrefix, state: true })
            );
            break;
          case updateCheckoutDetail.fulfilled.type:
            dispatch(
              setLoading({
                id: updateCheckoutDetail.typePrefix,
                state: false,
              })
            );
            break;
          case updateCheckoutDetail.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: updateCheckoutDetail.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, payload.code),
              })
            );
            break;
          default:
            break;
        }
      };

const getWarrantyClaimListMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case getWarrantyClaimList.pending.type:
      dispatch(
        setLoading({ id: getWarrantyClaimList.typePrefix, state: true })
      );
      break;

    case getWarrantyClaimList.fulfilled.type:
      dispatch(
        setLoading({ id: getWarrantyClaimList.typePrefix, state: false })
      );
      break;

    case getWarrantyClaimList.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getWarrantyClaimList.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
}

const getWarrantyClaimDetailMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case getWarrantyClaimDetail.pending.type:
      dispatch(
        setLoading({ id: getWarrantyClaimDetail.typePrefix, state: true })
      );
      break;

    case getWarrantyClaimDetail.fulfilled.type:
      dispatch(
        setLoading({ id: getWarrantyClaimDetail.typePrefix, state: false })
      );
      break;

    case getWarrantyClaimDetail.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getWarrantyClaimDetail.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
}

const processWarrantyClaimMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case processWarrantyClaim.pending.type:
      dispatch(
        setLoading({ id: processWarrantyClaim.typePrefix, state: true })
      );
      break;

    case processWarrantyClaim.fulfilled.type:
      dispatch(
        setLoading({ id: processWarrantyClaim.typePrefix, state: false })
      );
      break;

    case processWarrantyClaim.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: processWarrantyClaim.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
}

const handoffWarrantyClaimMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case handoffWarrantyClaim.pending.type:
      dispatch(
        setLoading({ id: handoffWarrantyClaim.typePrefix, state: true })
      );
      break;

    case handoffWarrantyClaim.fulfilled.type:
      dispatch(
        setLoading({ id: handoffWarrantyClaim.typePrefix, state: false })
      );
      break;

    case handoffWarrantyClaim.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: handoffWarrantyClaim.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
}

export const integrationMiddleware = [
  getPendingCheckoutListingMiddleware,
  getCompletedCheckoutListingMiddleware,
  getCheckoutDetailMiddleware,
  getCheckoutProductDetailMiddleWare,
  getCheckoutProductListMiddleware,
  getCheckoutBoxListMiddleware,
  updateCheckoutDeliveryInfoMiddleware,
  getCheckoutDeliveryInfoMiddleware,
  updateCheckoutDetailMiddleware,
  getWarrantyClaimListMiddleware,
  getWarrantyClaimDetailMiddleware,
  processWarrantyClaimMiddleware,
  handoffWarrantyClaimMiddleware,
];
